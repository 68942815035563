<script>
import WelcomePanel from '@comp/home/WelcomePanel.vue'
import { useIndexStore } from '@src/store'
import ProjectList from '@comp/home/ProjectList.vue'

export default {
  name: 'Home',
  components: {
    WelcomePanel,
    ProjectList,
  },
  mounted () {
    const store = useIndexStore()
    store.changeRoute(this.$options.name)
    store.resetProject()
  },
}
</script>
<template>
  <section class="view home">
    <WelcomePanel />
    <ProjectList />
  </section>
</template>
<style lang="scss" scoped>
.home {
  max-height: calc(100vh - 50px);
}
</style>
