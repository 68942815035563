<script>
export default {
  name: 'ContributingTradesSection',
  props: {
    activeTrades: {
      type: Array,
      required: true,
    },
    hasDocuments: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'toggleTradeDialog',
    'search',
    'update:modelValue',
  ],
  data () {
    return {
      activeTradesData: [],
    }
  },
  watch: {
    activeTrades: {
      handler() {
        this.activeTradesData = this.activeTrades
      },
      immediate: true,
    },
  },
  methods: {
    changeTradeData (trade, key) {
      trade[key] = true
    },
    toggleTradeDialog (action, trade) {
      this.$emit('toggleTradeDialog', action, trade)
    },
    onSearch(text) {
      this.$emit('search', text)
    },
    onUpdateModelValue (trade) {
      trade._updated = true
      this.$emit('update:modelValue', this.activeTradesData, 'trades', null)
    },
  },
}
</script>
<template>
  <div class="form-section">
    <h1>{{ $gettext('Contributing disciplines') }}</h1>
    <v-card>
      <v-card-text>
        <div
          v-if="activeTrades.length"
          class="trades-container"
        >
          <div
            v-for="trade in activeTradesData"
            :key="trade.name"
            class="row"
          >
            <ChipsAutocomplete
              v-model="trade.experts"
              :label="trade.acronym"
              :item-value="item => item"
              :item-display="item => `${item.fullName} (${item.email})`"
              :items="users"
              hide-details
              @search="onSearch"
              @update:model-value="experts => onUpdateModelValue(trade)"
            />
            <v-btn
              color="secondary"
              variant="text"
              icon="fas fa-pen"
              size="small"
              @click="toggleTradeDialog('edit', trade)"
            />
            <v-btn
              color="secondary"
              variant="text"
              size="small"
              :disabled="hasDocuments && !trade._created"
              icon="fas fa-trash"
              @click="changeTradeData(trade, '_deleted')"
            />
          </div>
        </div>
        <v-btn
          color="secondary"
          prepend-icon="fas fa-plus"
          rounded
          @click="toggleTradeDialog('new')"
        >
          {{ $gettext('Add a contributing discipline') }}
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>
<style lang="scss" scoped>
.trades-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}
</style>
