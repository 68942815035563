import gql from 'graphql-tag'

export const getDataStep2 = gql`
  query currentProject($id: ID!) {
    project(pk: $id) {
      id
      hasDocuments
      managers {
        id
        fullName
        username
        email
      }
      docControllers {
        id
        fullName
        username
        email
      }
      contracts {
        id
        name
        code
        companyContracts {
          id
          contacts {
            id
            name
          }
          company {
            id
            name
          }
        }
        managers {
          id
          fullName
          username
          email
        }
      }
      trades {
        id
        name
        acronym
        experts {
          id
          fullName
          username
          email
        }
      }
    }
  }
`
export const allAzureUsers = gql`
  query allAzureUsers($partName: String!) {
    allAzureUsers(partName: $partName) {
      id
      fullName
      username
      email
    }
  }
`
export const updateUsersProject = gql`
  mutation updateProject(
    $id: ID!
    $managerUsernames: [String!]!
    $docControllerUsernames: [String!]!
  ) {
    updateProject(
      pk: $id
      data: {
        managerUsernames: $managerUsernames
        docControllerUsernames: $docControllerUsernames
      }
    ) {
      id
      name
    }
  }
`
export const createTrade = gql`
  mutation createTrade(
    $projectId: ID!
    $name: String!
    $acronym: String!
    $expertUsernames: [String!]!
  ) {
    createTrade(data: {
      projectId: $projectId
      name: $name
      acronym: $acronym
      expertUsernames: $expertUsernames
    }) {
      id
      name
    }
  }
`
export const deleteTrade = gql`
  mutation deletetrade($id: ID!) {
    deleteTrade(pk: $id) {
      id
    }
  }
`
export const updateTrade = gql`
  mutation updateTrade(
    $id: ID!
    $name: String!
    $acronym: String!
    $expertUsernames: [String!]!
  ) {
    updateTrade(
      pk: $id
      data: {
        name: $name
        acronym: $acronym
        expertUsernames: $expertUsernames
      }
    ) {
      id
      name
    }
  }
`
