<script>
import { backUrl } from '@src/config.js'
import InformationPopup from '@comp/utils/InformationPopup.vue'

export default {
  name: 'CommentImagePopup',
  components: {
    InformationPopup,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    open: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'close',
  ],
  data () {
    return {
      dialogProps: {
        persistent: false,
        fullscreen: true,
      },
      title: this.$gettext('Illustration'),
      buttons: [
        {
          text: this.$gettext('Close'),
          icon: 'fas fa-check',
          emit: 'close',
        },
      ],
    }
  },
  computed: {
    imageUrl () {
      const url = this.comment.imageUrl
      if (url) {
        const hash = Date.now()
        if (url.startsWith('data:') && url.includes(';base64,')) {
          return url
        } else if (url.startsWith('http')) {
          return `${url}?hash=${hash}`
        } else if (url[0] === '/') {
          return `${backUrl}${url.substr(1)}?hash=${hash}`
        } else {
          return `${backUrl}${url}?hash=${hash}`
        }
      } else {
        return ''
      }
    },
  },
  methods: {
    close () {
      this.$emit('close')
    },
  },
}
</script>
<template>
  <InformationPopup
    :model="open"
    :props="dialogProps"
    :title="title"
    :show-extra-buttons="true"
    :extra-buttons="buttons"
    width="80%"
    @close="close"
  >
    <img :src="imageUrl">
  </InformationPopup>
</template>
<style scoped>
.dialog-content-pre img {
  width: 100%;
}
</style>
