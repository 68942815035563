<script>
import KeywordTagsFilter from '@comp/project/KeywordTagsFilter.vue'
export default {
  name: 'KeywordFilter',
  components: {
    KeywordTagsFilter,
  },
  props: {
    disabled: {
      type: Boolean,
      required: true,
      default: false,
    },
    searchCriterion: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'change',
    'isDirty',
  ],
  data () {
    return {
      search: '',
      searchClass: 'search',
      filterTags: [],
      oldFilterTags: null,
    }
  },
  watch: {
    searchCriterion: {
      handler () {
        this.setData()
      },
      immediate: true,
    },
    filterTags: {
      handler (value) {
        if (JSON.stringify(value) !== this.oldFilterTags) {
          this.oldFilterTags = JSON.stringify(this.filterTags)
          this.$emit('change', this.filterTags)
        }
      },
      deep: true,
    },
  },
  methods: {
    setData () {
      this.filterTags = this.searchCriterion.slice()
      if (this.oldFilterTags === null) {
        this.oldFilterTags = JSON.stringify(this.filterTags)
      }
    },
    searchChangeEvent (event) {
      if (event.code === 'Enter') {
        this.updateTagFilter()
      } else {
        this.$emit('isDirty', this.search.trim() !== '')
      }
    },
    resetAll () {
      this.filterTags = []
      this.reset()
    },
    updateTagFilter () {
      const searchTerm = this.search.trim()
      if (searchTerm) {
        this.updateSearchedTags(searchTerm)
        this.removeKeywordSearch()
      }
    },
    updateSearchedTags (search) {
      const newTag = search.trim().toLowerCase()
      if (newTag && !this.filterTags.find(tag => tag.toLowerCase() === newTag)) {
        this.filterTags.push(newTag)
      }
    },
    reset () {
      this.removeKeywordSearch()
    },
    removeKeywordSearch () {
      this.search = ''
      this.$emit('isDirty', false)
    },
    changeSearchClass (event) {
      if (event) {
        this.searchClass = 'search-focused'
      } else {
        this.searchClass = 'search'
      }
    },
  },
}
</script>
<template>
  <div class="keyword-filter">
    <div class="filter-header">
      <div class="filter-title">
        {{ $gettext('Search by keyword') }}
      </div>
      <v-tooltip
        location="start"
        color="secondary"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            variant="plain"
            icon="fas fa-eraser"
            size="small"
            v-bind="props"
            @click="resetAll"
          />
        </template>
        {{ $gettext('Clear keywords') }}
      </v-tooltip>
    </div>
    <div class="test">
      <v-text-field
        v-model="search"
        prepend-inner-icon="fas fa-search"
        variant="underlined"
        color="primary"
        :class="searchClass"
        :append-inner-icon="'fas fa-plus-circle'"
        :disabled="disabled"
        @update:focused="changeSearchClass"
        @keyup="searchChangeEvent"
        @click:append-inner="updateTagFilter"
      />
      <div class="filter-items">
        <KeywordTagsFilter
          :filter-tags="filterTags"
          @remove-tags="updateTagFilter"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.search :deep(.v-icon) {
  color: $white;
  font-size: 1rem;
  opacity: 1;
  padding-top: 15px;
}
.search-focused :deep(.v-icon) {
  color: $primary;
  font-size: 1rem;
  opacity: 1;
  padding-top: 15px;
}
.search, .search-focused {
  padding-bottom: 10px;
}
.keyword-filter {
  flex-grow: 1;
}
.keyword-filter :deep(.v-input__control) { // stylelint-disable-line selector-class-pattern
  --v-input-padding-top: 10px;
}
.keyword-filter :deep(.v-input__details) { // stylelint-disable-line selector-class-pattern
  display: none;
}
.field-and-tags {
  overflow: hidden auto;
}
</style>
