<script>
export default {
  name: 'CustomLabelsDialog',
  props: {
    displayDialog: {
      type: Boolean,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    workflowTemplate: {
      type: Object,
      required: true,
    },
    labelTypes: {
      type: Object,
      required: true,
    },
    step: {
      type: Object,
      required: true,
    },
    hasDocuments: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:labels',
    'close',
  ],
  data () {
    return {
      customLabelsDialogNewLabel: null,
      customLabelsDialogHasIsAdmissible: this.step.type === 'Admissibility',
    }
  },
  watch: {
    displayDialog: {
      async handler () {
        this.popupShown = this.displayDialog
      },
      immediate: true,
    },
  },
  methods: {
    isCustomLabelValid () {
      return this.customLabelsDialogNewLabel !== null && this.customLabelsDialogNewLabel.trim()
    },
    removeCustomLabel (label) {
      const index = this.labels.findIndex(x => x._sequence === label._sequence)
      this.labels.splice(index, 1)
      this.labels.forEach((label, index) => {
        label._sequence = index
      })
    },
    orderCustomLabel (item, offset) {
      const label = this.labels.find(x => x._sequence === item._sequence + offset)
      const labelItem = this.labels.find(x => x._sequence === item._sequence)
      label._sequence = label._sequence - offset
      labelItem._sequence = item._sequence + offset
      this.labels.sort((a, b) => a._sequence - b._sequence)
    },
    addCustomLabel () {
      if (this.isCustomLabelValid()) {
        const newLabel = this.customLabelsDialogNewLabel.trim()
        const index = this.labels.length
        const label = {
          _created: true,
          _id: `${newLabel}`,
          _sequence: index,
          name: newLabel,
          isActive: true,
        }
        if (this.customLabelsDialogHasIsAdmissible) {
          label.isAdmissible = false
        }
        this.labels.push(label)
        this.customLabelsDialogNewLabel = null
      }
    },
    setCustomLabels () {
      const labels = this.labels.map(label => {
        const labelForServer = {
          _created: label._created || false,
          name: label.name,
          isActive: label.isActive,
        }
        if ('isAdmissible' in label) {
          labelForServer.isAdmissible = label.isAdmissible
        }
        return labelForServer
      })
      const labelType = this.labelTypes[this.step.type]
      this.workflowTemplate.labels[labelType] = labels
      this.closeCustomLabelsPopup()
    },
    closeCustomLabelsPopup () {
      this.$emit('close')
    },
  },
}
</script>
<template>
  <v-dialog
    v-model="popupShown"
    scrollable
    max-width="40em"
  >
    <v-card>
      <v-card-title class="dialog-header">
        {{ $gettext('Customize the labels of') }} "{{ $gettext(step.label) }}"
      </v-card-title>
      <v-card-text>
        <div class="row">
          <v-text-field
            v-model="customLabelsDialogNewLabel"
            color="secondary"
            :label="$gettext('New label')"
            prepend-inner-icon="fas fa-tag"
            append-icon="fas fa-plus-circle"
            @keydown.enter.prevent="addCustomLabel"
            @click:append="addCustomLabel"
          />
        </div>
        <div class="custom-label-grid">
          <template
            v-for="label in labels"
            :key="label._id"
          >
            <div class="label-name">
              {{ label.name }}
            </div>
            <v-switch
              v-if="customLabelsDialogHasIsAdmissible"
              :key="`${label._id}_isAdmissible`"
              v-model="label.isAdmissible"
              :label="$gettext('Admissible')"
              class="isAdmissible"
              hide-details
              :disabled="hasDocuments && !label._created"
            />
            <div
              v-else
              :key="`${label._id}_filler`"
            />
            <v-btn
              icon="fas fa-arrow-up"
              variant="text"
              color="secondary"
              size="small"
              :disabled="label._sequence === 0"
              @click="orderCustomLabel(label, -1)"
            />
            <v-btn
              icon="fas fa-arrow-down"
              variant="text"
              color="secondary"
              size="small"
              :disabled="label._sequence === labels.length - 1"
              @click="orderCustomLabel(label, +1)"
            />
            <v-switch
              v-if="hasDocuments && !label._created"
              :key="`${label._id}_switch`"
              v-model="label.isActive"
              :label="$gettext('Active label')"
              class="switch"
              hide-details
            />
            <v-btn
              v-else
              :key="`${label._id}_remove`"
              icon="fas fa-trash"
              variant="text"
              color="secondary"
              size="small"
              @click="removeCustomLabel(label)"
            />
          </template>
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="lightgrey"
          variant="elevated"
          prepend-icon="fas fa-times"
          @click="closeCustomLabelsPopup"
        >
          {{ $gettext('Cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          variant="elevated"
          prepend-icon="fas fa-save"
          @click="setCustomLabels"
        >
          {{ $gettext('Save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
.custom-label-grid {
  display: grid;
  grid-template-columns: max-content auto min-content min-content min-content;
}
.custom-label-grid > * {
  align-content: center;
  align-self: center;
}
.label-name {
  padding-right: 1rem;
}
</style>
