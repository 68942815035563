<script>
const PROJECT_SETTINGS_STEPS = [
  'Project',
  'Project team',
  'Document',
  'Contributions',
  'Approval sheet template',
]

export default {
  name: 'ProjectSettingsStepperButtons',
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  emits: [
    'changeStep',
    'saveAndQuit',
    'publishProject',
  ],
  computed: {
    isFirstStep () {
      return this.step === 1
    },
    isLastStep () {
      return this.step === PROJECT_SETTINGS_STEPS.length
    },
  },
  methods: {
    changeStep (value) {
      if (value === 'next' && this.step < PROJECT_SETTINGS_STEPS.length) {
        this.$emit('changeStep', this.step + 1)
      } else if (value === 'previous' && this.step > 1) {
        this.$emit('changeStep', this.step - 1)
      }
    },
    saveAndQuit () {
      this.$emit('saveAndQuit')
    },
    publishProject () {
      this.$emit('publishProject')
    },
  },
}
</script>
<template>
  <div class="stepper-btn-container">
    <v-btn
      size="small"
      color="primary"
      icon="fas fa-chevron-left"
      :disabled="isFirstStep"
      @click="changeStep('previous')"
    />
    <v-btn
      color="primary"
      prepend-icon="fas fa-floppy-disk"
      @click="saveAndQuit()"
    >
      {{ $gettext('Save and quit') }}
    </v-btn>
    <v-btn
      color="primary"
      prepend-icon="fas fa-upload"
      @click="publishProject"
    >
      {{ $gettext('Publish the project') }}
    </v-btn>
    <v-btn
      size="small"
      color="primary"
      icon="fas fa-chevron-right"
      :disabled="isLastStep"
      @click="changeStep('next')"
    />
  </div>
</template>
<style lang="scss" scoped>
.stepper-btn-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: baseline;
  padding-bottom: 30px;
}
</style>
