<script>
export default {
  name: 'ThreadsHeader',
  props: {
    tradeName: {
      type: String,
      required: true,
    },
  },
}
</script>
<template>
  <div>
    {{ $gettext('Discipline contribution') }}{{ $gettext(':') }} {{ tradeName }}
  </div>
</template>
<style lang="scss" scoped>
.v-icon.threads-return {
  margin-right: 0.5em;
}
</style>
