<script>
import ProjectAddDialog from '@comp/home/ProjectAddDialog.vue'
import { useIndexStore } from '@src/store/index'
import { addProject } from '@comp/home/queries'

export default {
  name: 'WelcomePanel',
  components: {
    ProjectAddDialog,
  },
  data () {
    return {
      showAddDialog: false,
    }
  },
  computed: {
    store () {
      return useIndexStore()
    },
    canAddProject () {
      return this.store.user && this.store.user.isAdmin
    },
  },
  methods: {
    async addNewProject (newProjectName) {
      const store = useIndexStore()
      await this.$graphqlMutate(addProject, {
        name: newProjectName,
      }).then(response => {
        store.createProject(response.createProject)
      }).catch(error => {
        store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
      }).finally(() => {
        this.toggleAddDialog()
      })
    },
    toggleAddDialog () {
      this.showAddDialog = !this.showAddDialog
    },
  },
}
</script>
<template>
  <section class="card welcome-panel">
    <div class="welcome-subtitle">
      {{ $gettext('Welcome to') }}
    </div>
    <div class="welcome-title">
      <img
        src="/static/logo_vizeo.png"
        alt="Vizeo"
      >
    </div>
    <p class="welcome-text">
      {{ $gettext("Select a project in the list, or create a new one!") }}
    </p>
    <v-btn
      v-if="canAddProject"
      color="primary"
      class="welcome-btn"
      prepend-icon="fas fa-plus"
      size="large"
      @click="toggleAddDialog"
    >
      {{ $gettext('Create a new project') }}
    </v-btn>
    <ProjectAddDialog
      v-if="showAddDialog"
      @add-new-project="addNewProject"
      @close-add-dialog="toggleAddDialog"
    />
    <img
      class="welcome-icon"
      src="/static/vizeo-icon.png"
    >
  </section>
</template>
<style lang="scss" scoped>
.welcome-panel {
  max-width: 40%;
  overflow: auto;
}
.welcome-title {
  margin-bottom: 1rem;
  width: 80%
}
.welcome-title img {
  max-height: 5ch;
}
.first-section {
  padding-bottom: 30px;
}
.welcome-text {
  font-size: 1.2rem;
  position: relative;
  z-index: 2;
  margin-bottom: 1rem;
}
.welcome-icon {
  position: absolute;
  width: 40%;
  z-index: 1;
  opacity: 0.6;
  right: 2rem;
  bottom: 2rem;
}

@media (width <= 1400px), (height <= 850px) {
  .welcome-title {
    font-size: 3rem !important;
  }
  .welcome-subtitle {
    font-size: 1.6rem;
  }
}
</style>
