<script>
import moment from 'moment'
import {
  CONTRIBUTION_STATUS_NAME,
} from '@src/utils/consts'
import {
  getSafeMetadataName,
} from '@src/utils/utils'

export default {
  name: 'ProjectTable',
  props: {
    archive: {
      type: Boolean,
      required: false,
    },
    documentVersions: {
      type: Array,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    metadata: {
      type: Array,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    sortOptions: {
      type: Object,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
    totalDocuments: {
      type: Number,
      required: true,
    },
    trades: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'documentViewRequest',
    'updateItemsPerPage',
    'updateOptions',
    'uploadNewDocument',
  ],
  data () {
    return {
      atBottomOfPage: false,
      itemsPerPageOptions: [20, 50, 100],
    }
  },
  computed: {
    displayStepEndDateLabel () {
      return `${this.$gettext('End of step')} - ${this.$gettext('due date')}${this.$gettext(':')}`
    },
    displayStepEndDaysLabel () {
      return `${this.$gettext('End of step')} - ${this.$gettext('remaining days')}${this.$gettext(':')}`
    },
    displayWorkflowEndDateLabel () {
      return `${this.$gettext('End of workflow')} - ${this.$gettext('due date')}${this.$gettext(':')}`
    },
    displayWorkflowEndDaysLabel () {
      return `${this.$gettext('End of workflow')} - ${this.$gettext('remaining days')}${this.$gettext(':')}`
    },
    filteredHeaders () {
      const allowSort = true
      const headers = []
      headers.push({ title: this.$gettext('Title'), key: 'title', sortable: allowSort, minWidth: '300' })
      for (const metadatum of this.metadata) {
        if (metadatum.isShown) {
          const name = getSafeMetadataName(metadatum.name)
          const header = { title: metadatum.name, key: `metadataValues.${name}`, sortable: allowSort }
          headers.push(header)
        }
      }
      headers.push(
        { title: this.$gettext('Link to doc.'), key: 'url', sortable: false, minWidth: '100' },
        { title: this.$gettext('Original DMS link'), key: 'originGedUrl', sortable: false },
        { title: this.$gettext('Contract'), key: 'document.contract.code', sortable: true },
        { title: this.$gettext('Step'), key: 'currentStepTemplate.type', sortable: false },
        { title: this.$gettext('Date received'), key: 'workflowStartDatetime', sortable: allowSort },
      )
      if (!this.archive) {
        headers.push(
          { title: this.$gettext('Delay (working days)'), key: 'remainingStepCalendarDays', sortable: allowSort },
          ...this.filterTradeHeaders(),
        )
      } else {
        headers.push(
          { title: this.$gettext('Resolution date'), key: 'endOfWorkflowTransitionDatetime', sortable: allowSort },
        )
      }
      return headers
    },
    sortedTrades () {
      const sortedTrades = this.trades.slice().sort((a, b) => {
        const fa = a.acronym.toLowerCase()
        const fb = b.acronym.toLowerCase()
        if (fa < fb) {
          return -1
        }
        if (fa > fb) {
          return 1
        }
        return 0
      })
      return sortedTrades
    },
  },
  methods: {
    filterTradeHeaders () {
      let count = 0
      const headers = []
      for (const trade of this.sortedTrades) {
        if (trade.value || this.sortedTrades.every(o => !o.value)) {
          count++
          headers.push({
            title: trade.acronym,
            key: `trades.${trade.acronym}`,
            align: 'center',
            sortable: false,
            width: '50',
            cellClass: `trade-cell-${count % 2}`,
          })
        }
      }
      return headers
    },
    displayStepEndDate (documentVersion) {
      return this.displayDate(documentVersion.stepDeadline)
    },
    displayStepEndDays (documentVersion) {
      return `${documentVersion.remainingStepCalendarDays} / ${documentVersion.remainingStepWorkDays}`
    },
    displayWorkflowEndDate (documentVersion) {
      return this.displayDate(documentVersion.workflowDeadline)
    },
    displayWorkflowEndDays (documentVersion) {
      return `${documentVersion.remainingWorkflowCalendarDays} / ${documentVersion.remainingWorkflowWorkDays}`
    },
    displayDate (date) {
      if (date !== '' && date !== null) {
        return moment(date).format('DD/MM/YYYY')
      }
    },
    getColor (delta) {
      if (delta <= 0) {
        return '#E86A6A'
      } else if (delta >= 10) {
        return '#B5E0D6'
      } else {
        return '#F3CC8C'
      }
    },
    displayStatus (tradeContributionStatus) {
      // eslint-disable-next-line max-len
      return `${this.$gettext('Progress')}${this.$gettext(':')} ${this.$gettext(CONTRIBUTION_STATUS_NAME[tradeContributionStatus])}`
    },
    getStatusCssClass (tradeContributionStatus) {
      return `table-progress progress-${tradeContributionStatus.toLowerCase()}`
    },
    openDoc (link, Window) {
      Window = Window || window
      Window.open(link, '_blank')
    },
    onDocumentViewRequest (event, rawItem) {
      const documentVersion = rawItem.item
      this.$emit('documentViewRequest', documentVersion)
    },
    onClickUploadNewDocument () {
      this.$emit('uploadNewDocument')
    },
    onUpdateItemsPerPage (value) {
      this.$emit('updateItemsPerPage', value)
    },
    onUpdateOptions (value) {
      this.$emit('updateOptions', value)
    },
  },
}
</script>
<template>
  <v-data-table-server
    :items-per-page="itemsPerPage"
    :headers="filteredHeaders"
    :items="documentVersions"
    :items-length="totalDocuments"
    :loading="loading"
    :items-per-page-options="itemsPerPageOptions"
    :page="page"
    :options="sortOptions"
    class="card project-table"
    fixed-header
    @click:row="onDocumentViewRequest"
    @update:items-per-page="onUpdateItemsPerPage"
    @update:options="onUpdateOptions"
  >
    <template v-slot:loading>
      <v-skeleton-loader type="table-row@10" />
    </template>
    <template v-slot:[`item.url`]="{ item }">
      <div class="links-btn">
        <v-btn
          icon="fas fa-file-alt"
          variant="text"
          color="secondary"
          size="small"
          @click.stop.prevent="openDoc(item.url)"
        />
        <v-btn
          v-if="item.directoryUrl !== null"
          icon="fas fa-folder-open"
          variant="text"
          color="secondary"
          size="small"
          @click.stop.prevent="openDoc(item.directoryUrl)"
        />
      </div>
    </template>
    <template v-slot:[`item.originGedUrl`]="{ item }">
      <v-btn
        v-if="item.originGedUrl !== null"
        icon="fas fa-file-alt"
        variant="text"
        color="secondary"
        size="small"
        @click.stop.prevent="openDoc(item.originGedUrl)"
      />
    </template>
    <template v-slot:[`item.currentStepTemplate.type`]="{ item }">
      <div>{{ $gettext(steps.find(o => o.type === item.currentStepTemplate.type).label) }}</div>
    </template>
    <template v-slot:[`item.remainingStepCalendarDays`]="{ item }">
      <v-tooltip location="bottom">
        <template v-slot:activator="{ props }">
          <v-chip
            :color="getColor(item.remainingStepCalendarDays)"
            v-bind="props"
            variant="flat"
          >
            {{ `${item.remainingStepCalendarDays} (${item.remainingStepWorkDays})` }}
          </v-chip>
        </template>
        <div>
          <div>{{ `${displayStepEndDateLabel} ${displayStepEndDate(item)}` }}</div>
          <div>{{ `${displayStepEndDaysLabel} ${displayStepEndDays(item)}` }}</div>
          <div>{{ `${displayWorkflowEndDateLabel} ${displayWorkflowEndDate(item)}` }}</div>
          <div>{{ `${displayWorkflowEndDaysLabel} ${displayWorkflowEndDays(item)}` }}</div>
        </div>
      </v-tooltip>
    </template>
    <template v-slot:[`item.workflowStartDatetime`]="{ item }">
      <div>{{ displayDate(item.workflowStartDatetime) }}</div>
    </template>
    <template v-slot:[`item.endOfWorkflowTransitionDatetime`]="{ item }">
      {{ displayDate(item.endOfWorkflowTransitionDatetime) }}
    </template>
    <template
      v-for="trade in trades"
      v-slot:[`item.trades.${trade.acronym}`]="{ item }"
    >
      <div
        v-if="item.currentStepTemplate !== 'Admissibility' && item.trades[trade.acronym]"
        :key="`${item.id}${trade.acronym}`"
        :class="getStatusCssClass(item.trades[trade.acronym])"
        :title="displayStatus(item.trades[trade.acronym])"
      />
    </template>
    <template v-slot:footer.prepend>
      <v-fab
        v-if="!error && !archive"
        id="fab-upload-document"
        extended
        color="primary"
        prepend-icon="fas fa-file-import"
        :text="$gettext('Upload a new document')"
        @click="onClickUploadNewDocument"
      />
    </template>
  </v-data-table-server>
</template>
<style lang="scss" scoped>
.project-table {
  padding: 0 !important;
  overflow: auto;
  border-radius: 5px !important;
}
:deep(table > thead > tr > th) {
  background-color: $secondary !important;
  color: $white;
  font-size: 0.75rem;
}
:deep(table > thead > tr > th:hover) {
  color: $white !important;
}
.links-btn {
  display: flex;
  flex-direction: row;
}
#fab-upload-document {
  margin-left: 12px;
}
</style>
