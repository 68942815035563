<script>
import {
  rejectClosingSuggestionOnThread,
  closeThread,
  reopenThread,
  getThreadPermissions,
} from '@comp/document/threads/queries'
import { STEP_PM_EVALUATION } from '@src/utils/consts'
import ThreadsTable from '@comp/document/threads/ThreadsTable.vue'
import Thread from '@comp/document/threads/Thread.vue'
import { useIndexStore } from '@src/store/index.js'

export default {
  name: 'AllThreads',
  components: {
    ThreadsTable,
    Thread,
  },
  provide () {
    return {
      isThreadLockToggable: this.isThreadLockToggable,
      displayThreadLockTitle: this.displayThreadLockTitle,
      getThreadLockIcon: this.getThreadLockIcon,
      lockThreadToggle: this.lockThreadToggle,
    }
  },
  props: {
    threads: {
      type: Array,
      required: true,
    },
    lockedMode: {
      type: Boolean,
      required: true,
    },
    documentVersionId: {
      type: String,
      required: true,
    },
    isOmanType: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'update:threads',
    'closeContributionPanel',
    'submitContribution',
    'reopenContribution',
    'showThreadDetails',
    'closeThreadDetails',
  ],
  data () {
    return {
      newThreads: [],
      oldThreads: null,
      dataProcessing: false,
      showThreadDetails: false,
      currentThreadIndex: null,
    }
  },
  computed: {
    store () {
      return useIndexStore()
    },
    currentStep () {
      return STEP_PM_EVALUATION
    },
    currentThread: {
      get () {
        if (this.currentThreadIndex === null) {
          return null
        } else if (this.currentThreadIndex === -1) {
          return {
            initialComments: [],
            comments: [],
            permissions: {
              canAddComment: true,
              canCloseThread: false,
              canReopenThread: false,
              canSuggestClosing: false,
              canCancelClosingSuggestion: false,
              canRejectClosingSuggestion: false,
            },
          }
        } else {
          return this.newThreads[this.currentThreadIndex]
        }
      },
      async set (thread) {
        if (this.currentThreadIndex >= 0 && thread) {
          if (this.currentThreadIndex < this.newThreads.length) {
            await this.$graphqlMutate(
              getThreadPermissions, { id: thread.id },
            ).then(response => {
              thread.permissions = response.thread.permissions
            })
          }
          this.newThreads.splice(this.currentThreadIndex, 1, thread)
        }
      },
    },
    showThreadLockButton () {
      return !this.lockedMode
    },
  },
  watch: {
    threads: {
      handler () {
        this.setData()
      },
      immediate: true,
    },
    lockedMode () {
      this.setData()
    },
    documentVersionId () {
      this.setData()
    },
    newThreads: {
      handler (value) {
        if (JSON.stringify(value) !== this.oldThreads) {
          this.oldThreads = JSON.stringify(this.newThreads)
          this.$emit('update:threads', this.newThreads)
        }
      },
      deep: true,
    },
  },
  methods: {
    setData () {
      this.newThreads = this.threads.slice()
      if (this.oldThreads === null) {
        this.oldThreads = JSON.stringify(this.newThreads)
      }
    },
    onShowThread (thread) {
      this.showThreadDetails = true
      this.dataProcessing = true
      this.currentThreadIndex = this.newThreads.findIndex(item => item.id === thread.id)
      this.$emit('showThreadDetails')
    },
    closeThreadDetails () {
      this.showThreadDetails = false
      this.dataProcessing = false
      this.$emit('closeThreadDetails')
    },
    isThreadLockToggable (thread) {
      const perms = thread.permissions
      if (thread.suggestedToClose) {
        return perms.canRejectClosingSuggestion
      } else if (thread.closed) {
        return perms.canReopenThread
      } else {
        return perms.canCloseThread
      }
    },
    displayThreadLockTitle (thread) {
      if (thread.suggestedToClose) {
        return this.$gettext('Reject closing suggestion on the comment thread')
      } else if (thread.closed) {
        return this.$gettext('Re-open the comment thread')
      } else {
        return this.$gettext('Close the comment thread')
      }
    },
    getThreadLockIcon (thread) {
      return (thread.closed || thread.suggestedToClose) ? 'fas fa-lock' : 'fas fa-lock-open'
    },
    async lockThreadToggle (thread) {
      if (thread.suggestedToClose) {
        await this.rejectClosingSuggestionOnThread(thread)
      } else {
        await thread.closed ? this.reopenThread(thread) : this.closeThread(thread)
      }
    },
    async rejectClosingSuggestionOnThread (thread) {
      this.dataProcessing = true
      const id = thread.id
      await this.$graphqlMutate(rejectClosingSuggestionOnThread, {
        id,
        documentVersionId: this.documentVersionId,
      }).then(response => {
        this.newThreads.splice(
          this.newThreads.findIndex(th => th.id === id),
          1,
          response.rejectClosingSuggestionOnThread,
        )
        this.dataProcessing = false
      }).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
        this.dataProcessing = false
      })
    },
    async closeThread (thread) {
      this.dataProcessing = true
      const id = thread.id
      await this.$graphqlMutate(closeThread, {
        id,
        documentVersionId: this.documentVersionId,
      }).then(response => {
        this.newThreads.splice(
          this.newThreads.findIndex(th => th.id === id),
          1,
          response.closeThread,
        )
        this.dataProcessing = false
      }).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
        this.dataProcessing = false
      })
    },
    async reopenThread (thread) {
      this.dataProcessing = true
      const id = thread.id
      await this.$graphqlMutate(reopenThread, {
        id,
        documentVersionId: this.documentVersionId,
      }).then(response => {
        this.newThreads.splice(
          this.newThreads.findIndex(th => th.id === id),
          1,
          response.reopenThread,
        )
        this.dataProcessing = false
      }).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
        this.dataProcessing = false
      })
    },
  },
}
</script>
<template>
  <ThreadsTable
    v-if="!showThreadDetails"
    :threads="newThreads"
    :current-step="currentStep"
    :locked-mode="lockedMode"
    :can-create-thread="false"
    :show-lock-button="showThreadLockButton"
    :is-oman-type="isOmanType"
    :data-processing="dataProcessing"
    @show="onShowThread"
  />
  <Thread
    v-if="showThreadDetails && currentThread"
    v-model:current-thread="currentThread"
    :current-step="currentStep"
    :document-version-id="documentVersionId"
    @close-thread-details="closeThreadDetails"
  />
</template>
