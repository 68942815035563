<script>
import CustomLabelsDialog from '@comp/projectSettings/step3Documents/CustomLabelsDialog.vue'

export default {
  name: 'WorkflowStepCard',
  components: {
    CustomLabelsDialog,
  },
  props: {
    stepWFT: {
      type: Object,
      required: true,
    },
    workflowTemplate: {
      type: Object,
      required: true,
    },
    labelTypes: {
      type: Object,
      required: true,
    },
    hasDocuments: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:stepWFT',
  ],
  data () {
    return {
      displayDialog: false,
      customizableStepTypes: [
        'Admissibility',
        'PMEvaluation',
        'EvaluationSummary',
      ],
      customLabelsDialogLabels: [],
    }
  },
  watch: {
    stepWFT: {
      handler(newVal) {
        this.$emit('update:stepWFT', newVal)
      },
      deep: true,
    },
  },
  methods: {
    isCustomizableStepType (stepType) {
      return this.customizableStepTypes.includes(stepType)
    },
    showCustomLabelsDialog () {
      this.displayDialog = true
      const labelType = this.labelTypes[this.stepWFT.type]
      this.customLabelsDialogLabels = this.workflowTemplate.labels[labelType].map((label, index) => ({
        ...label,
        _id: `${label.name}`,
        _sequence: index,
      }))
    },
    hideCustomLabelsDialog () {
      this.displayDialog = false
    },
  },
}
</script>
<template>
  <div class="step card">
    <div class="step-label">
      {{ $gettext(stepWFT.label) }}
      <v-tooltip
        location="bottom"
        color="secondary"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            v-if="isCustomizableStepType(stepWFT.type)"
            v-bind="props"
            color="secondary"
            size="small"
            icon="fas fa-edit"
            variant="plain"
            @click="showCustomLabelsDialog"
          />
        </template>
        {{ $gettext('Edit labels') }}
      </v-tooltip>
    </div>
    <div class="step-content">
      <v-text-field
        v-model="stepWFT.days0"
        color="secondary"
        :label="$gettext('Delay index 0')"
        type="number"
        hide-details
      />
      <v-text-field
        v-model="stepWFT.daysX"
        color="secondary"
        :label="$gettext('Delay index X')"
        type="number"
        hide-details
      />
      <CustomLabelsDialog
        :display-dialog="displayDialog"
        :labels="customLabelsDialogLabels"
        :workflow-template="workflowTemplate"
        :label-types="labelTypes"
        :step="stepWFT"
        :has-documents="hasDocuments"
        @close="hideCustomLabelsDialog"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.step {
  flex-shrink: 1;
  flex-grow: 1;
  position: relative;
}
.card {
  padding: 0;
}
.step-content {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 1ch;
}
.step-label {
  background-color: $primary;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 5px 0 20px;
  font-weight: bold;
  border-radius: 5px 5px 0 0;
  justify-content: space-between;
}
</style>
