<script>
import FilterList from '@comp/project/FilterList.vue'
import KeywordFilter from '@comp/project/KeywordFilter.vue'
import TradesFilter from '@comp/project/TradesFilter.vue'
import UpdateFiltersButton from '@comp/project/UpdateFiltersButton.vue'
import { useIndexStore } from '@src/store/index.js'

export default {
  name: 'FilterHeader',
  components: {
    FilterList,
    KeywordFilter,
    TradesFilter,
    UpdateFiltersButton,
  },
  props: {
    contracts: {
      type: Array,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
    trades: {
      type: Array,
      required: true,
    },
    filtersPanel: {
      type: Boolean,
      required: true,
    },
    error: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    archive: {
      type: Boolean,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  emits: [
    'resizeFilters',
    'updateFilters',
  ],
  data () {
    return {
      showFiltersContent: true,
      filteredTrades: [],
      filteredContracts: [],
      filteredSteps: [],
      sortedSteps: [],
      keywords: [],
      tradesFilterValue: 'all',
      isDirty: false,
      keywordIsDirty: false,
    }
  },
  computed: {
    store () {
      return useIndexStore()
    },
    numberOfActiveFilters () {
      let count = 0
      count += this.filteredTrades.filter(trade => trade.value).length
      count += this.filteredContracts.filter(contract => contract.value).length
      count += this.filteredSteps.filter(step => step.value).length
      if (this.tradesFilterValue !== 'all') {
        count += 1
      }
      count += this.keywords.length
      return count
    },
    resizeIcon () {
      return this.filtersPanel ? 'fas fa-compress' : 'fas fa-expand'
    },
  },
  mounted () {
    this.filteredTrades = this.trades.map(trade => ({ ...trade, value: false }))
    this.filteredSteps = this.steps.map(step => ({ ...step, value: false }))
    this.filteredContracts = this.contracts.map(contract => ({ ...contract, value: false }))
    this.setFiltersFromStore()
    this.sortFilterTrades()
    this.sortFilterContracts()
  },
  methods: {
    sortFilterTrades () {
      this.filteredTrades.sort((a, b) => {
        const fa = a.acronym.toLowerCase()
        const fb = b.acronym.toLowerCase()
        if (fa < fb) {
          return -1
        }
        if (fa > fb) {
          return 1
        }
        return 0
      })
    },
    sortFilterContracts () {
      this.filteredContracts.sort((a, b) => {
        const fa = a.name.toLowerCase()
        const fb = b.name.toLowerCase()
        if (fa < fb) {
          return -1
        }
        if (fa > fb) {
          return 1
        }
        return 0
      })
    },
    resizeFilters () {
      if (this.filtersPanel) {
        this.showFiltersContent = false
      } else {
        setTimeout(() => {
          this.showFiltersContent = true
        }, 300)
      }
      this.$emit('resizeFilters')
    },
    reset (key) {
      switch (key) {
        case 'trades':
          this.filteredTrades = this.filteredTrades.map(trade => ({ ...trade, value: false }))
          break
        case 'contracts':
          this.filteredContracts = this.filteredContracts.map(contract => ({ ...contract, value: false }))
          break
        case 'steps':
          this.filteredSteps = this.filteredSteps.map(step => ({ ...step, value: false }))
          break
      }
      this.changeFilters()
    },
    onRadioButtonChange (value) {
      this.tradesFilterValue = value
      this.changeFilters()
    },
    changeFilters () {
      this.isDirty = true
    },
    getFiltersData () {
      const trades = Object.fromEntries(this.filteredTrades.map(trade => [trade.id, trade.value]))
      const contracts = Object.fromEntries(this.filteredContracts.map(contract => [contract.id, contract.value]))
      const steps = Object.fromEntries(this.filteredSteps.map(step => [step.type, step.value]))
      const tradesFilterValue = this.tradesFilterValue
      const search = this.keywords.slice()
      return {
        trades,
        contracts,
        steps,
        tradesFilterValue,
        search,
      }
    },
    updateStoreFilters (filters) {
      this.store.changeTableParam({ key: 'filters', newValue: filters })
    },
    setFiltersFromStore () {
      const projectStoreFilters = this.store.filters[this.projectId]
      if (projectStoreFilters) {
        const pageStoreFilters = projectStoreFilters[this.store.route]
        if (pageStoreFilters) {
          for (const key in pageStoreFilters.trades) {
            const trade = this.filteredTrades.find(trade => trade.id === key)
            if (trade) {
              trade.value = pageStoreFilters.trades[key]
            }
          }
          for (const key in pageStoreFilters.contracts) {
            const contract = this.filteredContracts.find(contract => contract.id === key)
            if (contract) {
              contract.value = pageStoreFilters.contracts[key]
            }
          }
          for (const key in pageStoreFilters.steps) {
            const step = this.filteredSteps.find(step => step.type === key)
            if (step) {
              step.value = pageStoreFilters.steps[key]
            }
          }
          this.tradesFilterValue = pageStoreFilters.tradesFilterValue
          this.keywords = (pageStoreFilters.search || []).slice()
        }
      }
    },
    onUpdateFilters () {
      this.isDirty = false
      const filters = this.getFiltersData()
      this.updateStoreFilters(filters)
      this.$emit('updateFilters', filters)
    },
    keywordFilterIsDirty (state) {
      this.keywordIsDirty = state
    },
    onAddFilterTag (tags) {
      this.changeFilters()
      this.keywords = tags
    },
  },
}
</script>
<template>
  <div
    v-if="!error"
    class="card filters"
    :class="[ filtersPanel ? '' : 'filters-small']"
  >
    <div class="filters-icons">
      <div class="filters-resize-container">
        <v-btn
          color="white"
          variant="text"
          size="small"
          :icon="resizeIcon"
          @click="resizeFilters"
        />
        <div
          v-if="!filtersPanel"
          class="nb-active-filters"
        >
          <span>{{ numberOfActiveFilters }}</span> <span>{{ $gettext('active filter(s)') }}</span>
        </div>
      </div>
    </div>
    <transition>
      <FilterList
        v-if="!archive && showFiltersContent && filtersPanel"
        :title="$gettext('Contributors')"
        icon="fas fa-people-group"
        type="trades"
        :items="filteredTrades"
        attr="acronym"
        :filter-update-data-error="error"
        :filter-is-updating="disabled"
        :with-wrap="true"
        @reset="reset"
        @change="changeFilters"
      />
    </transition>
    <transition>
      <FilterList
        v-if="showFiltersContent && filtersPanel"
        :title="$gettext('Contracts')"
        icon="fas fa-file-contract"
        type="contracts"
        :items="filteredContracts"
        attr="name"
        :filter-update-data-error="error"
        :filter-is-updating="disabled"
        :fixed-width="true"
        @reset="reset"
        @change="changeFilters"
      />
    </transition>
    <transition>
      <FilterList
        v-if="!archive && showFiltersContent && filtersPanel"
        :title="$gettext('Steps')"
        icon="fas fa-chart-gantt"
        type="steps"
        :translate="true"
        :items="filteredSteps"
        attr="label"
        :filter-update-data-error="error"
        :filter-is-updating="disabled"
        :fixed-width="true"
        @reset="reset"
        @change="changeFilters"
      />
    </transition>
    <transition>
      <TradesFilter
        v-if="!archive && showFiltersContent && filtersPanel"
        :value="tradesFilterValue"
        :filter-is-updating="disabled"
        @radio-button-change="onRadioButtonChange"
      />
    </transition>
    <transition>
      <div
        v-if="showFiltersContent && filtersPanel"
        class="keywords-button-filter"
      >
        <KeywordFilter
          :disabled="disabled"
          :search-criterion="keywords"
          @change="onAddFilterTag"
          @is-dirty="keywordFilterIsDirty"
        />
        <UpdateFiltersButton
          :filter-update-data-error="error"
          :filter-is-dirty="isDirty"
          :filter-keywords-is-dirty="keywordIsDirty"
          :loading="disabled"
          @filters-updated="onUpdateFilters"
        />
      </div>
    </transition>
  </div>
</template>
<style lang="scss" scoped>
:deep(.v-skeleton-loader__date-picker-days) { // stylelint-disable-line selector-class-pattern
  max-height: 11vh;
}
.filters {
  padding: 10px 20px 20px 10px;
  height: 25vh;
  background-color: $secondary;
  color: $white;
  flex-direction: row;
  transition: 0.3s;
  gap: 1.5rem;
  min-height: 190px;
  flex-grow: 1;
  flex-shrink: 1;
}
.filters-small {
  min-height: 60px !important;
  height: 60px !important;
}
.filters-resize-container {
  display: flex;
  gap: 1rem;
}
.keywords-button-filter {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 1ch;
  max-width: 260px;
}
.nb-active-filters {
  margin: auto;
}
.nb-active-filters > span:first-child {
  font-weight: bold;
}
</style>
<style lang="scss">
.filter {
  display: flex;
  flex-direction: column;
  gap: 1ch;
  flex-grow: 1;
  flex-shrink: 1;
}
.fixed-width {
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 40ch;
}
.filter-header {
  border-bottom: 2px solid $primary;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
.filter-title {
  display: flex;
  gap: 1ch;
  font-weight: 500;
}
.filter-items {
  overflow: hidden auto;
  padding-right: 1rem;
}
.filter ::-webkit-scrollbar-thumb {
  background-color: $primary; // $secondary color is not visible in this context
}

// Properties for transitions
.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
