<script>
import { login } from '@src/utils/auth'
import { useIndexStore } from '@src/store'

export default {
  name: 'Login',
  async mounted () {
    const store = useIndexStore()
    store.changeRoute(this.$options.name)
    store.changeUser(null)
  },
  methods: {
    login () {
      login()
    },
  },
}
</script>
<template>
  <div class="layout">
    <div class="layout-overlay" />
    <div class="card login-card">
      <div class="login-title">
        <img
          src="/static/logo_vizeo.png"
          alt="Vizeo"
        >
      </div>
      <div class="login-subtitle">
        {{ $gettext("Login") }}
      </div>
      <div class="login-text">
        <div>
          <!-- eslint-disable-next-line max-len -->
          {{ $gettext("You must be logged in to have access to this application. Please click on the button below to do so.") }}
        </div>
      </div>
      <div class="login-btn-container">
        <v-btn
          color="primary"
          prepend-icon="fas fa-sign-in-alt"
          size="large"
          class="login-btn"
          @click="login"
        >
          {{ $gettext('Login') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.login-card {
  z-index: 1;
  gap: 1rem;
  width: 500px;
  max-height: calc(100% - 2em);
  overflow-y: auto;
  padding: 40px 20px;
}
.login-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-title > img {
  max-width: 20rem;
  max-height: 15rem;
}
.login-subtitle {
  font-size: 2em;
  color: $secondary !important;
  font-weight: bold;
  text-align: center;
}
.login-text {
  text-align: center;
}
.login-btn-container {
  display: flex;
  justify-content: center;
}
.login-btn {
  width: min-content;
}
</style>
