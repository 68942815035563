<script>
export default {
  name: 'CommentCompanyResponse',
  props: {
    comment: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
<template>
  <v-card class="answer">
    <v-card-title class="title">
      {{ $gettext('Submitter response provided at index ') }} {{ comment.documentVersion.index }}
    </v-card-title>
    <v-card-text class="text">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="comment.text" />
    </v-card-text>
  </v-card>
</template>
<style lang="scss" scoped>
.answer {
  padding: 5px 15px;
  margin: 5px 0;
  grid-template:
    "title title" 32px
    "text ." auto;
  overflow-y: hidden;
  background-color: $primary;
  text-align: right !important;
  display: block;
}
.title {
  grid-area: title;
  height: 24px ;
  padding: 0 20px !important;
  font-size: 14px !important;
  display: inline !important;
}
.text {
  grid-area: text;
  padding: 0 20px !important;
}
</style>
