<script>
export default {
  name: 'CommentEditImportanceOmanType',
  props: {
    importanceProp: {
      type: String,
      default: () => '',
    },
  },
  emits: [
    'change',
  ],
  data () {
    return {
      importance: this.importanceProp,
    }
  },
  methods: {
    change () {
      this.$emit('change', this.importance)
    },
    validateImportanceLength (importance) {
      if (importance.length > 5) {
        return this.$gettext('5 characters maximum')
      }
      return true
    },
  },
}
</script>
<template>
  <div class="comment-edit-importance">
    {{ $gettext('Comment importance:') }}
    <v-text-field
      v-model="importance"
      variant="outlined"
      :rules="[validateImportanceLength(importance)]"
      @update:model-value="change"
    />
  </div>
</template>
<style lang="scss" scoped>
.comment-edit-importance {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 2%;
}
.internal-note-warning {
  min-height: 2em;
}
</style>
