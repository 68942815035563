import gql from 'graphql-tag'

export const getDataStep5 = gql`
  query currentProject($id: ID!) {
    project(pk: $id) {
      id
      approvalSheetTemplate {
        name
        path
        size
        url
      }
    }
  }
`
export const uploadTemplate = gql`
  mutation updateProject(
    $id: ID!
    $approvalSheetTemplate: Upload
  ) {
    updateProject(
      pk: $id
      data: {
        approvalSheetTemplate: $approvalSheetTemplate
      }
    ) {
      id
      approvalSheetTemplateUrl
    }
  }
`
