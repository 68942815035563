<script>
export default {
  name: 'TradesFilter',
  props: {
    value: {
      type: String,
      required: true,
      default: 'all',
    },
    filterIsUpdating: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'radioButtonChange',
  ],
  data () {
    return {
      radioGroup: this.value,
    }
  },
  watch: {
    value () {
      this.radioGroup = this.value
    },
  },
  methods: {
    radioButtonChange (radioButtonValue) {
      this.$emit('radioButtonChange', radioButtonValue)
    },
  },
}
</script>
<template>
  <div class="filter fixed-width">
    <div class="filter-header">
      <div class="filter-title">
        <v-icon icon="fas fa-clipboard-check" />
        {{ $gettext('Status') }}
      </div>
      <v-btn
        icon="fas fa-eraser"
        size="small"
        disabled
        :style="{opacity:0}"
      />
    </div>
    <v-radio-group
      v-model="radioGroup"
      density="comfortable"
      class="radio-buttons"
      hide-details
      :disabled="filterIsUpdating"
      @update:model-value="radioButtonChange(radioGroup)"
    >
      <v-radio
        :label="$gettext('All')"
        value="all"
      />
      <v-radio
        :label="$gettext('Completed')"
        value="completed"
      />
      <v-radio
        :label="$gettext('In progress')"
        value="in_progress"
      />
    </v-radio-group>
  </div>
</template>
<style lang="scss" scoped>
.filter {
  flex-grow: 0;
}
.radio-buttons {
  overflow: hidden auto;
  padding-right: 1rem;
}
.radio-buttons :deep(label) {
  opacity: 1;
}
.radio-buttons :deep(.v-icon) {
  color: $primary;
  opacity: 1;
}
</style>
