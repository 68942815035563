<script>
import { updateContract } from '@comp/projectSettings/queries'
import ContributingTradeDialog from '@comp/projectSettings/step2ProjectTeam/ContributingTradeDialog.vue'
import ContributingTradesSection from '@comp/projectSettings/step2ProjectTeam/ContributingTradesSection.vue'
import ProjectTeamSection from '@comp/projectSettings/step2ProjectTeam/ProjectTeamSection.vue'
import {
  allAzureUsers,
  createTrade,
  deleteTrade,
  getDataStep2,
  updateTrade,
  updateUsersProject,
} from '@comp/projectSettings/step2ProjectTeam/queries'

export default {
  name: 'FormStep2',
  components: {
    ContributingTradeDialog,
    ContributingTradesSection,
    ProjectTeamSection,
  },
  props: {
    step: {
      type: Number,
      default: () => 0,
    },
  },
  data () {
    return {
      projectId: null,
      ready: false,
      managers: [],
      docControllers: [],
      contracts: [],
      trades: [],
      showTradeDialog: false,
      currentTrade: null,
      changes: {},
      users: [],
      hasDocuments: false,
    }
  },
  computed: {
    activeTrades () {
      return this.trades.filter(o => o._deleted !== true).sort((t1, t2) => t1.name.localeCompare(t2.name))
    },
    activeContracts () {
      return this.contracts.filter(o => o._deleted !== true)
    },
    activeUsers () {
      const dupList = [...this.managers, ...this.docControllers]
      for (const contract of this.contracts) {
        dupList.push(...contract.managers)
      }
      for (const trade of this.trades) {
        dupList.push(...trade.experts)
      }
      return this.getUniqueList(dupList, (user) => user.id)
    },
  },
  watch: {
    async step () {
      await this.setData()
    },
  },
  async mounted () {
    this.projectId = this.$route.params.id
    await this.setData()
  },
  methods: {
    getUniqueList (lst, hashFunc) {
      const hashAndObj = (o) => [hashFunc(o), o]
      return [...new Map(lst.map(hashAndObj)).values()]
    },
    cleanContracts (contracts) {
      if (contracts) {
        for (const contract of contracts) {
          contract.companies = []
          if (contract.companyContracts) {
            for (const company of contract.companyContracts) {
              contract.companies.push({
                name: company.company.name,
                contacts: company.contacts.map(o => o.name),
              })
            }
            delete contract.companyContracts
          }
        }
      }
      return contracts
    },
    async setData () {
      if (this.step === 2) {
        return this.$graphqlQuery(getDataStep2, {
          id: this.projectId,
        }).then(response => {
          const project = response.project
          this.managers = project.managers
          this.docControllers = project.docControllers
          this.hasDocuments = project.hasDocuments
          this.contracts = this.cleanContracts(project.contracts)
          this.trades = project.trades
          this.users = this.activeUsers.slice()
          this.ready = true
        })
      }
    },
    async getUsers (value) {
      if (value.length > 2) {
        return this.$graphqlQuery(allAzureUsers, {
          partName: value,
        }).then(response => {
          const filteredUsers = response.allAzureUsers
          this.users = this.getUniqueList([...this.activeUsers, ...filteredUsers], (user) => user.id)
        })
      } else {
        this.users = this.activeUsers.slice()
      }
    },
    toggleTradeDialog (key, trade) {
      if (key === 'new') {
        this.currentTrade = null
      }
      if (key === 'edit' && trade) {
        this.currentTrade = trade
      }
      this.showTradeDialog = !this.showTradeDialog
    },
    addNewTrade (newTrade) {
      this.trades.push(newTrade)
    },
    retrieveData (data, role, contract) {
      if (contract !== null && typeof contract === 'object') {
        contract[role] = data
        contract._updated = true
        if (role === 'managers') {
          const index = this.contracts.findIndex(o => o.id === contract.id)
          this.contracts[index] = contract
        }
      } else {
        this[role] = data
        this.changes[role] = true
      }
      this.users = this.activeUsers.slice()
    },
    generateUpdateContractsPromises () {
      const promises = []
      for (const contract of this.activeContracts) {
        if (contract._updated) {
          delete contract._updated
          const queryUpdateContract = this.$graphqlMutate(updateContract, {
            id: contract.id,
            contract: {
              managerUsernames: contract.managers.map(o => o.username),
            },
          })
          promises.push(queryUpdateContract)
        }
      }
      return promises
    },
    generateUpdateTradePromises () {
      const promises = []
      for (const trade of this.trades) {
        let queryUpdateTrade
        if (trade._deleted && !trade._created) {
          delete trade._deleted
          queryUpdateTrade = this.$graphqlMutate(deleteTrade, {
            id: trade.id,
          })
        } else if (trade._created && !trade._deleted) {
          delete trade._created
          delete trade._updated
          queryUpdateTrade = this.$graphqlMutate(createTrade, {
            name: trade.name,
            acronym: trade.acronym,
            expertUsernames: trade.experts.map(o => o.username),
            projectId: this.projectId,
          })
        } else if (trade._updated) {
          delete trade._updated
          queryUpdateTrade = this.$graphqlMutate(updateTrade, {
            id: trade.id,
            name: trade.name,
            acronym: trade.acronym,
            expertUsernames: trade.experts.map(o => o.username),
          })
        }
        if (queryUpdateTrade) {
          promises.push(queryUpdateTrade)
        }
      }
      return promises
    },
    saveData () {
      return new Promise((resolve, reject) => {
        const promises = []
        if (this.changes.managers || this.changes.docControllers) {
          const queryUpdateProject = this.$graphqlMutate(updateUsersProject, {
            id: this.projectId,
            managerUsernames: this.managers.map(o => o.username),
            docControllerUsernames: this.docControllers.map(o => o.username),
          })
          promises.push(queryUpdateProject)
        }
        promises.push(...this.generateUpdateContractsPromises())
        promises.push(...this.generateUpdateTradePromises())
        Promise.all(promises).then(response => {
          this.changes = {}
          resolve(response)
        }).catch(error => reject(error))
      })
    },
  },
}
</script>
<template>
  <section
    v-if="ready"
    class="form"
  >
    <ProjectTeamSection
      :active-contracts="activeContracts"
      :managers="managers"
      :doc-controllers="docControllers"
      :users="users"
      @update:model-value="retrieveData"
      @search="getUsers"
    />
    <ContributingTradesSection
      :active-trades="activeTrades"
      :has-documents="hasDocuments"
      :users="users"
      @search="getUsers"
      @toggle-trade-dialog="toggleTradeDialog"
      @update:model-value="retrieveData"
    />
    <ContributingTradeDialog
      v-if="showTradeDialog"
      :trade="currentTrade"
      @add-new-trade="addNewTrade"
      @close-trade-dialog="toggleTradeDialog('')"
    />
  </section>
</template>
