// styles
import '@scss/main.scss'
// Main app component
import App from './App.vue'

// Vue
import { router } from '@src/router'
import { createApp } from 'vue'

// Plugins
import ChipsAutocomplete from '@comp/utils/ChipsAutocomplete.vue'
import ChipsCombobox from '@comp/utils/ChipsCombobox.vue'
import { createApolloProvider } from '@vue/apollo-option'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { createPinia } from 'pinia'
import VueApexCharts from 'vue3-apexcharts'
import { createGettext } from 'vue3-gettext'

// Vuetify
import { vuetifyColors } from '@scss/sds-design-system/color-shades'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, fa } from 'vuetify/iconsets/fa'
import * as localeMessages from 'vuetify/locale'
import 'vuetify/styles'

// Font Awesome
import '@fortawesome/fontawesome-free/css/all.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// i18n
import { DEFAULT_LANGUAGE, LANGUAGES } from '@src/constants'
import translations from './translations.json'

// GraphQL
import { apolloClient, graphqlMixin } from '@src/utils/graphql'

const languageMixin = {
  methods: {
    $selectBestLanguage (browserLangs, supportedLangs, defaultLang) {
      if (browserLangs.length) {
        for (const lang of browserLangs) {
          const parts = lang.toLowerCase().split('-')
          if (parts.length > 1) {
            parts[1] = parts[1].toUpperCase()
          }
          const normLang = parts.join('-')
          if (supportedLangs.includes(normLang)) {
            return normLang
          } else if (parts.length > 1 && supportedLangs.includes(parts[0])) {
            return parts[0]
          }
        }
        return defaultLang
      } else {
        return defaultLang
      }
    },
  },
}

const app = createApp(App)
app.mixin(graphqlMixin)
app.mixin(languageMixin)
app.use(createPinia())
app.use(router)
app.component('Apexchart', VueApexCharts)
app.component('VueDatePicker', VueDatePicker)
app.component('FontAwesomeIcon', FontAwesomeIcon)
app.component('ChipsAutocomplete', ChipsAutocomplete)
app.component('ChipsCombobox', ChipsCombobox)
library.add(fas)

const bestLanguage = languageMixin.methods.$selectBestLanguage(
  navigator.languages,
  Object.keys(LANGUAGES),
  DEFAULT_LANGUAGE,
)

const vuetify = createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  components,
  directives,
  theme: {
    defaultTheme: 'light',
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        dark: false,
        colors: vuetifyColors,
      },
    },
  },
  icons: {
    aliases,
    sets: {
      fa,
    },
  },
  locale: {
    messages: Object.assign(
      { translations },
      ...Object.keys(LANGUAGES).map(lang => ({ [lang]: localeMessages[lang] })),
    ),
    fallback: 'translations',
    locale: bestLanguage,
  },
})
app.use(vuetify)

app.use(createGettext({
  availableLanguages: LANGUAGES,
  defaultLanguage: bestLanguage,
  translations,
  silent: true,
}))

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
})

app.use(apolloProvider)

app.mount('#app')
