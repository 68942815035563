<script>
import InformationPopup from '@comp/utils/InformationPopup.vue'
import { currentDocumentVersionPMES, finishPmEvaluationSubmission } from './queries'
import { useIndexStore } from '@src/store/index.js'

export default {
  name: 'PMEvaluationSubmission',
  components: {
    InformationPopup,
  },
  props: {
    lockedMode: {
      type: Boolean,
      default: () => false,
    },
    documentVersionId: {
      type: String,
      default: () => '',
    },
  },
  emits: [
    'nextStep',
  ],
  data () {
    return {
      documentVersion: null,
      admissibilityLabel: null,
      pmEvaluationLabel: null,
      informationPopup: false,
      informationPopupTitle: '',
      informationPopupContent: '',
      informationPopupValidate: '',
      informationPopupValidateParams: [],
    }
  },
  computed: {
    store () {
      return useIndexStore()
    },
    canIAdvanceToNextStep () {
      return this.documentVersion ? this.documentVersion.permissions.canIAdvanceToNextStep : false
    },
  },
  watch: {
    async lockedMode () {
      await this.setData()
    },
  },
  async mounted () {
    await this.setData()
  },
  methods: {
    async setData () {
      return this.$graphqlQuery(currentDocumentVersionPMES, {
        id: this.documentVersionId,
      }).then(response => {
        this.documentVersion = response.documentVersion
        this.admissibilityLabel = this.documentVersion.admissibilityLabel
        this.pmEvaluationLabel = this.documentVersion.pmEvaluationLabel
      })
    },
    async nextStep () {
      return this.$graphqlMutate(finishPmEvaluationSubmission, {
        documentVersionId: this.documentVersionId,
      }).then(response => {
        this.$emit('nextStep', response.finishPmEvaluationSubmission.currentStepTemplate.type)
      }).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
      })
    },
    openInformationPopupNextStep () {
      this.informationPopupTitle = this.$gettext('Transition to next step')
      this.informationPopupContent = this.$gettext('Are you sure you want to go to the next step?')
      this.informationPopupValidate = this.nextStep()
      this.informationPopupValidateParams = []
      this.informationPopup = true
    },
  },
}
</script>
<template>
  <section class="card">
    <div>
      <div v-if="pmEvaluationLabel">
        {{ $gettext('Evaluation submitted') }} : {{ pmEvaluationLabel }}
      </div>
      <div v-else>
        {{ $gettext('Admissibility') }} : {{ admissibilityLabel }}
      </div>
    </div>
    <div
      v-if="!lockedMode"
      class="btn-container"
    >
      <v-btn
        :color="!canIAdvanceToNextStep ? 'secondary' : 'primary'"
        :disabled="!canIAdvanceToNextStep"
        :class="!canIAdvanceToNextStep ? 'disabled-button' : ''"
        prepend-icon="fas fa-arrow-right"
        @click="openInformationPopupNextStep"
      >
        {{ $gettext("Go to next step") }}
      </v-btn>
    </div>
    <InformationPopup
      :model="informationPopup"
      :title="informationPopupTitle"
      :content="informationPopupContent"
      :params="informationPopupValidateParams"
      @cancel="informationPopup = false"
      @validate="informationPopupValidate"
    />
  </section>
</template>
<style lang="scss" scoped>
.btn-container {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  align-items: flex-end;
}
.card {
  flex-grow: 1;
}
.disabled-button {
  background-color: $grey-light !important;
}
.disabled-button > :deep(.v-btn__content) { // stylelint-disable-line selector-class-pattern
  color: $black;
}
.disabled-button > :deep(.v-btn__prepend) { // stylelint-disable-line selector-class-pattern
  color: $secondary;
}
</style>
