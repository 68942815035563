<script>
export default {
  name: 'PageNotFound',
}
</script>
<template>
  <v-empty-state
    :headline="$gettext('Whoops, 404')"
    :title="$gettext('Page not found')"
    :text="$gettext('The page you were looking for does not exist')"
    image="/static/logo_vizeo.png"
  />
</template>
