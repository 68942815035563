import gql from 'graphql-tag'

export const currentProject = gql`
  query currentProject($id: ID!) {
    project(pk: $id) {
      name
      id
      isPublished
    }
  }
`
export const updatePublishedProject = gql`
  mutation updatePublishedProject(
    $id: ID!
    $isPublished: Boolean!
  ) {
    updateProject(
      pk: $id
      data: {
        isPublished: $isPublished
      }
    ) {
      id
      name
    }
  }
`
export const updateContract = gql`
  mutation updateContract($id: ID!, $contract: ContractUpdateInput!) {
    updateContract(pk: $id, data: $contract) {
      id
      name
    }
  }
`
