<script>
export default {
  name: 'ChipsAutocomplete',
  props: {
    label: {
      type: String,
      default: () => '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemValue: {
      type: String,
      default: () => 'id',
    },
    itemDisplay: {
      type: Function,
      default: o => o,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'search',
    'update:modelValue',
  ],
  methods: {
    onSearch (event) {
      this.$emit('search', event.target.value)
    },
    onUpdateModelValue (newValue) {
      this.$emit('update:modelValue', newValue)
    },
  },
}
</script>
<template>
  <v-autocomplete
    :model-value="modelValue"
    clear-on-select
    hide-selected
    multiple
    closable-chips
    color="secondary"
    :items="items"
    :label="label"
    :item-title="itemDisplay"
    :item-value="itemValue"
    :disabled="disabled"
    :rules="rules"
    :hide-details="hideDetails"
    @keydown="onSearch"
    @update:model-value="onUpdateModelValue"
  >
    <template v-slot:chip="{ props }">
      <v-chip
        v-bind="props"
        variant="elevated"
        color="secondary"
      />
    </template>
  </v-autocomplete>
</template>
