<script>
import moment from 'moment'
import { currentDocumentVersionEOW } from './queries'
import { WORKFLOW_STEPS } from '@src/utils/consts'

export default {
  name: 'EndOfWorkflow',
  props: {
    lockedMode: {
      type: Boolean,
      default: () => false,
    },
    documentVersionId: {
      type: String,
      default: () => '',
    },
  },
  data () {
    return {
      stepTransitions: [],
      workflowSteps: [],
    }
  },
  watch: {
    async lockedMode () {
      await this.setData()
    },
  },
  async mounted () {
    this.workflowSteps = WORKFLOW_STEPS
    await this.setData()
  },
  methods: {
    async setData () {
      const stepMap = {
        Admissibility: 'admissibilityLabel',
        PMEvaluation: 'pmEvaluationLabel',
        EvaluationSummary: 'evaluationSummaryStateLabel',
      }
      return this.$graphqlQuery(currentDocumentVersionEOW, {
        id: this.documentVersionId,
      }).then(response => {
        const stepTransitions = response.documentVersion.stepTransitions
        this.stepTransitions = stepTransitions.map(stepTransition => {
          const stepType = stepTransition.currentStepTemplate.type
          return {
            type: stepType,
            datetime: stepTransition.datetime,
            author: stepTransition.by.fullName,
            label: this.workflowSteps[stepType].label,
            value: response.documentVersion[stepMap[stepType]] || '',
          }
        })
      })
    },
    displayDate (date) {
      if (date !== '') {
        return moment(date).format('DD/MM/YYYY | HH:mm')
      }
    },
  },
}
</script>
<template>
  <section class="end-of-workflow card">
    <div
      v-for="stepTransition in stepTransitions"
      :key="stepTransition.type"
      class="step-transition"
    >
      <div class="step-transition-date">
        {{ `${displayDate(stepTransition.datetime)} (${$gettext('by')} ${stepTransition.author}) : ` }}
      </div>
      <div class="step-transition-label">
        {{ $gettext(stepTransition.label) }}
      </div>
      <div
        v-if="stepTransition.value"
        class="step-transition-value"
      >
        {{ $gettext(stepTransition.value) }}
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.end-of-workflow {
  flex-grow: 1;
  overflow-y: auto;
}
.step-transition {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $grey-light;
  padding: 5px 0;
}
.step-transition:last-of-type {
  border-bottom: 0;
}
.step-transition-date {
  margin-right: 10px;
}
.step-transition-label {
  width: 30%;
}
.step-transition-value {
  font-weight: bold;
}
</style>
