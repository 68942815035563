<script>
import { useIndexStore } from '@src/store/index.js'
import { STEP_ADMISSIBILITY, STEP_PM_EVALUATION, STEP_TRADE_CONTRIBUTIONS, WORKFLOW_STEPS } from '@src/utils/consts'
import { downloadDocument, getApprovalSheetPreview } from '@src/utils/utils'
import { VuePDF, usePDF } from '@tato30/vue-pdf'
import download from 'downloadjs'

export default {
  name: 'Preview',
  components: {
    VuePDF,
  },
  props: {
    currentStep: {
      type: String,
      default: () => '',
    },
    documentVersion: {
      type: Object,
      default: () => ({}),
    },
    lockedMode: {
      type: Boolean,
      default: () => false,
    },
    docPreview: {
      type: Boolean,
      required: true,
    },
    isLinkActivatedProps: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'toggleDocPreview',
    'sendCommentedPage',
  ],
  data () {
    return {
      pdf: null, // PDF specific object to be use by VuePDF
      dataUrl: null,
      loading: false,
      numPages: 0,
      isLinkActivated: false,
      isCommentedPageClicked: false,
    }
  },
  computed: {
    store () {
      return useIndexStore()
    },
    pdfActions () {
      return ['paperclip', 'download']
    },
    previewVisa () {
      return ![STEP_ADMISSIBILITY, STEP_TRADE_CONTRIBUTIONS].includes(this.currentStep)
    },
    pdfPreview () {
      return this.documentVersion.url.split('.').pop().toLowerCase() === 'pdf'
    },
    sectionCssClass () {
      return this.docPreview ? 'with-preview' : 'without-preview'
    },
    cursorClass () {
      return this.isLinkActivated ? 'active-link' : ''
    },
    hasPDFToDisplay () {
      return this.previewVisa || this.pdfPreview
    },
    showActionButtons () {
      return this.currentStep !== STEP_PM_EVALUATION && this.docPreview
    },
  },
  watch: {
    currentStep (oldV, newV) {
      const stepNames = Object.keys(WORKFLOW_STEPS)
      const changePoint = stepNames.indexOf('STEP_PM_EVALUATION')
      const oldIndex = stepNames.indexOf(oldV)
      const newIndex = stepNames.indexOf(newV)
      if ((oldIndex < changePoint && newIndex >= changePoint) || (oldIndex >= changePoint && newIndex < changePoint)) {
        this.setPreview()
      }
    },
    documentVersion () {
      this.setPreview()
    },
    isLinkActivatedProps () {
      this.isLinkActivated = this.isLinkActivatedProps
      if (this.isLinkActivated) {
        const commentedPage = 0
        this.isCommentedPageClicked = false
        this.$emit('sendCommentedPage', commentedPage, this.isCommentedPageClicked)
      }
    },
  },
  beforeMount () {
    this.setPreview()
  },
  methods: {
    setPreview () {
      this.loading = true
      if (this.previewVisa) {
        return this.getNewPreview()
      } else {
        return this.getDocument()
      }
    },
    getNewPreview () {
      return getApprovalSheetPreview(this.store, this.$router, this.documentVersion.id).then(
        this.setPdfData,
      ).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: true,
        })
      })
    },
    getDocument () {
      return downloadDocument(this.store, this.$router, this.documentVersion.id).then(
        this.setPdfData,
      ).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: true,
        })
      })
    },
    async setPdfData (response) {
      return new Promise((resolve, _reject) => {
        const reader = new FileReader()
        reader.addEventListener('loadend', () => {
          this.dataUrl = reader.result
          const { pdf, pages } = this.hasPDFToDisplay && this.dataUrl ? usePDF(this.dataUrl) : { pdf: null, pages: 0 }
          this.pdf = pdf
          this.numPages = pages
          this.loading = false
          resolve()
        })
        // response.data is a Blob
        reader.readAsDataURL(response.data)
      })
    },
    getPdf (action, Window) {
      Window = Window || window
      if (action === 'paperclip') {
        const url = this.previewVisa ? this.documentVersion.approvalSheetUrl : this.documentVersion.url
        Window.open(url, '_blank')
      } else { // 'download'
        const docv = this.documentVersion
        const baseFileName = docv.codification + docv.separator + docv.index
        const fileName = this.previewVisa ? `${baseFileName}_VISA.pdf` : `${baseFileName}.${docv.url.split('.').pop()}`
        const mimeType = this.dataUrl.split(':')[1].split(';')[0] // data:{mimeType};base64,{content}
        // first argument could be a blob, a file, a string, or a dataURL (here it’s a dataURL)
        download(this.dataUrl, fileName, mimeType)
      }
    },
    togglePreview () {
      this.$emit('toggleDocPreview')
    },
    retrievePageInformation (page) {
      if (this.isLinkActivated) {
        this.isCommentedPageClicked = true
        this.$emit('sendCommentedPage', page, this.isCommentedPageClicked)
        this.isLinkActivated = false
      }
    },
  },
}
</script>
<template>
  <section
    class="card pdf-viewer"
    :class="sectionCssClass"
  >
    <template v-if="loading">
      <div class="progress-container">
        <v-progress-circular
          :width="10"
          :size="100"
          color="primary"
          indeterminate
        />
      </div>
    </template>
    <template v-else>
      <div v-if="hasPDFToDisplay">
        <div class="pdf-viewer-buttons">
          <template v-if="showActionButtons">
            <v-btn
              v-for="action in pdfActions"
              :key="action"
              size="small"
              color="secondary"
              @click="getPdf(action)"
            >
              <v-icon
                size="small"
                color="primary"
                :icon="`fas fa-${action}`"
              />
            </v-btn>
          </template>
          <v-btn
            size="small"
            color="secondary"
            @click="togglePreview"
          >
            <v-icon
              size="small"
              color="primary"
              :icon="docPreview ? 'fas fa-compress' : 'fas fa-expand'"
            />
          </v-btn>
        </div>
        <div
          v-for="page in numPages"
          :key="page"
          :class="cursorClass"
          @click="retrievePageInformation(page)"
        >
          <VuePDF
            v-show="docPreview"
            class="pdf-page"
            :page="page"
            :pdf="pdf"
            :fit-parent="true"
          />
        </div>
      </div>
      <div
        v-else
        class="no-preview-card"
      >
        <v-icon
          color="secondary"
          size="x-large"
          icon="fas fa-unlink"
        />
        <div>
          {{ $gettext("This document is not in PDF format and cannot be visualized.") +
            $gettext("You can, however, download it with the button below.") }}
        </div>
        <v-btn
          color="primary"
          prepend-icon="fas fa-file-download"
          @click="getPdf('download')"
        >
          {{ $gettext('Download') }}
        </v-btn>
      </div>
    </template>
  </section>
</template>
<style lang="scss">
.pdf-viewer {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: relative;
  transition: 0.3s;
  height: 100%;
}
.pdf-viewer.with-preview {
  width: calc(45% - 20px); /* 20px is card padding */
}
.pdf-viewer.without-preview {
  width: calc(26px + 2 * 12px + 20px); /* button size + margins + card padding */
}
.pdf-viewer-buttons {
  position: sticky;
  top: 0;
  left: calc(100%);
  z-index: 100;
  width: fit-content;
  background-color: $secondary;
  border-radius: 5px;
}
.pdf-page {
  box-shadow: 0 10px 20px rgb($black, 0.4);
}
.progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.active-link {
  cursor: crosshair;
}
.no-preview-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 1.2em;
  color: $secondary;
  padding: 80px;
  height: 100%;
}
</style>
