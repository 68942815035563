<script>
import AddMetadatumButton from '@comp/projectSettings/step4Contributions/AddMetadatumButton.vue'
import ContributionsMatrix from '@comp/projectSettings/step4Contributions/ContributionsMatrix.vue'
import {
  getDataStep4,
  updateTradeContributionMatrix,
} from '@comp/projectSettings/step4Contributions/queries'

export default {
  name: 'FormStep4',
  components: {
    AddMetadatumButton,
    ContributionsMatrix,
  },
  props: {
    step: {
      type: Number,
      default: () => 0,
    },
  },
  data () {
    return {
      projectId: null,
      ready: false,
      initMetadata: [],
      changes: false,
      trades: [],
      metadataList: [],
      tradeContributionMatrix: [],
    }
  },
  watch: {
    async step () {
      await this.setData()
    },
  },
  async mounted () {
    this.projectId = this.$route.params.id
    await this.setData()
  },
  methods: {
    getUsedMetadata () {
      const usedMetadata = []
      for (const line of this.tradeContributionMatrix) {
        line.tableData = {}
        for (const metadatum of line.metadata) {
          line.tableData[metadatum.name] = metadatum.value
          if (!usedMetadata.includes(metadatum.name)) {
            usedMetadata.push(metadatum.name)
          }
        }
      }
      return usedMetadata
    },
    async setData () {
      if (this.step === 4) {
        return this.$graphqlQuery(getDataStep4, {
          id: this.projectId,
        }).then(response => {
          const project = response.project
          this.trades = project.trades
          const tradeContributionMatrix = project.tradeContributionMatrix
          this.tradeContributionMatrix = tradeContributionMatrix.sort((a, b) => a.sequence - b.sequence)
          const usedMetadata = this.getUsedMetadata()
          this.initMetadata = project.metadata
          this.metadataList = this.initMetadata.filter(metadata => usedMetadata.includes(metadata.name))
          this.ready = true
        })
      }
    },
    cleanTradeContributionMatrix () {
      for (const contribution of this.tradeContributionMatrix) {
        contribution.metadata = []
        delete contribution.id
        delete contribution.sequence
        for (const metadatum in contribution.tableData) {
          contribution.metadata.push({
            name: metadatum,
            value: contribution.tableData[metadatum],
          })
        }
        delete contribution.tableData
      }
    },
    addMetadatum (metadatum) {
      this.metadataList.push(metadatum)
      this.changes = true
    },
    removeMetadatum (metadatum) {
      this.metadataList = this.metadataList.filter(o => o.name !== metadatum.title)
      for (const contribution of this.tradeContributionMatrix) {
        contribution.metadata = contribution.metadata.filter(o => o.name !== metadatum.title)
        if (contribution.tableData.hasOwnProperty(metadatum.title)) {
          delete contribution.tableData[metadatum.title]
        }
      }
      this.changes = true
    },
    addContribution () {
      const newLine = {
        metadata: [],
        trades: [],
        tableData: {},
        sequence: this.tradeContributionMatrix.length,
      }
      this.tradeContributionMatrix.push(newLine)
      this.changes = true
    },
    deleteContribution (contribution) {
      const contributionIndex = this.tradeContributionMatrix.findIndex(o => o.id === contribution.id)
      this.tradeContributionMatrix.splice(contributionIndex, 1)
      this.tradeContributionMatrix.forEach((item, index) => {
        if (index >= contributionIndex) {
          item.sequence--
        }
      })
      this.changes = true
    },
    changeContributionMetadataValue (value, contribution, metadatum) {
      contribution.tableData[metadatum.name] = value
      this.changes = true
    },
    order (key, item) {
      const tradeContribution = this.tradeContributionMatrix.find(x => x.sequence === item.sequence - key)
      const tradeContributionItem = this.tradeContributionMatrix.find(x => x.sequence === item.sequence)
      tradeContribution.sequence = tradeContribution.sequence + key
      tradeContributionItem.sequence = item.sequence - key
      this.tradeContributionMatrix.sort((a, b) => a.sequence - b.sequence)
      this.changes = true
    },
    async saveData () {
      return new Promise(resolve => {
        if (this.changes) {
          this.cleanTradeContributionMatrix()
          this.$graphqlMutate(updateTradeContributionMatrix, {
            projectId: this.projectId,
            tradeContributionMatrix: this.tradeContributionMatrix,
          }).then(response => {
            this.changes = false
            resolve(response)
          })
        } else {
          resolve()
        }
      })
    },
  },
}
</script>
<template>
  <section
    v-if="ready"
    class="form"
  >
    <div class="form-section">
      <h1>{{ $gettext('Contribution matrix') }}</h1>
      <ContributionsMatrix
        :trade-contribution-matrix="tradeContributionMatrix"
        :metadata-list="metadataList"
        :trades="trades"
        @change-contribution-metadata-value="changeContributionMetadataValue"
        @delete-contribution="deleteContribution"
        @order="order"
        @remove-metadatum="removeMetadatum"
        @update:trade-contribution-matrix="changes = true"
      />
      <div class="row">
        <AddMetadatumButton
          :init-metadata="initMetadata"
          :metadata-list="metadataList"
          @add-metadatum="addMetadatum"
        />
        <v-btn
          color="secondary"
          class="data-table-add-btn"
          prepend-icon="fas fa-plus"
          rounded
          @click="addContribution"
        >
          {{ $gettext('Add contribution') }}
        </v-btn>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.form-section {
  max-height: calc(100vh - 270px);
}
</style>
