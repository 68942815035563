<script>
export default {
  name: 'ContractCard',
  props: {
    contractProp: {
      type: Object,
      required: true,
    },
    contractCodeIsUnique: {
      type: Function,
      required: true,
    },
  },
  emits: [
    'changeContractData',
  ],
  data () {
    return {
      contract: Object.assign({}, this.contractProp),
      codeModificationDisabled: this.contractProp.hasDocuments,
      canDeleteContract: !this.contractProp.hasDocuments,
    }
  },
  methods: {
    changeContractData (contract, action) {
      this.$emit('changeContractData', contract, action)
    },
    retrieveDataContacts (data, contract, company) {
      company.contacts = data
      this.changeContractData(contract, '_updated')
    },
    retrieveDataCompanies (data, contract) {
      const newCompanies = []
      for (let company of data) {
        if (typeof company !== 'object') {
          company = { name: company }
        }
        newCompanies.push(company)
      }
      contract.companies = newCompanies
      this.changeContractData(contract, '_updated')
    },
  },
}
</script>
<template>
  <v-card class="contract-card">
    <v-card-title class="contract-header">
      <v-text-field
        v-model="contract.name"
        color="secondary"
        prepend-inner-icon="fas fa-file-contract"
        hide-details
        :label="$gettext('Contract name')"
        @change="changeContractData(contract, '_updated')"
      />
      <v-tooltip
        location="start"
        color="secondary"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            v-if="canDeleteContract"
            v-bind="props"
            color="$white"
            variant="plain"
            size="small"
            icon="fas fa-trash"
            @click="changeContractData(contract, '_deleted')"
          />
        </template>
        {{ $gettext('Delete contract') }}
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="contract.code"
        prepend-inner-icon="fas fa-hashtag"
        :disabled="codeModificationDisabled"
        :rules="[contractCodeIsUnique(contract.code) || $gettext('Another contract already has the same code')]"
        :label="$gettext('Contract code')"
        @update:model-value="changeContractData(contract, '_updated')"
      />
      <ChipsCombobox
        v-model="contract.companies"
        :label="$gettext('Companies')"
        item-value="name"
        :item-display="item => item.name"
        prepend-inner-icon="fas fa-building"
        hide-details
        @update:model-value="retrieveDataCompanies($event, contract)"
      />
      <ChipsCombobox
        v-for="company in contract.companies"
        :key="company.name"
        v-model="company.contacts"
        :label="`${$gettext('Contacts')} - ${company.name}`"
        item-value="id"
        :item-display="item => item.value"
        prepend-inner-icon="fas fa-building"
        hide-details
        @update:model-value="retrieveDataContacts($event, contract, company)"
      />
    </v-card-text>
  </v-card>
</template>
<style lang="scss" scoped>
.row > .v-text-field {
  width: 100%;
}
.contract-card {
  padding: 0 !important;
  width: calc(33% - 1ch);
  min-width: 350px;
}
.contract-header {
  display: flex;
  align-items: center;
  gap: 1ch;
  background-color: $secondary !important;
  color: $white;
}
.v-card-text {
  display: flex;
  flex-direction: column;
  gap: 1ch;
  padding-top: 1rem !important;
}
</style>
