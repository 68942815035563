import axios from 'axios'
import { backUrl } from '@src/config'
import { loginRoute } from '@src/router'

export function getSafeMetadataName (name) {
  return name.replace('.', '_').replace(' ', '_').replace('[', '').replace(']', '')
}

export function redirectToLoginIfUnauthorized ($store, $router, response) {
  if (response && response.status === 401 && $store.getters.route !== loginRoute.name) {
    $router.push({ name: loginRoute.name })
    return Error('redirected to login')
  } else {
    return null
  }
}

export function downloadDocument ($store, $router, documentId) {
  return axios.get(
    `${backUrl}download-document-version?document_version_id=${documentId}`,
    {
      withCredentials: true,
      responseType: 'blob',
    },
  )
    .catch(error => {
      const redirectError = redirectToLoginIfUnauthorized($store, $router, error.response)
      if (redirectError) {
        error = redirectError
      } else {
        console.error(error)
      }
      return Promise.reject(error)
    })
}

export function getApprovalSheetPreview ($store, $router, documentId) {
  return axios.get(
    `${backUrl}approval-sheet-preview?document_version_id=${documentId}`,
    {
      withCredentials: true,
      responseType: 'blob',
    },
  )
    .catch(error => {
      const redirectError = redirectToLoginIfUnauthorized($store, $router, error.response)
      if (redirectError) {
        error = redirectError
      } else {
        console.error(error)
      }
      return Promise.reject(error)
    })
}
