<script>
import ProjectSection from '@comp/projectSettings/step1Project/ProjectSection.vue'
import ContractsSection from '@comp/projectSettings/step1Project/ContractsSection.vue'
import {
  getDataStep1,
  updateDriveProject,
  deleteContract,
  createContract,
} from '@comp/projectSettings/step1Project/queries'
import {
  updateContract,
} from '@comp/projectSettings/queries'

export default {
  name: 'FormStep1',
  components: {
    ContractsSection,
    ProjectSection,
  },
  props: {
    step: {
      type: Number,
      default: () => 1,
    },
  },
  data () {
    return {
      project: {
        id: null,
        name: '',
        driveUrl: '',
        contracts: [],
        complementarySubdirectories: [],
        documentSubdirectory: '',
        approvalSheetSubdirectory: '',
        hasDocuments: false,
      },
      ready: false,
      contracts: [],
      projectHasChanged: false,
    }
  },
  watch: {
    step () {
      this.setData()
    },
  },
  async mounted () {
    this.project.id = this.$route.params.id
    await this.setData()
  },
  methods: {
    async setData () {
      if (this.step === 1) {
        return this.$graphqlQuery(
          getDataStep1,
          { id: this.project.id },
        ).then(response => {
          this.project = response.project
          const contracts = this.project.contracts
          if (contracts) {
            this.populateCompaniesForContracts(contracts)
          }
          this.contracts = contracts
          this.ready = true
        })
      }
    },
    populateCompaniesForContracts (contracts) {
      for (const contract of contracts) {
        contract.companies = []
        if (contract.companyContracts) {
          for (const company of contract.companyContracts) {
            contract.companies.push({
              name: company.company.name,
              contacts: company.contacts.map(o => o.name),
            })
          }
        }
      }
      return contracts
    },
    onChangeContractData (contract, key) {
      contract[key] = true
      const index = this.contracts.findIndex(o => o.id === contract.id)
      if (key === '_deleted' && contract._created) {
        this.contracts.splice(index, 1)
      } else {
        this.contracts[index] = contract
      }
    },
    onChangeApprovalSheetSubdirectory (newApprovalSheetSubdirectory) {
      this.projectHasChanged = true
      this.project.approvalSheetSubdirectory = newApprovalSheetSubdirectory
    },
    onChangeComplementarySubdirectory (newComplementarySubdirectories) {
      this.projectHasChanged = true
      this.project.complementarySubdirectories = newComplementarySubdirectories
    },
    onChangeDocumentSubdirectory (newDocumentSubdirectory) {
      this.projectHasChanged = true
      this.project.documentSubdirectory = newDocumentSubdirectory
    },
    onChangeName (newName) {
      this.projectHasChanged = true
      this.project.name = newName
    },
    onChangeDriveUrl (newDriveUrl) {
      this.projectHasChanged = true
      this.project.driveUrl = newDriveUrl
    },
    onAddContract () {
      this.contracts.push({
        id: Math.random().toString(36).substr(2, 9),
        name: '',
        companies: [],
        code: '',
        managerUsernames: [],
        _created: true,
      })
    },
    buildUpdateProjectQuery () {
      return this.$graphqlMutate(updateDriveProject, {
        id: this.project.id,
        name: this.project.name,
        driveUrl: this.project.driveUrl,
        documentSubdirectory: this.project.documentSubdirectory.trim(),
        approvalSheetSubdirectory: this.project.approvalSheetSubdirectory,
        complementarySubdirectories: this.project.complementarySubdirectories,
      })
    },
    buildCreateContractQuery (contract) {
      delete contract._created
      delete contract._updated
      return this.$graphqlMutate(createContract, {
        contract: {
          projectId: this.project.id,
          name: contract.name,
          code: contract.code,
        },
      })
    },
    buildDeleteContractQuery (contract) {
      return this.$graphqlMutate(deleteContract, {
        id: contract.id,
      }).then(response => {
        this.contracts = this.contracts.filter(o => o.id !== response.deleteContract.id)
      })
    },
    buildUpdateContractQuery (contract) {
      delete contract._updated
      return this.$graphqlMutate(updateContract, {
        id: contract.id,
        contract: {
          name: contract.name,
          code: contract.code,
          managerUsernames: contract.managers.map(o => o.username),
          companies: contract.companies,
        },
      })
    },
    buildContractModificationQuery (contract) {
      if (contract._deleted && !contract._created) {
        return this.buildDeleteContractQuery(contract)
      } else if (contract._created && !contract._deleted) {
        return this.buildCreateContractQuery(contract)
      } else if (contract._updated) {
        return this.buildUpdateContractQuery(contract)
      }
    },
    buildContractPromises () {
      const promises = []
      for (const contract of this.contracts) {
        const contractModificationQuery = this.buildContractModificationQuery(contract)
        if (contractModificationQuery) {
          promises.push(contractModificationQuery)
        }
      }
      return promises
    },
    saveData () {
      return new Promise((resolve, reject) => {
        let promises = []
        if (this.projectHasChanged) {
          promises.push(this.buildUpdateProjectQuery())
        }
        promises = promises.concat(this.buildContractPromises())
        Promise.all(promises).then(response => {
          this.projectHasChanged = false
          resolve(response)
        }).catch(error => reject(error))
      })
    },
  },
}
</script>
<template>
  <section class="form">
    <ProjectSection
      :project-prop="project"
      @change-name="onChangeName"
      @change-drive-url="onChangeDriveUrl"
      @change-document-subdirectory="onChangeDocumentSubdirectory"
      @change-approval-sheet-subdirectory="onChangeApprovalSheetSubdirectory"
      @change-complementary-subdirectories="onChangeComplementarySubdirectory"
    />
    <ContractsSection
      :contracts="contracts"
      :has-documents="project.hasDocuments"
      @change-contract-data="onChangeContractData"
      @add-contract="onAddContract"
    />
  </section>
</template>
