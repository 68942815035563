<script>
import CodificationSection from '@comp/projectSettings/step3Documents/CodificationSection.vue'
import MetadataSection from '@comp/projectSettings/step3Documents/MetadataSection.vue'
import WorkflowsSection from '@comp/projectSettings/step3Documents/WorkflowsSection.vue'

import {
  getDataStep3,
  updateStep3,
} from '@comp/projectSettings/step3Documents/queries'

const $gettext = s => s // to force vue-i18n-extract to see translations

export default {
  name: 'FormStep3',
  components: {
    CodificationSection,
    MetadataSection,
    WorkflowsSection,
  },
  props: {
    step: {
      type: Number,
      default: () => 0,
    },
  },
  data () {
    return {
      projectId: null,
      ready: false,
      metadata: [],
      approvalSheetSuffix: '',
      approvalSheetPrefix: '',
      unicityList: [{ name: $gettext('Contract'), _default: true }],
      codificationList: [],
      separator: '',
      titleList: [],
      indexList: [],
      workflowTemplates: [],
      // step workflow type => label type Matching
      labelTypes: {
        Admissibility: 'admissibilityList',
        PMEvaluation: 'pmEvaluationList',
        EvaluationSummary: 'summaryStateList',
      },
      changes: false,
      hasDocuments: false,
    }
  },
  watch: {
    async step () {
      await this.setData()
    },
    async '$vuetify.lang.current' () {
      this.unicityList.find(o => o._default).name = $gettext('Contract')
    },
  },
  async mounted () {
    this.projectId = this.$route.params.id
    await this.setData()
  },
  methods: {
    onMetadataUpdate () {
      const metadataNames = this.metadata.map(o => o.name)
      this.unicityList = this.unicityList.filter(o => metadataNames.includes(o.name) || o._default)
      this.codificationList = this.codificationList.filter(o => metadataNames.includes(o.name))
      this.titleList = this.titleList.filter(o => metadataNames.includes(o.name))
      this.indexList = this.indexList.filter(o => metadataNames.includes(o.name))
      this.changes = true
    },
    onChangeApprovalSheetPrefix (prefix) {
      this.approvalSheetPrefix = prefix
      this.changes = true
    },
    onChangeApprovalSheetSuffix (suffix) {
      this.approvalSheetSuffix = suffix
      this.changes = true
    },
    validate () {
      if (this.$refs.form) {
        this.$refs.form.validate()
      }
    },
    async setData () {
      if (this.step === 3) {
        return this.$graphqlQuery(getDataStep3, {
          id: this.projectId,
        }).then(response => {
          const project = response.project
          this.hasDocuments = project.hasDocuments
          if (project.codification) {
            this.unicityList = project.codification.unicityMetadata
            this.unicityList.unshift({ name: $gettext('Contract'), _default: true })
            this.codificationList = project.codification.codificationMetadata
            this.separator = project.codification.separator
            this.indexList = project.codification.indexMetadata
            this.titleList = project.codification.titleMetadata
            this.approvalSheetSuffix = project.approvalSheetSuffix
            this.approvalSheetPrefix = project.approvalSheetPrefix
          }
          this.metadata = project.metadata.sort((a, b) => a.sequence - b.sequence)
          this.workflowTemplates = project.workflowTemplates
          // Remove EndOfWorkflow if in workflowTemplate
          for (const workflowTemplate of this.workflowTemplates) {
            while (workflowTemplate.steps[workflowTemplate.steps.length - 1].type === 'EndOfWorkflow') {
              workflowTemplate.steps.pop()
            }
          }
          this.ready = true
        })
      }
    },
    updateSeparator (newSeparator) {
      this.changes = true
      this.separator = newSeparator
    },
    retrieveData (data, key, attr) {
      this.changes = true
      if (typeof key === 'object') {
        key[attr] = data
        if (attr === 'choices') {
          const index = this.metadata.findIndex(o => o.id === key.id)
          this.metadata[index] = key
        }
      } else {
        this[key] = data
      }
    },
    saveData () {
      return new Promise((resolve, reject) => {
        if (this.changes) {
          const tmpMetadata = this.metadata.sort((a, b) => a.sequence - b.sequence).map(metadatum => ({
            name: metadatum.name,
            isShown: metadatum.isShown,
            isChoices: metadatum.isChoices,
            stringFormat: metadatum.isChoices ? null : metadatum.stringFormat,
            choices: metadatum.isChoices ? metadatum.choices : [],
          }))
          const tmpCodification = {
            separator: this.separator,
            codificationList: this.codificationList.map(o => o.name),
            unicityList: this.unicityList.filter(o => !o._default).map(o => o.name),
            indexList: this.indexList.map(o => o.name),
            titleList: this.titleList.map(o => o.name),
          }
          for (const workflowTemplate of this.workflowTemplates) {
            // Ensure days0 and daysX are integers
            workflowTemplate.steps.forEach(stepTemplate => {
              stepTemplate.days0 = 1 * stepTemplate.days0 || 0
              stepTemplate.daysX = 1 * stepTemplate.daysX || 0
            })
            // Add EndOfWorkflow if missing in workflowTemplate
            if (workflowTemplate.steps[workflowTemplate.steps.length - 1].type !== 'EndOfWorkflow') {
              workflowTemplate.steps.push({
                label: $gettext('End of Workflow'),
                type: 'EndOfWorkflow',
                days0: 0,
                daysX: 0,
              })
            }
          }
          const tmpWorkflowTemplates = this.workflowTemplates.sort((a, b) => a.sequence - b.sequence)
          for (const tmpWorkflowTemplate of tmpWorkflowTemplates) {
            delete tmpWorkflowTemplate.sequence
            delete tmpWorkflowTemplate.hasDocuments
            if (tmpWorkflowTemplate._created) {
              tmpWorkflowTemplate.id = null
            }
            delete tmpWorkflowTemplate._created
            if (tmpWorkflowTemplate.metadataName === null) {
              delete tmpWorkflowTemplate.metadataName
              delete tmpWorkflowTemplate.metadataValues
            }
            for (const labelType of Object.values(this.labelTypes)) {
              if (tmpWorkflowTemplate.labels[labelType]) {
                tmpWorkflowTemplate.labels[labelType].forEach(label => {
                  delete label._created
                })
              }
            }
          }
          this.$graphqlMutate(updateStep3, {
            projectId: this.projectId,
            metadata: tmpMetadata,
            codification: tmpCodification,
            workflowTemplates: tmpWorkflowTemplates,
            approvalSheetPrefix: this.approvalSheetPrefix,
            approvalSheetSuffix: this.approvalSheetSuffix,
          }).then(response => {
            this.changes = false
            resolve(response)
          }).catch(error => reject(error))
        } else {
          resolve()
        }
      })
    },
  },
}
</script>
<template>
  <section
    v-if="ready"
    class="form"
  >
    <MetadataSection
      v-model:metadata="metadata"
      :has-documents="hasDocuments"
      @update:metadata="onMetadataUpdate"
    />
    <CodificationSection
      :disabled="hasDocuments"
      :separator-prop="separator"
      :unicity-list="unicityList"
      :codification-list="codificationList"
      :index-list="indexList"
      :title-list="titleList"
      :active-metadata="metadata"
      :approval-sheet-prefix="approvalSheetPrefix"
      :approval-sheet-suffix="approvalSheetSuffix"
      @change-separator="updateSeparator"
      @retrieve-data="retrieveData"
      @validate="validate"
      @change-approval-sheet-prefix="onChangeApprovalSheetPrefix"
      @change-approval-sheet-suffix="onChangeApprovalSheetSuffix"
    />
    <WorkflowsSection
      v-model:workflow-templates="workflowTemplates"
      :label-types="labelTypes"
      :active-metadata="metadata"
      @update:workflow-templates="changes = true"
    />
  </section>
</template>
