<script>
import {
  validateSharepointFilename,
  maxStringLengthRule,
  stringCannotBeEmptyRule,
} from '@src/utils/userInputValidation'
const MAX_CHARACTERS = 30

export default {
  name: 'ProjectSection',
  props: {
    projectProp: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'changeName',
    'changeDriveUrl',
    'changeDocumentSubdirectory',
    'changeComplementarySubdirectories',
    'changeApprovalSheetSubdirectory',
  ],
  data () {
    return {
      name: '',
      driveUrl: '',
      hasDocuments: false,
      documentSubdirectory: '',
      approvalSheetSubdirectory: '',
    }
  },
  computed: {
    complementarySubdirectoryObjects () {
      return this.projectProp.complementarySubdirectories.map(d => ({ name: d }))
    },
    complementarySubdirectoryNames () {
      return this.complementarySubdirectoryObjects.map(d => (d.name))
    },
    placeholder () {
      return `https://<${this.$gettext('Company name')}>.sharepoint.com/sites/<${this.$gettext('Site name')}>/VIZEO`
    },
    documentSubdirectoryRules () {
      return [
        maxStringLengthRule(this.documentSubdirectory, MAX_CHARACTERS)
        || this.$gettext(`This field cannot contain more than ${MAX_CHARACTERS} characters`),
        stringCannotBeEmptyRule(this.documentSubdirectory)
        || this.$gettext('This field cannot be empty'),
        validateSharepointFilename(this.documentSubdirectory)
        || this.$gettext('This field cannot contain any of the following characters: " * : < > ? / \\ |'),
      ]
    },
    approvalSheetSubdirectoryRules () {
      return [
        maxStringLengthRule(this.approvalSheetSubdirectory, MAX_CHARACTERS)
        || this.$gettext(`This field cannot contain more than ${MAX_CHARACTERS} characters`),
        stringCannotBeEmptyRule(this.approvalSheetSubdirectory)
        || this.$gettext('This field cannot be empty'),
        validateSharepointFilename(this.approvalSheetSubdirectory)
        || this.$gettext('This field cannot contain any of the following characters: " * : < > ? / \\ |'),
      ]
    },
    complementarySubdirectoryNameRules (complementarySubdirectory) {
      return [
        maxStringLengthRule(complementarySubdirectory.name, MAX_CHARACTERS)
        || this.$gettext(`This field cannot contain more than ${MAX_CHARACTERS} characters`),
        stringCannotBeEmptyRule(complementarySubdirectory.name)
        || this.$gettext('This field cannot be empty'),
        validateSharepointFilename(complementarySubdirectory.name)
        || this.$gettext('This field cannot contain any of the following characters: " * : < > ? / \\ |'),
      ]
    },
  },
  watch: {
    async projectProp () {
      await this.setData()
    },
  },
  async mounted () {
    await this.setData()
  },
  methods: {
    async setData () {
      this.name = this.projectProp.name
      this.driveUrl = this.projectProp.driveUrl
      this.hasDocuments = this.projectProp.hasDocuments
      this.documentSubdirectory = this.projectProp.documentSubdirectory
      this.approvalSheetSubdirectory = this.projectProp.approvalSheetSubdirectory
    },
    validateSharepointFilename,
    maxStringLengthRule,
    stringCannotBeEmptyRule,
    complementarySubdirectoryLabel (index) {
      return `${this.$gettext('Complementary subdirectory')} ${(index + 1)}`
    },
    isValidDriveUrl (text) {
      const pattern = /^(https:\/\/).+(\.sharepoint\.com\/).*(sites\/).+$/
      return pattern.test(text) || this.$gettext('Incorrect value')
    },
    onChangeComplementarySubdirectory () {
      this.$emit('changeComplementarySubdirectories', this.complementarySubdirectoryNames)
    },
    addComplementarySubdirectory () {
      this.complementarySubdirectoryNames.push('')
      this.$emit('changeComplementarySubdirectories', this.complementarySubdirectoryNames)
    },
    deleteComplementarySubdirectory (index) {
      this.complementarySubdirectoryNames.splice(index, 1)
      this.$emit('changeComplementarySubdirectories', this.complementarySubdirectoryNames)
    },
    onChangeName () {
      this.$emit('changeName', this.name)
    },
    onChangeDriveUrl () {
      this.$emit('changeDriveUrl', this.driveUrl)
    },
    onChangeDocumentSubdirectory () {
      this.$emit('changeDocumentSubdirectory', this.documentSubdirectory)
    },
    onChangeApprovalSheetSubdirectory () {
      this.$emit('changeApprovalSheetSubdirectory', this.approvalSheetSubdirectory)
    },
  },
}
</script>
<template>
  <section class="form-section">
    <h1>{{ $gettext('Project') }}</h1>
    <v-card>
      <v-card-text>
        <v-text-field
          v-model="name"
          color="secondary"
          :label="$gettext('Project name')"
          @change="onChangeName"
        />
        <v-text-field
          v-model="driveUrl"
          color="secondary"
          :label="$gettext('Sharepoint link')"
          :placeholder="placeholder"
          :disabled="hasDocuments"
          :rules="[isValidDriveUrl]"
          @change="onChangeDriveUrl"
        />
      </v-card-text>
    </v-card>
    <v-card
      prepend-icon="fa-brands fa-microsoft"
      :title="$gettext('Sharepoint subfolders')"
    >
      <v-card-text>
        <v-text-field
          v-model="documentSubdirectory"
          color="secondary"
          :label="$gettext('Name of the folder containing the uploaded document')"
          :rules="documentSubdirectoryRules"
          @change="onChangeDocumentSubdirectory"
        />
        <v-text-field
          v-model="approvalSheetSubdirectory"
          color="secondary"
          :label="$gettext('Name of the folder containing the approval sheets')"
          :rules="approvalSheetSubdirectoryRules"
          @change="onChangeApprovalSheetSubdirectory"
        />
      </v-card-text>
    </v-card>
    <v-card
      prepend-icon="fas fa-folder"
      :title="$gettext('Complementary subdirectories')"
    >
      <v-card-text>
        <div
          v-for="(complementarySubdirectory, i) in complementarySubdirectoryObjects"
          :key="i"
          class="row"
        >
          <v-text-field
            v-model="complementarySubdirectory.name"
            color="secondary"
            :label="complementarySubdirectoryLabel(i)"
            :rules="complementarySubdirectoryNameRules"
            prepend-inner-icon="fas fa-folder-open"
            @update:model-value="onChangeComplementarySubdirectory"
          />
          <v-btn
            icon="fas fa-trash"
            variant="text"
            @click="deleteComplementarySubdirectory(i)"
          />
        </div>
        <v-btn
          color="secondary"
          prepend-icon="fas fa-folder-plus"
          rounded
          @click="addComplementarySubdirectory"
        >
          {{ $gettext('Add a complementary subdirectory') }}
        </v-btn>
      </v-card-text>
    </v-card>
  </section>
</template>
