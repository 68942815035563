<script>
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import sanitizeHtml from 'sanitize-html'
import '@ckeditor/ckeditor5-build-classic/build/translations/fr'

export default {
  name: 'TextEditor',
  components: {
    ckeditor: CKEditor.component,
  },
  props: {
    textProp: {
      type: String,
      default: () => '',
    },
  },
  emits: [
    'change',
  ],
  data () {
    return {
      text: this.textProp,
      editor: null,
      editorConfig: {
        toolbar: {
          items: ['heading', '|', 'bold', 'italic', 'bulletedList'],
          shouldNotGroupWhenFull: true,
        },
        placeholder: this.$gettext('Observations'),
        language: null, /* UI and content language */
      },
    }
  },
  watch: {
    text (newVal, oldVal) {
      const allowedTags = [
        'h1',
        'h2',
        'h3',
        'h4',
        'p',
        'ul',
        'ol',
        'li',
        'b',
        'i',
        'strong',
        'em',
        'br',
      ]
      const parser = { decodeEntities: false }
      const newValSanitized = sanitizeHtml(newVal, { allowedTags, parser })
      const oldValSanitized = sanitizeHtml(oldVal, { allowedTags, parser })
      if (newValSanitized !== oldValSanitized || newValSanitized !== newVal) {
        this.text = newValSanitized
      }
    },
  },
  beforeMount () {
    this.initEditor()
  },
  methods: {
    async initEditor () {
      this.editor = ClassicEditor
      this.setCurrentLanguage()
    },
    setCurrentLanguage () {
      this.editorConfig.language = this.$vuetify.locale.current
    },
    change () {
      this.$emit('change', this.text)
    },
  },
}
</script>
<template>
  <div>
    <ckeditor
      v-model="text"
      :editor="editor"
      :config="editorConfig"
      @input="change"
    />
  </div>
</template>
<style lang="scss" scoped>
:deep(.ck.ck-editor__main > .ck-editor__editable) { // stylelint-disable-line selector-class-pattern
  min-height: 25vh;
}
</style>
