export function validateSharepointFilename (text) {
  const specialCharacters = ['"', '*', ':', '<', '>', '?', '/', '|', '\\']
  return !specialCharacters.some(character => text.includes(character))
}

export function maxStringLengthRule (text, len) {
  return text.length <= len
}

export function stringCannotBeEmptyRule (text) {
  return text !== null && text.length > 0
}
