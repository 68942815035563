<script>
import ProjectCard from '@comp/home/ProjectCard.vue'
import {
  askProjectPerms,
  deleteProject,
  getAllProjects,
  makeProjectFavorite,
  openProject,
} from '@comp/home/queries'
import { projectRoute } from '@src/router'
import { useIndexStore } from '@src/store'

export default {
  name: 'ProjectList',
  components: {
    ProjectCard,
  },
  data () {
    return {
      projects: [],
    }
  },
  computed: {
    orderedProjects () {
      return this.projects.slice().sort(this.orderProjects)
    },
    store () {
      return useIndexStore()
    },
    createdProject () {
      return this.store.createdProject
    },
    isAdmin () {
      const user = this.store.user
      return user && !!user.isAdmin
    },
  },
  watch: {
    createdProject () {
      this.projects.push(this.store.createdProject)
    },
  },
  async mounted () {
    await this.setData()
  },
  methods: {
    async setData () {
      await this.$graphqlQuery(
        getAllProjects,
      ).then(response => {
        this.projects.push(...response.allProjects)
      }).catch(console.error)
    },
    async onEnterProject (project) {
      if (project.isPublished) {
        const partialProject = await this.$graphqlQuery(askProjectPerms, { id: project.id })
          .then(response => {
            return response.project
          }).catch(error => {
            this.store.changeNotification({
              type: 'error',
              text: error,
              autoClose: false,
            })
          })
        if (!partialProject) {
          return
        }
        if (!partialProject.permissions.doIHaveAccess) {
          const error = this.$gettext('You don’t have sufficient rights to open this project')
          this.store.changeNotification({
            type: 'error',
            text: error,
            autoClose: false,
          })
          return
        }
        const fullProject = await this.$graphqlQuery(openProject, { id: project.id })
          .then(response => {
            project.docControllers = response.project.docControllers
            project.permissions = response.project.permissions
            return project
          }).catch(error => {
            this.store.changeNotification({
              type: 'error',
              text: error,
              autoClose: false,
            })
          })
        if (!fullProject) {
          return
        }
        this.store.changeProject(fullProject)
        this.$router.push({
          name: projectRoute.name,
          params: {
            id: fullProject.id,
          },
        })
      }
    },
    orderProjects (a, b) {
      if (a.isFavorite !== b.isFavorite) {
        return a.isFavorite ? -1 : 1
      } else {
        if (a.isPublished !== b.isPublished) {
          return a.isPublished ? 1 : -1
        } else {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        }
      }
    },
    onDeleteProject (project) {
      return this.$graphqlMutate(deleteProject, { id: project.id })
        .then(response => {
          this.projects = this.projects.filter(o => o.id !== response.deleteProject.id)
        }).catch(error => {
          this.store.changeNotification({
            type: 'error',
            text: error,
            autoClose: false,
          })
        })
    },
    onMakeProjectFavorite (projectId, isFavorite) {
      this.projects.find(project => project.id === projectId).isFavorite = isFavorite
      return this.$graphqlMutate(makeProjectFavorite, {
        id: projectId,
        isFavorite: isFavorite,
      }).then(() => {
        if (isFavorite) {
          this.store.changeNotification({
            text: this.$gettext('Project successfully added to favorites'),
            autoClose: true,
            type: 'positive',
          })
        } else {
          this.store.changeNotification({
            text: this.$gettext('Project successfully removed from favorites'),
            autoClose: true,
            type: 'positive',
          })
        }
      }).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
      })
    },
  },
}
</script>
<template>
  <section class="projects">
    <div class="project-cards-container">
      <ProjectCard
        v-for="project in orderedProjects"
        :key="project.id"
        :item="project"
        :is-admin="isAdmin"
        @make-project-favorite="onMakeProjectFavorite"
        @enter-project="onEnterProject"
        @delete-project="onDeleteProject"
      />
    </div>
  </section>
</template>
<style lang="scss" scoped>
.projects {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
  min-height: 10%;
}
.project-cards-container {
  display: flex;
  flex-direction: column;
  gap: 1ch;
  max-height: 100%;
  overflow-y: auto;
  padding-right: 5px;
  padding-bottom: 10px;
}
</style>
