import gql from 'graphql-tag'

export const currentDocumentVersion = gql`
  query currentDocumentVersion($id: ID!) {
    documentVersion(pk: $id) {
      id
      originGedUrl
      sequence
      isLatestVersion
      url
      directoryUrl
      approvalSheetUrl
      codification
      title
      index
      separator
      receivedDatetime
      workflowStartDatetime
      document {
        id
        unicity
        project {
          id
          name
          isOmanType
          docControllers {
            id
          }
          permissions {
            doIHaveAccess
          }
        }
        contract {
          id
          code
          name
        }
        versions {
          id
          index
          sequence
          isLatestVersion
        }
      }
      currentStepTemplate {
        type
      }
      previousStepTemplate {
        type
      }
      nextStepTemplate {
        type
      }
      metadataValues {
        metadata {name}
        value
      }
      stepTransitions {
        datetime
        currentStepTemplate {
          type
        }
      }
    }
  }
`

export const deleteDocumentVersion = gql`
  mutation deleteDocumentVersion($documentVersionId: ID!) {
    deleteDocumentVersion(pk: $documentVersionId) {
      id
    }
  }
`
