import {
  commentFragment,
  companyResponseFragment,
  initialCommentFragment,
} from '@comp/document/comments/queries'
import gql from 'graphql-tag'

export const threadBaseFragment = gql`
  fragment threadBaseFragment on Thread {
    id
    sequence
    importance
    suggestedToClose
    closed
    createdAtVersion {
      index
    }
    trade {
      id
      acronym
      name
    }
  }
`
export const threadPermissionsFragment = gql`
  fragment threadPermissionsFragment on Thread {
    permissions {
      canAddComment
      canSuggestClosing
      canCancelClosingSuggestion
      canRejectClosingSuggestion
      canCloseThread
      canReopenThread
    }
  }
`
export const threadPermissionsVDocIdFragment = gql`
  fragment threadPermissionsVDocIdFragment on Thread {
    permissions(documentVersionId: $documentVersionId) {
      canAddComment
      canSuggestClosing
      canCancelClosingSuggestion
      canRejectClosingSuggestion
      canCloseThread
      canReopenThread
    }
  }
`
export const threadHistoryVDocIdSeqFragment = gql`
  ${threadBaseFragment}
  ${initialCommentFragment}
  ${commentFragment}
  ${companyResponseFragment}
  fragment threadHistoryVDocIdSeqFragment on Thread {
    ...threadBaseFragment
    initialComments(filters: {
      documentVersion: {id: {exact: $documentVersionId}}
    }) {
      ...initialCommentFragment
        }
    allComments(filters: {
      documentVersion: {sequence: {lt: $documentVersionSequence}}
    }){
      ... on Comment {
        ...commentFragment
      }
      ... on CompanyResponse {
        ...companyResponseFragment
      }
    }
  }
`
export const threadVDocIdFragment = gql`
  ${threadBaseFragment}
  ${threadPermissionsVDocIdFragment}
  ${initialCommentFragment}
  ${commentFragment}
  ${companyResponseFragment}
  fragment threadVDocIdFragment on Thread {
    ...threadBaseFragment
    ...threadPermissionsVDocIdFragment
    initialComments(filters: {
      documentVersion: {id: {exact: $documentVersionId}}
    }) {
      ...initialCommentFragment
    }
    allComments {
      ... on Comment {
        ...commentFragment
      }
      ... on CompanyResponse {
        ...companyResponseFragment
      }
    }
  }
`
export const threadPmEvaluationVDocIdSeqFragment = gql`
  ${threadBaseFragment}
  ${threadPermissionsVDocIdFragment}
  ${commentFragment}
  ${companyResponseFragment}
  fragment threadPmEvaluationVDocIdSeqFragment on Thread {
    ...threadBaseFragment
    ...threadPermissionsVDocIdFragment
    allComments(filters: {
      documentVersion: {sequence: {lte: $documentVersionSequence}}
    }){
      ... on Comment {
        ...commentFragment
      }
      ... on CompanyResponse {
        ...companyResponseFragment
      }
    }
  }
`
export const getThreadPermissions = gql`
  ${threadPermissionsFragment}
  query getThreadPermissions(
    $id: ID!
  ) {
    thread(pk: $id) {
      ...threadPermissionsFragment
    }
  }
`
export const createFirstComment = gql`
  ${threadVDocIdFragment}
  mutation createFirstComment(
    $tradeContributionId: ID!
    $documentVersionId: ID! # for fragment
    $importance: Importance!
    $commentedPage: Int!
    $text: String!
    $image: Upload
  ) {
    createFirstComment(data: {
      tradeContributionId: $tradeContributionId
      importance: $importance
      commentedPage: $commentedPage
      text: $text
      image: $image
    }) {
      ...threadVDocIdFragment
    }
  }
`
export const updateThreadImportance = gql`
  ${threadBaseFragment}
  mutation updateThreadImportance(
    $id: ID!
    $importance: Importance!
  ) {
    updateThreadImportance(
      pk: $id
      data: {
        importance: $importance
      }
    ) {
      ...threadBaseFragment
    }
  }
`
export const suggestClosingThread = gql`
  ${threadVDocIdFragment}
  mutation suggestClosingThread(
    $id: ID!
    $documentVersionId: ID! # for fragment
  ) {
    suggestClosingThread(pk: $id) {
      ...threadVDocIdFragment
    }
  }
`
export const cancelClosingSuggestionOnThread = gql`
  ${threadVDocIdFragment}
  mutation cancelClosingSuggestionOnThread(
    $id: ID!
    $documentVersionId: ID! # for fragment
  ) {
    cancelClosingSuggestionOnThread(pk: $id) {
      ...threadVDocIdFragment
    }
  }
`
export const rejectClosingSuggestionOnThread = gql`
  ${threadVDocIdFragment}
  mutation rejectClosingSuggestionOnThread(
    $id: ID!
    $documentVersionId: ID! # for fragment
  ) {
    rejectClosingSuggestionOnThread(pk: $id) {
      ...threadVDocIdFragment
    }
  }
`
export const closeThread = gql`
  ${threadVDocIdFragment}
  mutation closeThread(
    $id: ID!
    $documentVersionId: ID! # for fragment
  ) {
    closeThread(pk: $id) {
      ...threadVDocIdFragment
    }
  }
`
export const reopenThread = gql`
  ${threadVDocIdFragment}
  mutation reopenThread(
    $id: ID!
    $documentVersionId: ID! # for fragment
  ) {
    reopenThread(pk: $id) {
      ...threadVDocIdFragment
    }
  }
`
