<script>
import ProjectSettingsStepper from '@comp/projectSettings/ProjectSettingsStepper.vue'
import ProjectSettingsStepperButtons from '@comp/projectSettings/ProjectSettingsStepperButtons.vue'
import {
  currentProject,
  updatePublishedProject,
} from '@comp/projectSettings/queries'
import { homeRoute } from '@src/router'
import { useIndexStore } from '@src/store'

export default {
  name: 'ProjectSettings',
  components: {
    ProjectSettingsStepper,
    ProjectSettingsStepperButtons,
  },
  data () {
    return {
      currentStep: 1,
      saveStepState: false,
    }
  },
  computed: {
    store () {
      return useIndexStore()
    },
  },
  async mounted () {
    this.projectId = this.$route.params.id
    this.store.changeRoute(this.$options.name)
    return this.$graphqlQuery(currentProject, {
      id: this.projectId,
    }).then(response => {
      this.store.changeProject(response.project)
    })
  },
  methods: {
    saveStep () {
      return new Promise((resolve, reject) => {
        this.$refs.stepper.saveData().then(() => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    onChangeStep (value) {
      return this.saveStep().then(() => {
        this.currentStep = value
      }).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
      })
    },
    onSaveAndQuit () {
      return this.saveStep().then(() => {
        this.$router.push({ name: homeRoute.name })
      }).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
      })
    },
    async onPublishProject () {
      return this.saveStep().then(() => {
        return this.$graphqlMutate(updatePublishedProject, {
          id: this.projectId,
          isPublished: true,
        }).then(() => {
          this.$router.push({ name: homeRoute.name })
          this.store.changeNotification({
            text: this.$gettext('Project successfully published'),
            autoClose: true,
            type: 'positive',
          })
        }).catch(error => {
          this.store.changeNotification({
            type: 'error',
            text: error,
            autoClose: false,
          })
        })
      }).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
      })
    },
  },
}
</script>
<template>
  <section class="view project-settings">
    <ProjectSettingsStepper
      ref="stepper"
      :current-step="currentStep"
      @change-step="onChangeStep"
    />
    <ProjectSettingsStepperButtons
      :step="currentStep"
      @change-step="onChangeStep"
      @save-and-quit="onSaveAndQuit"
      @publish-project="onPublishProject"
    />
  </section>
</template>
<style lang="scss" scoped>
.project-settings {
  flex-direction: column;
  padding: 0;
  gap: 0;
}
</style>
