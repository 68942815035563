<script>
import WorkflowStepCard from '@comp/projectSettings/step3Documents/WorkflowStepCard.vue'

export default {
  name: 'WorkflowCard',
  components: {
    WorkflowStepCard,
  },
  props: {
    workflowTemplate: {
      type: Object,
      required: true,
    },
    labelTypes: {
      type: Object,
      required: true,
    },
    workflows: {
      type: Array,
      required: true,
    },
    workflowTemplates: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    activeMetadata: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'update:workflowTemplate',
    'removeWorkflowTemplate',
    'changeWorkflowTemplateType',
    'orderWorkflowTemplate',
  ],
  computed: {
    hasDocuments () {
      return this.workflowTemplate.hasDocuments
    },
    isFirstWorkflowTemplateSequence () {
      return this.index === 0
    },
    isLastWorkflowTemplateSequence () {
      return this.index === this.workflowTemplates.length - 1
    },
    isPenultimateWorkflowSequence () {
      return this.index === this.workflowTemplates.length - 2
    },
  },
  methods: {
    removeWFT () {
      this.$emit('removeWorkflowTemplate', this.workflowTemplate)
    },
    daysTotal (workflowTemplate, key) {
      return workflowTemplate.steps.reduce((a, b) => parseInt(a) + (parseInt(b[key]) || 0), 0)
    },
    changeWorkflowTemplateType (value, workflowTemplate) {
      this.$emit('changeWorkflowTemplateType', value, workflowTemplate)
    },
    deleteMetadataNameAndValues (offset) {
      if ((this.isPenultimateWorkflowSequence && offset === 1)
        || (this.isLastWorkflowTemplateSequence && offset === -1)) {
        delete this.workflowTemplate.metadataName
        delete this.workflowTemplate.metadataValues
      }
    },
    orderWorkflowTemplate (offset) { // offset could be +1 or -1
      this.deleteMetadataNameAndValues(offset)
      this.$emit('orderWorkflowTemplate', this.workflowTemplate, offset)
    },
  },
}
</script>
<template>
  <v-card>
    <v-card-title class="workflow-header">
      <v-select
        v-model="workflowTemplate.name"
        :items="workflows"
        :label="$gettext('Type de workflow')"
        :item-title="item => item.text"
        :disabled="hasDocuments"
        hide-details
        @update:model-value="changeWorkflowTemplateType($event, workflowTemplate)"
      />
      <div>
        <v-btn
          size="small"
          variant="text"
          icon="fas fa-arrow-up"
          :disabled="isFirstWorkflowTemplateSequence"
          @click="orderWorkflowTemplate(-1)"
        />
        <v-btn
          size="small"
          variant="text"
          icon="fas fa-arrow-down"
          :disabled="isLastWorkflowTemplateSequence"
          @click="orderWorkflowTemplate(+1)"
        />
        <v-btn
          color="white"
          :disabled="hasDocuments"
          variant="plain"
          size="small"
          icon="fas fa-trash"
          @click="removeWFT(workflowTemplate)"
        />
      </div>
    </v-card-title>
    <v-card-text v-if="workflowTemplate.name">
      <div
        v-if="index !== workflowTemplates.length - 1"
        class="row"
      >
        <div class="workflow-conditions">
          <div>
            {{ $gettext('Use if') }}
          </div>
          <v-select
            v-model="workflowTemplate.metadataName"
            :items="activeMetadata"
            item-title="name"
            hide-details
          />
        </div>
        <div class="workflow-conditions">
          <div>
            {{ $gettext('Has one of the following values') }}
          </div>
          <ChipsCombobox
            v-model="workflowTemplate.metadataValues"
            hide-details
          />
        </div>
      </div>
      <div
        v-if="index === workflowTemplates.length - 1 && workflowTemplates.length > 1"
      >
        {{ $gettext('Else use') }}
      </div>
      <div class="steps-section">
        <div class="steps-container">
          <WorkflowStepCard
            v-for="stepWFT in workflowTemplate.steps"
            :key="stepWFT.label"
            :step-w-f-t="stepWFT"
            :workflow-template="workflowTemplate"
            :label-types="labelTypes"
          />
        </div>
        <div class="row total-delay">
          <div>
            <v-icon icon="fas fa-hourglass-half" />
            {{ `${$gettext('Total delay index X')} : ` }}
            <strong>{{ `${daysTotal(workflowTemplate, 'daysX')} ${$gettext('days')}` }}</strong>
          </div>
          <div>
            <v-icon icon="fas fa-hourglass-half" />
            {{ `${$gettext('Total delay index 0')} : ` }}
            <strong>{{ `${daysTotal(workflowTemplate, 'days0')} ${$gettext('days')}` }}</strong>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<style lang="scss" scoped>
.workflow-conditions {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 0;
  gap: 1ch;
}
.workflow-header {
  display: flex;
  gap: 1ch;
  background-color: $secondary !important;
  align-items: center;
  color: $white;
}
.v-card-text {
  display: flex;
  flex-direction: column;
  gap: 1ch;
  padding-top: 1ch !important;
}
.steps-section {
  display: flex;
  flex-direction: column;
  background-color: $grey-ultralight;
  padding: 15px 10px;
  border-radius: 5px;
  gap: 10px;
}
.total-delay {
  display: flex;
  color: $secondary;
  flex-direction: row-reverse;
  gap: 1rem;
}
.steps-container {
  display: flex;
  flex-direction: row;
  gap: 40px;
  overflow-y: auto;
  padding-bottom: 10px;
}
.steps-container > .step::after {
  content: '➜';
  position: absolute;
  color: $secondary;
  right: -7px;
  top: 0;
  font-size: 2rem;
  width: 0;
  height: 0;
}
.steps-container > .step:last-child::after {
  display: none;
}
</style>
