<script>
import { useIndexStore } from '@src/store/index.js'
import { currentDocumentVersionAdmissibility, getAllTrades, updateAdmissibility } from './queries'

export default {
  name: 'Admissibility',
  props: {
    lockedMode: {
      type: Boolean,
      default: () => false,
    },
    documentVersionId: {
      type: String,
      default: () => '',
    },
  },
  emits: [
    'nextStep',
  ],
  data () {
    return {
      documentVersion: null,
      admissibilityPmComment: '',
      admissibilityCompanyComment: '',
      isAdmissible: null,
      admissibilityLabel: null,
      allTrades: [],
      trades: [],
      admissibilityDialog: false,
      admissibilityList: [],
      admissibilityMapping: {},
    }
  },
  computed: {
    store () {
      return useIndexStore()
    },
    canIAdvanceToNextStep () {
      return this.documentVersion ? this.documentVersion.permissions.canIAdvanceToNextStep : false
    },
    admissibilityPmCommentLabel () {
      return this.$gettext('Reviewing team comment')
    },
  },
  watch: {
    async lockedMode () {
      await this.setData()
    },
    async documentVersionId () {
      await this.setData()
    },
  },
  async mounted () {
    await this.setData()
  },
  methods: {
    async setData () {
      return this.$graphqlQuery(currentDocumentVersionAdmissibility, {
        id: this.documentVersionId,
      }).then(async response => {
        this.documentVersion = response.documentVersion
        this.admissibilityPmComment = this.documentVersion.admissibilityPmComment
        this.admissibilityCompanyComment = this.documentVersion.admissibilityCompanyComment
        this.isAdmissible = this.documentVersion.isAdmissible
        this.admissibilityLabel = this.documentVersion.admissibilityLabel
        this.admissibilityMapping = {}
        this.documentVersion.labels.admissibilityList.forEach(label => {
          if (label.isActive) {
            this.admissibilityMapping[label.name] = label.isAdmissible
          }
        })
        this.admissibilityList = Object.keys(this.admissibilityMapping).map(label => (
          { value: label, title: label }
        ))
        this.trades = this.documentVersion.tradeContributions.map(tc => tc.trade)
        return this.$graphqlQuery(getAllTrades, {
          projectId: this.store.project.id,
        }).then(response => {
          this.allTrades = response.allTrades
        })
      })
    },
    populateTrades (data) {
      this.trades = data
    },
    admissibilityLabelChange () {
      this.isAdmissible = this.admissibilityMapping[this.admissibilityLabel]
    },
    getArgsForNextStep () {
      return {
        documentVersionId: this.documentVersion.id,
        tradeIds: this.trades.map(trade => trade.id),
        admissibilityCompanyComment: this.admissibilityCompanyComment,
        admissibilityPmComment: this.admissibilityPmComment,
        isAdmissible: this.isAdmissible,
        admissibilityLabel: this.admissibilityLabel,
      }
    },
    async nextStep () {
      return this.$graphqlMutate(updateAdmissibility, this.getArgsForNextStep())
        .then(response => {
          this.$emit('nextStep', response.updateAdmissibility.currentStepTemplate.type)
        }).catch(error => {
          this.store.changeNotification({
            type: 'error',
            text: error,
            autoClose: false,
          })
        })
    },
    getArgsForSave () {
      return {
        documentVersionId: this.documentVersion.id,
        tradeIds: this.trades.map(trade => trade.id),
        admissibilityCompanyComment: this.admissibilityCompanyComment,
        admissibilityPmComment: this.admissibilityPmComment,
      }
    },
    async save () {
      return this.$graphqlMutate(updateAdmissibility, this.getArgsForSave())
        .then(() => {
          this.store.changeNotification(
            {
              type: 'positive',
              text: this.$gettext('Changes saved'),
              autoClose: true,
            },
          )
        }).catch(error => {
          this.store.changeNotification({
            type: 'error',
            text: error,
            autoClose: false,
          })
        })
    },
  },
}
</script>
<template>
  <section
    v-if="documentVersionId"
    class="card admissibility-container"
  >
    <div class="admissibility-inputs">
      <v-textarea
        v-model="admissibilityCompanyComment"
        clear-icon="fas fa-trash"
        :label="$gettext('Submitter comment')"
        :rows="3"
        no-resize=""
        color="secondary"
        :disabled="lockedMode"
      />
      <v-textarea
        v-model="admissibilityPmComment"
        clear-icon="fas fa-trash"
        :label="admissibilityPmCommentLabel"
        :rows="3"
        no-resize=""
        color="secondary"
        :disabled="lockedMode"
      />
      <ChipsAutocomplete
        v-if="allTrades"
        v-model="trades"
        :label="$gettext('Disciplines')"
        :item-value="item => item"
        :item-display="item => item.acronym"
        :items="allTrades"
        :disabled="lockedMode"
        @update:model-value="populateTrades"
      />
    </div>
    <div
      v-if="!lockedMode"
      class="step-btn-container"
    >
      <v-btn
        color="primary"
        prepend-icon="fas fa-save"
        @click="save"
      >
        {{ $gettext('Save') }}
      </v-btn>
      <v-btn
        :color="!canIAdvanceToNextStep ? 'secondary' : 'primary'"
        :disabled="!canIAdvanceToNextStep"
        :class="!canIAdvanceToNextStep ? 'disabled-button' : ''"
        prepend-icon="fas fa-arrow-right"
        @click="admissibilityDialog = true"
      >
        {{ $gettext("Go to next step") }}
      </v-btn>
      <v-dialog
        v-model="admissibilityDialog"
        persistent=""
        width="30%"
      >
        <v-card>
          <v-card-title class="dialog-header">
            {{ $gettext('Submission of admissibility') }}
          </v-card-title>
          <v-card-text>
            <div>{{ $gettext('Which admissibility status would you like to apply to this document?') }}</div>
            <v-select
              v-model="admissibilityLabel"
              :item-props="true"
              :items="admissibilityList"
              :label="$gettext('Admissibility')"
              color="secondary"
              @update:model-value="admissibilityLabelChange"
            />
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              color="lightgrey"
              variant="elevated"
              prepend-icon="fas fa-times"
              @click="admissibilityDialog = false"
            >
              {{ $gettext('Cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              variant="elevated"
              :disabled="isAdmissible == null"
              prepend-icon="fas fa-save"
              @click="nextStep"
            >
              {{ $gettext('Save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.admissibility-container {
  justify-content: space-between;
  overflow-y: auto;
  flex-grow: 1;
  flex-shrink: 1;
}
.admissibility-inputs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.step-btn-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.disabled-button {
  background-color: lightgray !important;
}
.disabled-button > :deep(.v-btn__content) { // stylelint-disable-line selector-class-pattern
  color: $black;
}
.disabled-button > :deep(.v-btn__prepend) { // stylelint-disable-line selector-class-pattern
  color: $secondary;
}
</style>
