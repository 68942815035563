import gql from 'graphql-tag'

export const getAllProjects = gql`
  query allProjects {
    allProjects {
      id
      name
      isPublished
      isFavorite
      permissions {
        canIConfigure
      }
    }
  }
`
export const askProjectPerms = gql`
  query askProjectPerms($id: ID!) {
    project(pk: $id) {
      permissions {
        doIHaveAccess
        canIConfigure
      }
    }
  }
`
export const openProject = gql`
  query openProject($id: ID!) {
    project(pk: $id) {
      docControllers {
        id
      }
      permissions {
        doIHaveAccess
        canIConfigure
      }
    }
  }
`
export const makeProjectFavorite = gql`
  mutation makeProjectFavorite(
    $id: ID!
    $isFavorite: Boolean!
  ) {
    makeProjectFavorite(
      pk: $id
      data: {
        isFavorite: $isFavorite
      }
    ) {
      id
    }
  }
`
export const addProject = gql`
  mutation createProject($name: String!) {
    createProject(data: {name: $name}) {
      id
      name
      isPublished
      permissions {
        canIConfigure
      }
    }
  }
`
export const deleteProject = gql`
  mutation deleteProject($id: ID!) {
    deleteProject(pk: $id) {
      id
    }
  }
`
