import gql from 'graphql-tag'

export const currentDocumentVersionAdmissibility = gql`
  query currentDocumentVersion($id: ID!) {
    documentVersion(pk: $id) {
      id
      admissibilityCompanyComment
      admissibilityPmComment
      admissibilityLabel
      isAdmissible
      labels {
        admissibilityList {
          name
          isActive
          isAdmissible
        }
      }
      tradeContributions {
        trade {
          id
          acronym
          name
        }
      }
      permissions {
        canIAdvanceToNextStep
      }
    }
  }
`
export const currentDocumentVersionPMES = gql`
  query currentDocumentVersion($id: ID!) {
    documentVersion(pk: $id) {
      id
      admissibilityLabel
      pmEvaluationLabel
      permissions {
        canIAdvanceToNextStep
      }
    }
  }
`
export const currentDocumentVersionES = gql`
  query currentDocumentVersion($id: ID!) {
    documentVersion(pk: $id) {
      id
      labels {
        summaryStateList {
          name
          isActive
        }
      }
      permissions {
        canIAdvanceToNextStep
      }
    }
  }
`
export const currentDocumentVersionESS = gql`
  query currentDocumentVersion($id: ID!) {
    documentVersion(pk: $id) {
      id
      evaluationSummaryStateLabel
      evaluationSummaryComment
      permissions {
        canIAdvanceToNextStep
      }
    }
  }
`
export const currentDocumentVersionEOW = gql`
  query currentDocumentVersion($id: ID!) {
    documentVersion(pk: $id) {
      admissibilityLabel
      pmEvaluationLabel
      evaluationSummaryStateLabel
      stepTransitions {
        datetime
        by {
          fullName
        }
        currentStepTemplate {
          type
          label
        }
      }
    }
  }
`
export const getAllTrades = gql`
  query allTrades($projectId: ID!) {
   allTrades(filters: {project: {id: {exact: $projectId}}}) {
      id
      acronym
      name
    }
  }
`
export const updateAdmissibility = gql`
  mutation updateAdmissibility(
    $documentVersionId: ID!
    $tradeIds: [ID!]!
    $admissibilityCompanyComment: String
    $admissibilityPmComment: String
    $isAdmissible: Boolean
    $admissibilityLabel: String
  ) {
    updateAdmissibility(
      pk: $documentVersionId
      data: {
        tradeIds: $tradeIds
        admissibilityCompanyComment: $admissibilityCompanyComment
        admissibilityPmComment: $admissibilityPmComment
        isAdmissible: $isAdmissible
        admissibilityLabel: $admissibilityLabel
      }
    ) {
      currentStepTemplate {
        type
      }
    }
  }
`
export const finishEvaluationSummarySubmission = gql`
  mutation finishEvaluationSummarySubmission(
    $documentVersionId: ID!
  ) {
    finishEvaluationSummarySubmission(pk: $documentVersionId) {
      currentStepTemplate {
        type
      }
    }
  }
`
export const finishPmEvaluationSummary = gql`
  mutation finishPmEvaluationSummary(
    $documentVersionId: ID!,
    $pmEvaluationSummaryLabel: String!
    $pmEvaluationSummaryComment: String,
  ) {
    finishPmEvaluationSummary(
      pk: $documentVersionId,
      data: {
        pmEvaluationSummaryComment: $pmEvaluationSummaryComment,
        pmEvaluationSummaryLabel: $pmEvaluationSummaryLabel,
      }
    ) {
      currentStepTemplate {
        type
      }
    }
  }
`
export const finishPmEvaluationSubmission = gql`
  mutation finishPmEvaluationSubmission(
    $documentVersionId: ID!
  ) {
    finishPmEvaluationSubmission(pk: $documentVersionId) {
      currentStepTemplate {
        type
      }
    }
  }
`
