<script>
import { backUrl } from '@src/config'
import { useIndexStore } from '@src/store/index.js'

export default {
  name: 'ImageEditor',
  props: {
    image: {
      type: Object,
      default: () => ({
        url: '',
        file: null,
      }),
    },
  },
  emits: [
    'change',
  ],
  data () {
    return {
      imageUrl: '',
      imageFile: null,
    }
  },
  computed: {
    dataImage () {
      const url = this.imageUrl
      if (url) {
        const hash = Date.now()
        if (url.startsWith('data:') && url.includes(';base64,')) {
          return url
        } else if (url.startsWith('http')) {
          return `${url}?hash=${hash}`
        } else if (url[0] === '/') {
          return `${backUrl}${url.substr(1)}?hash=${hash}`
        } else {
          return `${backUrl}${url}?hash=${hash}`
        }
      } else {
        return ''
      }
    },
    store () {
      return useIndexStore()
    },
  },
  watch: {
    image: {
      async handler (image) {
        this.imageUrl = image.url
        this.imageFile = image.file
        await this.initImageFile()
        if (this.imageFile !== image.file) {
          this.$emit('change', {
            url: this.imageUrl,
            file: this.imageFile,
          })
        }
      },
      immediate: true,
    },
  },
  async mounted () {
    document.onpaste = event => this.onPasteEvent(event)
  },
  methods: {
    async getImageFile (imageUrl) {
      if (imageUrl) {
        if (imageUrl.startsWith('data:') && imageUrl.includes(';base64,')) {
          return new File([], imageUrl)
        } else {
          const response = await fetch(imageUrl, { cache: 'no-cache' })
          const blob = await response.blob()
          return new File([blob], imageUrl, {
            lastModified: Date.now(),
            type: blob.type,
          })
        }
      } else {
        return null
      }
    },
    async initImageFile () {
      if (this.imageUrl && !this.imageFile) {
        this.imageFile = await this.getImageFile(this.imageUrl)
      }
    },
    readImage (imageFile) {
      return new Promise((resolve, reject) => {
        const imageReader = new FileReader()
        imageReader.onload = event => {
          resolve(event.target.result)
        }
        imageReader.onerror = () => {
          reject(imageReader.error)
        }
        imageReader.readAsDataURL(imageFile)
      })
    },
    isImageExtension(filename) {
      const nameElements = filename.split('.')
      const ext = nameElements[nameElements.length - 1]
      switch (ext.toLowerCase()) {
        case 'jpg':
        case 'gif':
        case 'bmp':
        case 'png':
        case 'jpeg':
          return true
      }
      return false
    },
    isImageTypeOk (file) {
      if (!file) {
        this.store.changeNotification({
          type: 'error',
          text: this.$gettext('Impossible to read empty content.'),
          autoClose: false,
        })
        return false
      }
      const filename = Array.isArray(file) ? file[0].name : file.name
      if (file && this.isImageExtension(filename)) {
        return true
      } else {
        this.store.changeNotification({
          type: 'error',
          text: this.$gettext('Impossible to read this type of content.'),
          autoClose: false,
        })
        return false
      }
    },
    onFileDraggedEnter (event) {
      const target = event.target
      target.classList.add('droppable')
    },
    onFileDraggedLeave (event) {
      const target = event.target
      target.classList.remove('droppable')
    },
    getTransferFile (dataTransferItem) {
      if (
        dataTransferItem
        && dataTransferItem.kind === 'file'
      ) {
        return dataTransferItem.getAsFile()
      } else {
        return null
      }
    },
    cleanImageFileData (imageFile) {
      this.imageFile = imageFile
    },
    async onFileDropped (event) {
      this.onFileDraggedLeave(event)
      const dataTransfer = event.dataTransfer
      const dataTransferFile = this.getTransferFile(dataTransfer.items[0])
      this.cleanImageFileData(dataTransferFile)
      if (this.isImageTypeOk(dataTransferFile)) {
        await this.updateImage(dataTransferFile)
      }
    },
    async onPasteEvent (event) {
      const dataTransfer = event.clipboardData || event.originalEvent.clipboardData
      const dataTransferFile = this.getTransferFile(dataTransfer.items[0])
      if (this.isImageTypeOk(dataTransferFile)) {
        await this.updateImage(dataTransferFile)
      }
    },
    async onClearImage () {
      return await this.updateImage(null)
    },
    async onUploadChange (ev) {
      if (ev.target.files && ev.target.files.length) {
        const imageFile = ev.target.files[0]
        if (this.isImageTypeOk(imageFile)) {
          return await this.updateImage(imageFile)
        }
      }
      return await this.updateImage(null)
    },
    async updateImage (imageFile) {
      if (imageFile) {
        this.imageUrl = await this.readImage(imageFile)
        this.imageFile = imageFile
      } else {
        this.imageUrl = ''
        this.imageFile = null
      }
      this.$emit('change', {
        url: this.imageUrl,
        file: this.imageFile,
      })
    },
  },
}
</script>
<template>
  <div>
    <v-file-input
      v-model="imageFile"
      :label="$gettext('Import image')"
      accept="image/*"
      variant="underlined"
      prepend-icon=""
      prepend-inner-icon="fas fa-paperclip"
      color="secondary"
      @click:clear="onClearImage"
      @change="onUploadChange"
    />
    <div
      v-cloak
      class="image-preview"
      @dragover.prevent
      @dragenter.prevent="onFileDraggedEnter"
      @dragleave.prevent="onFileDraggedLeave"
      @drop.prevent="onFileDropped"
    >
      <img
        v-if="dataImage"
        :src="dataImage"
      >
      <v-icon
        v-else
        size="x-large"
        icon="fas fa-image"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.image-input {
  display: flex;
  width: calc(100% - 5px);
}
.image-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(0 0 0 / 10%);
  flex-grow: 1;
  min-height: 150px;
}
.image-preview > img {
  max-width: 100%;
  max-height: 100%;
}
.droppable {
  background: rgb(30 100 30 / 50%);
  padding: 10px;
  border: 2px dashed green;
}
</style>
