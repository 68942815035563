<script>
import CommentEditable from '@comp/document/comments/CommentEditable.vue'

export default {
  name: 'CommentReadOnly',
  components: {
    CommentEditable,
  },
  props: {
    comment: {
      type: Object,
      default: () => {},
    },
    isOmanType: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'showImage',
  ],
  methods: {
    showImage (...args) {
      this.$emit('showImage', ...args)
    },
  },
}
</script>
<template>
  <CommentEditable
    :comment="comment"
    :is-first-comment="false"
    :is-editable="false"
    :is-oman-type="isOmanType"
    @show-image="showImage"
  />
</template>
