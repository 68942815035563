import { threadPmEvaluationVDocIdSeqFragment } from '@comp/document/threads/queries'
import gql from 'graphql-tag'

export const getDocumentVersion = gql`
  query getDocumentVersion($documentVersionId: ID!) {
    documentVersion(pk: $documentVersionId) {
      id
      sequence
      document {
        id
      }
      labels {
        pmEvaluationList {
          name
          isActive
        }
      }
      permissions {
        canIAdvanceToNextStep
      }
    }
  }
`
export const getDocumentThreads = gql`
  ${threadPmEvaluationVDocIdSeqFragment}
  query getDocumentThreads(
    $documentId: ID!
    $documentVersionId: ID! # for fragment
    $documentVersionSequence: Int! # for fragment
  ) {
    document(pk: $documentId) {
      id
      threads(filters: {NOT: {importance: {exact: "INTERNAL"}}}) {
        ...threadPmEvaluationVDocIdSeqFragment
      }
    }
  }
`
