<script>
import { parseMail } from '@src/utils/parseMail'
import MsgReader from 'msgreader'
import moment from 'moment'
import { stringCannotBeEmptyRule } from '@src/utils/userInputValidation'
import { createDocumentVersion } from './queries'
import { useIndexStore } from '@src/store/index.js'
import InfoTooltip from '@comp/utils/InfoTooltip.vue'

export default {
  name: 'NewDocPopup',
  components: {
    InfoTooltip,
  },
  props: {
    metadata: {
      type: Array,
      required: true,
    },
    contracts: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'closeAddDialog',
  ],
  data () {
    return {
      mail: null,
      timePickerKey: 0,
      file: null,
      saveButtonClicked: false,
      metadataValues: {},
      contract: '',
      originGedUrl: '',
      parsedData: {},
      fileReader: null,
      loading: false,
      selectedDate: '',
      selectedTime: '',
      requiredRules: [
        v => !!v || this.$gettext('Required field'),
      ],
      dragging: false,
      showDialog: true,
    }
  },
  computed: {
    noFileSelected () {
      return this.file === null && this.saveButtonClicked
    },
    store () {
      return useIndexStore()
    },
    disableFutureDates () {
      return [
        v => !!v || this.$gettext('Date is required'),
        v => {
          const maxDate = new Date().toISOString().substr(0, 10)
          const selectedDate = new Date(v).toISOString().substr(0, 10)
          return selectedDate <= maxDate || this.$gettext('Date cannot be in the future')
        },
      ]
    },
  },
  watch: {
    metadata () {
      this.setData()
    },
    mail () {
      if (this.mail) {
        this.parseMail(this.mail)
      }
    },
    parsedData () {
      for (const datum in this.parsedData) {
        if (Object.keys(this.metadataValues).includes(datum)) {
          this.metadataValues[datum] = this.parsedData[datum]
        }
      }
      if (this.parsedData.hasOwnProperty('Contract') && !this.contract) {
        this.contract = this.contracts.find(o => o.code === this.parsedData['Contract'])
      }
    },
  },
  mounted () {
    this.setData()
    this.fileReader = new FileReader()
    this.fileReader.onload = (evt) => {
      const buffer = evt.target.result
      const msgReader = new MsgReader(buffer)
      const fileData = msgReader.getFileData()
      if (!fileData.error) {
        parseMail(fileData).then(response => {
          this.originGedUrl = response.originGedUrl
          this.parsedData = response.metadata
          this.selectedDate = response.workflowStartDatetime
          this.mail = null
        }).catch(error => {
          this.store.changeNotification({
            type: 'error',
            text: this.$gettext(error),
            autoClose: false,
          })
          this.mail = null
        })
      } else {
        this.store.changeNotification({
          type: 'error',
          text: fileData.error,
          autoClose: false,
        })
        this.mail = null
      }
    }
    this.sortContracts()
  },
  methods: {
    sortContracts () {
      this.contracts.sort((a, b) => {
        const fa = a.code.toLowerCase()
        const fb = b.code.toLowerCase()
        if (fa < fb) {
          return -1
        }
        if (fa > fb) {
          return 1
        }
        return 0
      })
    },
    informativeMessage (metadatumName) {
      const formattingRule = this.metadata.find(o => o.name === metadatumName).stringFormat
      return formattingRule ? `${this.$gettext('This field has a formatting rule:')} ${formattingRule}` : null
    },
    displayTooltip (metadatumName) {
      const formattingRule = this.metadata.find(o => o.name === metadatumName).stringFormat
      const value = this.metadataValues[metadatumName]
      return (formattingRule && this.validateAll(metadatumName) !== true && (stringCannotBeEmptyRule(value)))
    },
    forceRerender () {
      this.timePickerKey += 1
    },
    validateAll (metadatumName) {
      const value = this.metadataValues[metadatumName]
      const formattingRule = this.metadata.find(o => o.name === metadatumName).stringFormat
      if (!stringCannotBeEmptyRule(value)) {
        return this.$gettext('Required field')
      } else if (!this.constructRegex(metadatumName).test(value)) {
        return `${this.$gettext('This field has a formatting rule:')} ${formattingRule}`
      }
      return true
    },
    addInputFile (e) {
      this.file = e.target.files[0]
    },
    setData () {
      if (this.$refs.form) {
        this.$refs.form.reset()
        this.originGedUrl = ''
        const DATE_FORMAT = 'YYYY-MM-DD'
        this.selectedDate = `${moment(new Date()).format(DATE_FORMAT)}`
        const TIME_FORMAT = 'HH:mm'
        this.selectedTime = `${moment(new Date()).format(TIME_FORMAT)}:00`
      }
      this.parsedData = {}
      for (const metadatum of this.metadata) {
        if (metadatum.isChoices && metadatum.choices.length === 1) {
          this.metadataValues[metadatum.name] = metadatum.choices[0]
        } else {
          this.metadataValues[metadatum.name] = null
        }
      }
      if (this.contracts.length === 1) {
        this.contract = this.contracts[0]
      }
    },
    removeFile () {
      this.file = null
    },
    addDropFile (e, key) {
      this[key] = e.dataTransfer.files[0]
    },
    constructRegex (metadatumName) {
      const metadatum = this.metadata.filter(o => o.name === metadatumName)[0]
      let input
      let regex = ''
      if (metadatum.isChoices) {
        input = metadatum.choices
        regex += '('
        for (const choice of input) {
          const protectedChoice = choice.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d')
          regex += '|' + protectedChoice
        }
        regex += ')'
      } else {
        input = metadatum.stringFormat
        const toRegex = {
          'A': '[A-Za-z]',
          '0': '[0-9]',
          '*': '[A-ZaA-z0-9]',
          '[': '(',
          ']': ')?',
        }
        for (const char of input) {
          regex += toRegex[char]
        }
        if (!regex.length) {
          regex = '.*'
        }
      }
      return new RegExp('^' + regex + '$')
    },
    close () {
      this.$emit('closeAddDialog')
      this.removeFile()
      this.saveButtonClicked = false
      this.setData()
      this.forceRerender()
    },
    parseMail (file) {
      if (file && this.fileReader.readyState !== 1) {
        this.fileReader.readAsArrayBuffer(file)
      }
    },
    formatWorkflowDateTime () {
      const datetime = `${this.selectedDate} ${this.selectedTime}`
      return moment(datetime, 'YYYY-MM-DD HH:mm').format()
    },
    addDoc () {
      if (!this.$refs.form.validate() || this.file === null) {
        this.saveButtonClicked = true
        this.store.changeNotification({
          type: 'warning',
          text: this.$gettext('Please select a file and fill in all the data before submitting the document'),
          autoClose: false,
        })
      } else {
        this.loading = true
        const tmpMetadata = []
        for (const metadatum of Object.keys(this.metadataValues)) {
          tmpMetadata.push({
            name: metadatum,
            value: this.metadataValues[metadatum],
          })
        }
        return this.$graphqlQuery(createDocumentVersion, {
          contractId: this.contract.id,
          metadataValues: tmpMetadata,
          file: this.file,
          workflowStartDatetime: this.formatWorkflowDateTime(),
          originGedUrl: this.originGedUrl,
        }).then(_ => {
          this.store.changeNotification({
            type: 'positive',
            text: this.$gettext('Document saved'),
            autoClose: true,
          })
          this.removeFile()
          this.close()
          this.loading = false
        }).catch(error => {
          this.store.changeNotification({
            type: 'error',
            text: error,
            autoClose: false,
          })
          this.$emit('closeAddDialog')
          this.loading = false
        })
      }
    },
  },
}
</script>
<template>
  <v-dialog
    v-model="showDialog"
    persistent
    scrollable
    width="40%"
  >
    <v-card>
      <v-card-title class="dialog-header">
        {{ $gettext('Add a new document') }}
      </v-card-title>
      <v-card-text v-show="!loading">
        <v-form ref="form">
          <!-- Drop document zone -->
          <div>
            {{ $gettext('Document') }}
            <InfoTooltip>
              {{ $gettext('Only files in PDF format will be viewable') }}
            </InfoTooltip>
          </div>
          <div
            v-cloak
            @drop.prevent="addDropFile($event, 'file')"
            @dragover.prevent
          >
            <span
              v-if="!file"
              class="dropzone"
              :class="{ 'not-uploaded-file': noFileSelected }"
            >
              <v-icon
                color="mediumgrey"
                icon="fas fa-file-arrow-down"
              />
              {{ $gettext('Drag and drop the document here') }}
              <input
                ref="fileToAdd"
                type="file"
                hidden
                @change="addInputFile"
              >
              <v-btn
                size="small"
                color="primary"
                @click="$refs.fileToAdd.click()"
              >
                {{ $gettext('Browse file') }}
              </v-btn>
            </span>
            <span
              v-else
              class="row filename-display"
            >
              <v-icon
                icon="fas fa-file-pdf"
                color="secondary"
              />
              {{ file.name }}
              <v-icon
                color="secondary"
                size="x-small"
                icon="fas fa-times-circle"
                @click="removeFile"
              />
            </span>
            <span v-if="noFileSelected">
              {{ $gettext('Required field') }}
            </span>
          </div>
          <!-- TEXT FIELDS -->
          <v-text-field
            v-model="originGedUrl"
            color="secondary"
            :label="$gettext('Original DMS link (optional)')"
          />
          <div class="row">
            <v-text-field
              v-model="selectedDate"
              :label="$gettext('Date received')"
              :rules="disableFutureDates"
              type="date"
            />
            <v-text-field
              v-model="selectedTime"
              :label="$gettext('Time received')"
              type="time"
            />
          </div>
          <v-select
            v-model="contract"
            :items="contracts"
            :label="$gettext('Contract')"
            color="secondary"
            return-object=""
            item-title="code"
            :rules="requiredRules"
            required
          />
          <template
            v-for="metadatumName in Object.keys(metadataValues)"
            :key="metadatumName"
          >
            <v-select
              v-if="metadata.find(o => o.name === metadatumName).isChoices"
              v-model="metadataValues[metadatumName]"
              :items="metadata.find(o => o.name === metadatumName).choices"
              :label="metadatumName"
              color="secondary"
              :rules="requiredRules"
              required
            />
            <v-text-field
              v-else
              v-model="metadataValues[metadatumName]"
              color="secondary"
              :label="metadatumName"
              :rules="[validateAll(metadatumName)]"
              :hint="informativeMessage(metadatumName)"
              required
            >
              <template v-slot:append>
                <InfoTooltip v-if="displayTooltip(metadatumName)">
                  <ul>
                    <li>{{ $gettext('"*" allows using any character') }}</li>
                    <li>{{ $gettext('"A" allows using letters only') }}</li>
                    <li>{{ $gettext('"0" allows using numbers only') }}</li>
                    <li>{{ $gettext('Optional elements are surrounded by \"[]\"') }}</li>
                  </ul>
                </InfoTooltip>
              </template>
            </v-text-field>
          </template>
        </v-form>
      </v-card-text>
      <v-card-text v-show="loading">
        <div class="row circular-loading">
          <v-progress-circular
            :width="10"
            :size="100"
            color="primary"
            indeterminate
          />
        </div>
      </v-card-text>
      <v-card-actions
        v-if="!loading"
        class="justify-end"
      >
        <v-btn
          variant="elevated"
          color="secondary"
          prepend-icon="fas fa-times"
          @click="close"
        >
          {{ $gettext('Cancel') }}
        </v-btn>
        <v-btn
          variant="elevated"
          color="primary"
          prepend-icon="fas fa-save"
          @click="addDoc"
        >
          {{ $gettext('Save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
.dropzone {
  border: 4px dashed $grey-light;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  margin-bottom: 1rem;
}
.dropzone:hover {
  border-color: $primary;
}
.not-uploaded-file {
  border-color: $error-red;
}
.filename-display {
  gap: 1ch;
  background: $primary;
  color: $secondary;
  font-weight: 500;
  border-radius: 5px;
  padding: 14px;
  min-height: 10px;
  align-items: center;
  margin-bottom: 22px;
}
.circular-loading {
  justify-content: center;
  margin: 20px 0;
}
:deep(.v-input--horizontal .v-input__append) { // stylelint-disable-line selector-class-pattern
  margin-inline-start: unset;
}
</style>
