<script>
export default {
  name: 'AddMetadatumButton',
  props: {
    initMetadata: {
      type: Array,
      required: true,
    },
    metadataList: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'addMetadatum',
  ],
  computed: {
    metadataFilteredList () {
      return this.initMetadata.filter(o => !this.metadataList.includes(o) && !o._deleted)
    },
  },
  methods: {
    addMetadatum (metadatum) {
      this.$emit('addMetadatum', metadatum)
    },
  },
}
</script>
<template>
  <v-menu
    transition="scroll-y-transition"
  >
    <template v-slot:activator="{ props }">
      <v-btn
        color="primary"
        prepend-icon="fas fa-plus"
        rounded
        v-bind="props"
      >
        {{ $gettext('Add a metadatum') }}
      </v-btn>
    </template>
    <v-list density="compact">
      <v-list-item
        v-for="metadatum in metadataFilteredList"
        :key="metadatum.id"
        @click="addMetadatum(metadatum)"
      >
        <v-list-item-title>{{ metadatum.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
