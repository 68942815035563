<script>
export default {
  name: 'FilterList',
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    attr: {
      type: String,
      required: true,
    },
    itemWidth: {
      type: String,
      default: () => '100%',
    },
    filterUpdateDataError: {
      type: Boolean,
      required: true,
    },
    filterIsUpdating: {
      type: Boolean,
      required: true,
    },
    fixedWidth: {
      type: Boolean,
      default: false,
    },
    withWrap: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'reset',
    'change',
  ],
  computed: {
    filterItemsClass () {
      let cls = 'filter-items '
      this.withWrap ? cls += 'with-wrap' : ''
      return cls.trim()
    },
  },
  methods: {
    reset (key) {
      this.$emit('reset', key)
    },
    handleChangeData () {
      this.$emit('change')
    },
  },
}
</script>
<template>
  <div
    class="filter"
    :class="fixedWidth ? 'fixed-width': ''"
  >
    <div class="filter-header">
      <div class="filter-title">
        <v-icon :icon="icon" />
        {{ title }}
      </div>
      <v-tooltip
        location="start"
        color="secondary"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            icon="fas fa-eraser"
            size="small"
            color="white"
            v-bind="props"
            variant="plain"
            @click="reset(type)"
          />
        </template>
        {{ $gettext('Clear filters') }}
      </v-tooltip>
    </div>
    <div
      :class="filterItemsClass"
    >
      <div
        v-for="item in items"
        :key="item.id"
        :class="withWrap ? 'filter-item' : ''"
      >
        <v-checkbox
          v-model="item.value"
          :label="$gettext(item[attr])"
          :title="$gettext(item[attr])"
          hide-details
          density="compact"
          :error="filterUpdateDataError"
          :disabled="filterIsUpdating"
          @update:model-value="handleChangeData"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.filter-item {
  flex: 0 0 33%;
}
.with-wrap {
  display: flex;
  flex-wrap: wrap;
}
.filter-items :deep(.v-icon) {
  color: $primary;
  opacity: 1;
}
.filter-items :deep(label) {
  color: $white;
  font-size: 0.9em;
  opacity: 1;
}
</style>
