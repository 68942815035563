<script>
import { currentDocumentVersionES, finishPmEvaluationSummary } from './queries'
import { useIndexStore } from '@src/store/index.js'

export default {
  name: 'EvaluationSummary',
  props: {
    lockedMode: {
      type: Boolean,
      default: () => false,
    },
    documentVersionId: {
      type: String,
      default: () => '',
    },
  },
  emits: [
    'nextStep',
  ],
  data () {
    return {
      documentVersion: null,
      evaluationLabel: null,
      comment: null,
      pmEvaluationSummaryList: [],
      evaluationSummaryDialog: false,
    }
  },
  computed: {
    store () {
      return useIndexStore()
    },
    canIAdvanceToNextStep () {
      return this.documentVersion ? this.documentVersion.permissions.canIAdvanceToNextStep : false
    },
  },
  watch: {
    async lockedMode () {
      await this.setData()
    },
    async documentVersionId () {
      await this.setData()
    },
  },
  async mounted () {
    await this.setData()
  },
  methods: {
    async setData () {
      return this.$graphqlQuery(currentDocumentVersionES, {
        id: this.documentVersionId,
      }).then(response => {
        this.documentVersion = response.documentVersion
        const pmEvaluationSummaryList = []
        this.documentVersion.labels.summaryStateList.forEach(label => {
          if (label.isActive) {
            pmEvaluationSummaryList.push({
              value: label.name,
              text: label.name,
            })
          }
        })
        this.pmEvaluationSummaryList = pmEvaluationSummaryList
      })
    },
    async nextStep () {
      return this.$graphqlMutate(finishPmEvaluationSummary, {
        documentVersionId: this.documentVersionId,
        pmEvaluationSummaryComment: this.comment,
        pmEvaluationSummaryLabel: this.evaluationLabel,
      }).then(response => {
        this.$emit('nextStep', response.finishPmEvaluationSummary.currentStepTemplate.type)
      }).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
      })
    },
  },
}
</script>
<template>
  <section class="card">
    <v-textarea
      v-model="comment"
      :label="$gettext('Comment')"
      :rows="5"
      :disabled="lockedMode"
      variant="underlined"
      no-resize
    />
    <div
      v-if="!lockedMode"
      class="step-btn-container"
    >
      <v-btn
        :color="!canIAdvanceToNextStep ? 'secondary' : 'primary'"
        :disabled="!canIAdvanceToNextStep"
        :class="!canIAdvanceToNextStep ? 'disabled-button' : ''"
        prepend-icon="fas fa-arrow-right"
        @click="evaluationSummaryDialog = true"
      >
        {{ $gettext("Go to next step") }}
      </v-btn>
      <v-dialog
        v-model="evaluationSummaryDialog"
        persistent
        width="30%"
      >
        <v-card>
          <v-card-title class="dialog-header">
            {{ $gettext('Submission of Project Manager evaluation') }}
          </v-card-title>
          <v-card-text>
            <div>{{ $gettext('Which admissibility status would you like to apply to this document?') }}</div>
            <v-select
              v-model="evaluationLabel"
              :items="pmEvaluationSummaryList"
              item-title="text"
              :label="$gettext('Evaluation Summary')"
              color="secondary"
            />
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              color="lightgrey"
              variant="elevated"
              prepend-icon="fas fa-times"
              @click="evaluationSummaryDialog = false"
            >
              {{ $gettext('Cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              variant="elevated"
              :disabled="!evaluationLabel"
              prepend-icon="fas fa-save"
              @click="nextStep"
            >
              {{ $gettext('Save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.card {
  flex-grow: 1;
  flex-shrink: 1;
}
.disabled-button {
  background-color: $grey-light !important;
}
.disabled-button > :deep(.v-btn__content) { // stylelint-disable-line selector-class-pattern
  color: $black;
}
.disabled-button > :deep(.v-btn__prepend) { // stylelint-disable-line selector-class-pattern
  color: $secondary;
}
</style>
