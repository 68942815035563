import moment from 'moment'
const $t = s => s // to force vue-i18n-extract to see translations

// This functionality is not currently used. It was masked in the implementation
// of VIS-876. See this comment: https://qeto.atlassian.net/browse/VIS-876?focusedCommentId=13165
// The issue to definitively remove this functionality is here:
// https://qeto.atlassian.net/browse/VIS-905
export function parseMail (fileData) {
  return new Promise((resolve, reject) => {
    if (fileData.senderEmail && fileData.senderEmail.includes('societedugrandparis.fr')) {
      parseMailSGP(fileData).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    } else {
      parseMailDefault(fileData).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    }
  })
}

function parseMailSGP (fileData) {
  return new Promise((resolve, reject) => {
    const body = fileData.body.replace(/\r\n/g, '*').split('*')
    // Get table data on line 5, delete non-url strings, then remove unused url parameter
    let originGedUrl = body[5].split('<').pop().split('>')[0].split('&')
    originGedUrl.splice(2, 1)
    originGedUrl = originGedUrl.join('&')
    // Get table headers on line 4
    let headers = body[4]
    headers = headers.replace(/\t/g, '*').split('*')
    // Get table data on line 5, then match data with headers
    let data = body[5]
    data = data.replace(/\t/g, '*').split('*')
    if (data.length !== headers.length) {
      reject(new Error($t('Format de mail non valide')))
    }
    // Add "Avis complémentaire" to table
    let additionalEvaluation = body.find(o => o.includes('Avis complémentaire'))
    if (additionalEvaluation) {
      additionalEvaluation = additionalEvaluation.split('Avis complémentaire : ')[1]
      headers.unshift('Avis complémentaire')
      data.unshift(additionalEvaluation)
    }

    // Create object with all parsed data, transformed by SGP matrix
    const metadata = {}
    for (const index in headers) {
      if (headers[index].length > 0) {
        if (Object.keys(SGPMetadataMatrix).includes(headers[index])) {
          for (const key of SGPMetadataMatrix[headers[index]]) {
            metadata[key] = transformParsedValueSGP(headers[index], key, data[index])
          }
        } else {
          metadata[headers[index]] = data[index]
        }
      }
    }

    let workflowStartDatetime
    let parsedDate = body.find(o => o.includes('Date de mise à disposition'))
    if (parsedDate) {
      parsedDate = parsedDate.split('Date de mise à disposition : ')[1].replace('H', ':')
      workflowStartDatetime = moment(parsedDate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')
    }
    resolve({
      originGedUrl,
      workflowStartDatetime,
      metadata,
    })
  })
}

const SGPMetadataMatrix = {
  'Emetteur': ['Marché', 'Emetteur', 'Lot', 'Lot (marché)'],
  'Id RTGP': ['Objet Technique', 'Objet', 'Ouvrage'],
  'Type de Document': ['Type de doc', 'Type Doc'],
  'Spécialité': ['Specialite', 'Spécialité'],
  'Numéro du document': ['Chrono', 'Chrono GED', 'Numéro GED'],
  'Version du document': ['Indice GED', 'indice GED', 'Version GED'],
  'Code Externe': ['Indice Interne', 'Indice_interne', 'Indice interne', 'indice interne'],
  'Avis complémentaire': ['Workflow GED', 'WFL GED'],
}

function transformParsedValueSGP (key, nKey, value) {
  switch (key) {
    case 'Emetteur':
      if (nKey === 'Lot' || nKey === 'Lot (marché)') {
        return SGPTransmitterMatrix[value]
      } else {
        return value
      }
    case 'Id RTGP':
      if (value === 'T2') {
        return '00000'
      } else {
        return value
      }
    case 'Code Externe':
      if (value === '') {
        return 'A'
      } else {
        return value
      }
    default:
      return value
  }
}

const SGPTransmitterMatrix = {
  PN1745: 'PN1745',
  PN1801: 'OA Lot Est',
  PN1403: 'OA Lot Centre',
  PN1954: 'ARD',
  PN1955: 'VIC',
  PN2028L1: 'VDM CEA',
  PN2028L2: 'VDM CET',
  PN1919: 'NCH',
  PN2009L1: 'CLE CEA',
  PN2009L2: 'CLE CET',
  PN2009L3: 'CLE EME',
  PN2008L1: 'BVC CEA',
  PN2008L2: 'BVC CET',
  PN2056: 'SMC',
}

function parseMailDefault (fileData) {
  return new Promise((resolve, reject) => {
    const body = fileData.body.replace(/\r\n/g, '*').split('*')
    const headers = body[3].replace(/\t/g, '*').split('*')
    const data = body[4].replace(/\t/g, '*').split('*')
    if (data.length !== headers.length) {
      reject(new Error($t('Format de mail non valide')))
    }
    const metadata = {}
    for (const index in headers) {
      if (headers[index].length > 0) {
        metadata[headers[index]] = data[index]
      }
    }
    const originGedUrl = metadata['Lien GED']
    const parsedDate = metadata['Date de mise à disposition']
    const workflowStartDatetime = moment(parsedDate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')
    resolve({
      originGedUrl,
      workflowStartDatetime,
      metadata,
    })
  })
}
