<script>
export default {
  name: 'ContributionsMatrix',
  props: {
    tradeContributionMatrix: {
      type: Array,
      required: true,
    },
    metadataList: {
      type: Array,
      required: true,
    },
    trades: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'changeContributionMetadataValue',
    'deleteContribution',
    'order',
    'removeMetadatum',
    'update:tradeContributionMatrix',
  ],
  computed: {
    tableHeaders () {
      const headers = []
      for (const metadatum of this.metadataList) {
        headers.push({
          title: metadatum.name,
          key: metadatum.name,
          class: 'data-table-light-header',
          sortable: false,
        })
      }
      for (const trade of this.trades) {
        headers.push({
          title: trade.acronym,
          key: trade.acronym,
          sortable: false,
          tradeId: trade.id,
        })
      }
      headers.push({
        title: this.$gettext('Actions'),
        key: 'actions',
        sortable: false,
      })
      return headers
    },
  },
  watch: {
    tradeContributionMatrix: {
      handler(newVal) {
        this.$emit('update:tradeContributionMatrix', newVal)
      },
      deep: true,
    },
  },
  methods: {
    order (key, contribution) {
      this.$emit('order', key, contribution)
    },
    removeMetadatum (metadatum) {
      this.$emit('removeMetadatum', metadatum)
    },
    changeContributionMetadataValue (event, contribution, metadatum) {
      const value = event.target.value
      this.$emit('changeContributionMetadataValue', value, contribution, metadatum)
    },
    deleteContribution (contribution) {
      this.$emit('deleteContribution', contribution)
    },
    canMoveItemUp (contribution) {
      return contribution.sequence != 0
    },
    canMoveItemDown (contribution) {
      return contribution.sequence != this.tradeContributionMatrix.length - 1
    },
    getHeaderClasses (column) {
      if (column.tradeId) {
        return 'centered-cell'
      }
      if (column.class) {
        return column.class
      }
      if (column.key === 'actions') {
        return 'actions-header'
      }
      return ''
    },
  },
}
</script>
<template>
  <v-data-table-virtual
    :headers="tableHeaders"
    :items="tradeContributionMatrix"
    class="card contributions-matrix-table"
    fixed-header
  >
    <template v-slot:headers="{ columns }">
      <tr>
        <template
          v-for="column in columns"
          :key="column.key"
        >
          <th :class="getHeaderClasses(column)">
            <div :class="column.class ? 'row' : ''">
              {{ column.title }}
              <v-btn
                v-if="column.class === 'data-table-light-header'"
                size="x-small"
                color="secondary"
                icon="fas fa-trash"
                variant="plain"
                @click="removeMetadatum(column)"
              />
            </div>
          </th>
        </template>
      </tr>
    </template>
    <template v-slot:item="{ item: contribution }">
      <tr>
        <td
          v-for="metadatum in metadataList"
          :key="metadatum.id"
        >
          <v-text-field
            :model-value="contribution.tableData[metadatum.name]"
            :placeholder="$gettext('All values')"
            color="secondary"
            variant="underlined"
            hide-details
            @change="changeContributionMetadataValue($event, contribution, metadatum)"
          />
        </td>
        <td
          v-for="trade in trades"
          :key="trade.id"
        >
          <v-checkbox
            v-model="contribution.trades"
            :value="trade.acronym"
            color="secondary"
            hide-details
          />
        </td>
        <td>
          <div class="row control-buttons">
            <v-btn
              size="small"
              variant="text"
              color="secondary"
              icon="fas fa-arrow-up"
              :disabled="!canMoveItemUp(contribution)"
              @click="order(1, contribution)"
            />
            <v-btn
              size="small"
              variant="text"
              color="secondary"
              icon="fas fa-arrow-down"
              :disabled="!canMoveItemDown(contribution)"
              @click="order(-1, contribution)"
            />
            <v-btn
              size="small"
              variant="text"
              color="secondary"
              icon="fas fa-trash"
              @click="deleteContribution(contribution)"
            />
          </div>
        </td>
      </tr>
    </template>
  </v-data-table-virtual>
</template>
<style lang="scss" scoped>
.contributions-matrix-table {
  padding: 0;
  border-radius: 5px !important;
  overflow: auto !important;
}
.data-table-light-header {
  background-color: $primary !important;
  color: $secondary !important;
  min-width: 250px;
}

// Some of these classes had to be added because Vuetify doesn't seem to correctly apply headers props for now (3.5.8)
.centered-cell {
  text-align: center !important;
  min-width: 70px;
}
.actions-header {
  width: 120px;
}
.row {
  align-items: baseline;
  justify-content: space-between;
}
.control-buttons {
  max-width: 120px;
  gap: 0;
}
:deep(.v-checkbox .v-selection-control) {
  justify-content: center;
}
</style>
