import gql from 'graphql-tag'

export const getDataStep1 = gql`
  query currentProject($id: ID!) {
    project(pk: $id) {
      name
      id
      hasDocuments
      driveUrl
      documentSubdirectory
      approvalSheetSubdirectory
      complementarySubdirectories
      contracts {
        id
        name
        code
        hasDocuments
        companyContracts {
          id
          contacts {
            id
            name
          }
          company {
            id
            name
          }
        }
      }
    }
  }
`
export const updateDriveProject = gql`
  mutation updateProject(
    $id: ID!,
    $name: String!,
    $driveUrl: String!,
    $documentSubdirectory: String!,
    $approvalSheetSubdirectory: String!
    $complementarySubdirectories: [String!]!
  ) {
    updateProject(
      pk: $id
      data: {
        name: $name
        driveUrl: $driveUrl
        documentSubdirectory: $documentSubdirectory
        approvalSheetSubdirectory: $approvalSheetSubdirectory
        complementarySubdirectories: $complementarySubdirectories
      }
    ) {
      id
      name
    }
  }
`
export const deleteContract = gql`
  mutation deleteContract($id: ID!) {
    deleteContract(pk: $id) {
      id
    }
  }
`
export const createContract = gql`
  mutation createContract($contract: ContractCreateInput!) {
    createContract(data: $contract) {
      id
      name
    }
  }
`
