import gql from 'graphql-tag'

export const getDataStep3 = gql`
  query currentProject($id: ID!) {
    project(pk: $id) {
      id
      hasDocuments
      approvalSheetPrefix
      approvalSheetSuffix
      metadata {
        id
        name
        isChoices
        isShown
        sequence
        stringFormat
        choices
      }
      codification {
        id
        indexMetadata {
          name
        }
        unicityMetadata {
          name
        }
        codificationMetadata {
          name
        }
        titleMetadata {
          name
        }
        separator
      }
      workflowTemplates {
        id
        name
        sequence
        metadataName
        metadataValues
        hasDocuments
        steps {
          label
          type
          days0
          daysX
        }
        labels {
          admissibilityList {
            name
            isActive
            isAdmissible
          }
          pmEvaluationList {
            name
            isActive
          }
          summaryStateList {
            name
            isActive
          }
        }
      }
    }
  }`
export const updateStep3 = gql`
  mutation updateStep3(
    $projectId: ID!
    $metadata: [Step3MetadataInput!]!
    $codification: CodificationInput!
    $workflowTemplates: [WorkflowTemplateInput!]!
    $approvalSheetPrefix: String!
    $approvalSheetSuffix: String!
  ) {
    updateStep3(data: {
      projectId: $projectId
      metadata: $metadata
      codification: $codification
      workflowTemplates: $workflowTemplates
      approvalSheetPrefix: $approvalSheetPrefix
      approvalSheetSuffix: $approvalSheetSuffix
    }) {
      id
      name
    }
  }`
