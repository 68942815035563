<script>
import {
  cancelClosingSuggestionOnThread,
  createFirstComment,
  getThreadPermissions,
  suggestClosingThread,
} from '@comp/document/threads/queries'
import Thread from '@comp/document/threads/Thread.vue'
import ThreadsButtons from '@comp/document/threads/ThreadsButtons.vue'
import ThreadsHeader from '@comp/document/threads/ThreadsHeader.vue'
import ThreadsTable from '@comp/document/threads/ThreadsTable.vue'
import {
  getTradeContributionPermissions,
} from '@comp/document/tradeContributions/queries'
import { useIndexStore } from '@src/store/index.js'
import { STEP_TRADE_CONTRIBUTIONS } from '@src/utils/consts.js'

export default {
  name: 'Threads',
  components: {
    ThreadsHeader,
    Thread,
    ThreadsTable,
    ThreadsButtons,
  },
  provide: function () {
    return {
      isThreadLockToggable: this.isThreadLockToggable,
      displayThreadLockTitle: this.displayThreadLockTitle,
      getThreadLockIcon: this.getThreadLockIcon,
      lockThreadToggle: this.suggestLockThreadToggle,
    }
  },
  props: {
    tradeContribution: {
      type: Object,
      required: true,
    },
    currentStep: {
      type: String,
      required: true,
    },
    lockedMode: {
      type: Boolean,
      required: true,
    },
    documentVersionId: {
      type: String,
      required: true,
    },
    isOmanType: {
      type: Boolean,
      required: true,
    },
    pdfPreview: {
      type: Boolean,
      required: true,
    },
    commentedPage: {
      type: Number,
      required: true,
    },
    isCommentedPageClicked: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'update:tradeContribution',
    'closeContributionPanel',
    'submitContribution',
    'reopenContribution',
    'showThreadDetails',
    'closeThreadDetails',
    'activateLink',
  ],
  data () {
    return {
      threads: [],
      oldThreads: null,
      dataProcessing: false,
      showThreadDetails: false,
      currentThreadIndex: null,
    }
  },
  computed: {
    store () {
      return useIndexStore()
    },
    tradeContributionPerms () {
      return this.tradeContribution.permissions
    },
    tradeName () {
      return this.tradeContribution.trade.name
    },
    currentThread: {
      get () {
        if (this.currentThreadIndex === null) {
          return null
        } else if (this.currentThreadIndex === -1) {
          return {
            initialComments: [],
            allComments: [],
            permissions: {
              canAddComment: true,
              canSuggestClosing: false,
              canCancelClosingSuggestion: false,
              canRejectClosingSuggestion: false,
              canCloseThread: false,
              canReopenThread: false,
            },
          }
        } else {
          return this.threads[this.currentThreadIndex]
        }
      },
      async set (thread) {
        const localCurrentThreadIndex = this.currentThreadIndex
        if (localCurrentThreadIndex >= 0 && thread) {
          if (localCurrentThreadIndex < this.threads.length) {
            await this.$graphqlQuery(
              getThreadPermissions, { id: thread.id },
            ).then(response => {
              thread.permissions = response.thread.permissions
            })
          }
          this.threads.splice(localCurrentThreadIndex, 1, thread)
        }
      },
    },
    showThreadLockSuggestionButton () {
      return !this.lockedMode && this.currentStep === STEP_TRADE_CONTRIBUTIONS
    },
  },
  watch: {
    tradeContribution: {
      async handler () {
        await this.setData()
      },
      immediate: true,
    },
    async lockedMode () {
      await this.setData()
    },
    async documentVersionId () {
      await this.setData()
    },
    threads (value) {
      if (JSON.stringify(value) !== this.oldThreads) {
        this.oldThreads = JSON.stringify(this.threads)
        const newTradeContribution = { ...this.tradeContribution }
        newTradeContribution.threads = this.threads.slice()
        this.$emit('update:tradeContribution', newTradeContribution)
      }
    },
  },
  methods: {
    async setData () {
      this.threads = this.tradeContribution.threads
      if (this.oldThreads === null) {
        this.oldThreads = JSON.stringify(this.threads)
      }
    },
    onCloseContributionPanel () {
      this.$emit('closeContributionPanel')
      this.$emit('closeThreadDetails')
    },
    onSubmitContribution (...args) {
      this.$emit('submitContribution', ...args)
      this.$emit('closeThreadDetails')
    },
    onReopenContribution (...args) {
      this.$emit('reopenContribution', ...args)
    },
    onShowThread (thread) {
      this.dataProcessing = true
      this.currentThreadIndex = this.threads.findIndex(item => item.id === thread.id)
      this.showThreadDetails = true
      this.$emit('showThreadDetails')
    },
    onAddThread () {
      this.dataProcessing = true
      this.currentThreadIndex = -1
      this.showThreadDetails = true
      this.$emit('showThreadDetails')
    },
    closeThreadDetails () {
      this.showThreadDetails = false
      this.currentThreadIndex = null
      this.dataProcessing = false
      this.$emit('closeThreadDetails')
    },
    isThreadLockToggable (thread) {
      const perms = thread.permissions
      if (this.currentStep === STEP_TRADE_CONTRIBUTIONS) {
        return (
          (thread.suggestedToClose && perms.canCancelClosingSuggestion)
          || (!thread.suggestedToClose && perms.canSuggestClosing)
        )
      } else {
        return false
      }
    },
    displayThreadLockTitle (thread) {
      if (thread.suggestedToClose) {
        return this.$gettext('Cancel closing suggestion on the comment thread')
      } else {
        return this.$gettext('Suggest to close the comment thread')
      }
    },
    getThreadLockIcon (thread) {
      return thread.suggestedToClose ? 'fas fa-lock' : 'fas fa-lock-open'
    },
    async suggestLockThreadToggle (thread) {
      if (thread.suggestedToClose) {
        await this.cancelClosingSuggestion(thread)
      } else {
        await this.suggestClosing(thread)
      }
    },
    async suggestClosing (thread) {
      this.dataProcessing = true
      const id = thread.id
      await this.$graphqlMutate(suggestClosingThread, {
        id,
        documentVersionId: this.documentVersionId,
      }).then(response => {
        this.threads.splice(
          this.threads.findIndex(th => th.id === id),
          1,
          response.suggestClosingThread,
        )
        this.dataProcessing = false
      }).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
        this.dataProcessing = false
      })
    },
    async cancelClosingSuggestion (thread) {
      this.dataProcessing = true
      const id = thread.id
      await this.$graphqlMutate(cancelClosingSuggestionOnThread, {
        id,
        documentVersionId: this.documentVersionId,
      }).then(response => {
        this.threads.splice(
          this.threads.findIndex(th => th.id === id),
          1,
          response.cancelClosingSuggestionOnThread,
        )
        this.dataProcessing = false
      }).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
        this.dataProcessing = false
      })
    },
    async createNewThread (comment, importance, commentedPage) {
      const payload = {
        tradeContributionId: this.tradeContribution.id,
        documentVersionId: this.documentVersionId,
        importance: importance,
        commentedPage: commentedPage,
        text: comment.text,
        image: comment.imageUrl ? comment.imageFile : null,
      }
      await this.$graphqlMutate(createFirstComment, payload).then(response => {
        this.currentThreadIndex = this.threads.length
        this.currentThread = response.createFirstComment
        this.closeThreadDetails()
      })
    },
    onThreadDeleted () {
      // Don’t need to ask the backend to delete the thread:
      // deleting the latest message automatically deletes the thread.
      // So here the javascript model simply needs to be updated.
      const threadToDeleteId = this.currentThread.id
      this.closeThreadDetails()
      this.threads = this.threads.filter(thread => thread.id !== threadToDeleteId)
    },
    async onChangeThread (thread) {
      const threadIndex = this.threads.findIndex(thread => thread.id === thread.id)
      await this.$graphqlMutate(
        getThreadPermissions, { id: thread.id },
      ).then(response => {
        thread.permissions = response.thread.permissions
      })
      this.threads[threadIndex] = thread
      await this.$graphqlMutate(
        getTradeContributionPermissions, { id: this.tradeContribution.id },
      ).then(response => {
        this.tradeContribution.permissions = response.tradeContribution.permissions
      })
    },
    onActivateLink (isLinkActivated) {
      this.$emit('activateLink', isLinkActivated)
    },
  },
}
</script>
<template>
  <ThreadsHeader
    :trade-name="tradeName"
  />
  <ThreadsTable
    v-if="!showThreadDetails"
    :threads="threads"
    :current-step="currentStep"
    :locked-mode="lockedMode"
    :can-create-thread="tradeContributionPerms.canCreateThread"
    :show-lock-button="showThreadLockSuggestionButton"
    :is-oman-type="isOmanType"
    :data-processing="dataProcessing"
    @show="onShowThread"
    @add="onAddThread"
  />
  <ThreadsButtons
    v-if="!showThreadDetails"
    :current-step="currentStep"
    :can-submit="tradeContributionPerms.canSubmit"
    :can-reopen="tradeContributionPerms.canReopen"
    :data-processing="dataProcessing"
    @close-contribution-panel="onCloseContributionPanel"
    @submit-contribution="onSubmitContribution"
    @reopen-contribution="onReopenContribution"
  />
  <Thread
    v-if="showThreadDetails && currentThread"
    v-model:current-thread="currentThread"
    :current-step="currentStep"
    :document-version-id="documentVersionId"
    :is-oman-type="isOmanType"
    :pdf-preview="pdfPreview"
    :commented-page="commentedPage"
    :is-commented-page-clicked="isCommentedPageClicked"
    @close-thread-details="closeThreadDetails"
    @new-thread="createNewThread"
    @thread-deleted="onThreadDeleted"
    @change="onChangeThread"
    @activate-link="onActivateLink"
  />
</template>
