import gql from 'graphql-tag'

export const getDataStep4 = gql`
  query currentProject($id: ID!) {
    project(pk: $id) {
      id
      trades {
        id
        name
        acronym
        experts {
          id
          fullName
        }
      }
      metadata {
        id
        name
        isChoices
        isShown
        sequence
        stringFormat
        choices
      }
      tradeContributionMatrix {
        id
        trades
        sequence
        metadata {
          name
          value
        }
      }
    }
  }
`
export const updateTradeContributionMatrix = gql`
  mutation updateTradeContributionMatrix(
    $projectId: ID!
    $tradeContributionMatrix: [TradeContributionListInput!]!
  ) {
    updateTradeContributionMatrix(data: {
      projectId: $projectId
      tradeContributionMatrix: $tradeContributionMatrix
    }) {
      id
    }
  }
`
