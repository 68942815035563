<script>
import { STEP_TRADE_CONTRIBUTIONS } from '@src/utils/consts'
import { allTradeContributionCommentsForDocumentVersion } from './queries'
import AllCommentsPopup from '@comp/document/tradeContributions/AllCommentsPopup.vue'
export default {
  name: 'TradeContributionsHeader',
  components: {
    AllCommentsPopup,
  },
  props: {
    currentStep: {
      type: String,
      required: true,
    },
    documentVersion: {
      type: Object,
      required: true,
    },
    isOmanType: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      showAllComments: false,
      threads: [],
    }
  },
  computed: {
    numberOfThreadsSupToZero () {
      return this.threads.length > 0
    },
    allCommentsPopupShown () {
      return this.currentStep === STEP_TRADE_CONTRIBUTIONS
    },
    canShowAllComments () {
      return this.allCommentsPopupShown && this.numberOfThreadsSupToZero
    },
  },
  async mounted () {
    await this.setData()
  },
  methods: {
    async setData () {
      return this.$graphqlQuery(allTradeContributionCommentsForDocumentVersion, {
        documentId: this.documentVersion.document.id,
        documentVersionId: this.documentVersion.id,
        documentVersionSequence: this.documentVersion.sequence,
      }).then(response => {
        this.threads = response.document.threads
      }).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
      })
    },
    onCommentsPopupClose () {
      this.showAllComments = false
    },
  },
}
</script>
<template>
  <div class="document-step-header">
    <v-btn
      size="small"
      :color="!canShowAllComments ? 'lightgray' : 'secondary'"
      prepend-icon="fas fa-sticky-note"
      :disabled="!canShowAllComments"
      @click="showAllComments = !showAllComments"
    >
      {{ $gettext("Show all comments") }}
      <v-badge
        v-if="canShowAllComments && threads.length"
        color="primary"
        :content="threads.length"
        floating
      />
    </v-btn>
    <AllCommentsPopup
      v-if="allCommentsPopupShown"
      v-model:popup-shown="showAllComments"
      :document-version="documentVersion"
      :all-threads="threads"
      :is-oman-type="isOmanType"
      @close="onCommentsPopupClose"
    />
  </div>
</template>
<style lang="scss" scoped>
.document-step-header {
  display: flex;
  flex-direction: row-reverse;
}
.disabled-button {
  background-color: $grey-light !important;
}
.disabled-button > :deep(.v-btn__content) { // stylelint-disable-line selector-class-pattern
  color: $black;
}
.disabled-button > :deep(.v-btn__prepend) { // stylelint-disable-line selector-class-pattern
  color: $secondary;
}
</style>
