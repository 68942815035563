<script>
import ContractCard from './ContractCard.vue'

export default {
  name: 'ContractsSection',
  components: {
    ContractCard,
  },
  props: {
    contracts: {
      type: Array,
      required: true,
    },
    hasDocuments: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'addContract',
    'changeContractData',
  ],
  computed: {
    activeContracts () {
      return this.contracts.filter(o => o._deleted !== true)
    },
  },
  methods: {
    contractCodeIsUnique (codeToCheck) {
      const duplicates = this.duplicatedCodesArray()
      return !duplicates.includes(codeToCheck)
    },
    duplicatedCodesArray () {
      const codes = this.activeContracts.map(o => o.code)
      const inputList = new Set()
      const duplicates = new Set()
      for (const item of codes) {
        if (inputList.has(item)) {
          duplicates.add(item)
        } else {
          inputList.add(item)
        }
      }
      return [...duplicates]
    },
    addContract () {
      this.$emit('addContract')
    },
    onChangeContractData (contract, key) {
      this.$emit('changeContractData', contract, key)
      this.$refs.form.validate()
    },
  },
}
</script>
<template>
  <section class="form-section">
    <h1>{{ $gettext('Contracts') }}</h1>
    <v-form
      ref="form"
      class="contract-cards-list"
    >
      <transition-group name="list">
        <ContractCard
          v-for="contract in activeContracts"
          :key="contract.id"
          :contract-prop="contract"
          :contract-code-is-unique="contractCodeIsUnique"
          @change-contract-data="onChangeContractData"
        />
      </transition-group>
    </v-form>
    <div>
      <v-btn
        color="secondary"
        prepend-icon="fas fa-file-circle-plus"
        rounded
        @click="addContract"
      >
        {{ $gettext('Add a contract') }}
      </v-btn>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.contract-cards-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1ch;
}
</style>
