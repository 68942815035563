import gql from 'graphql-tag'

const commentCommon = gql`{
  id
  documentVersion {
    id
    index
    sequence
  }
  creationDatetime
  text
  imageUrl
  lastModificationDatetime
  permissions {
    canEdit
    canDelete
  }
  __typename
}`
const initialCommentCommon = gql`{
  ...${commentCommon}
  commentedPage
}`
export const initialCommentFragment = gql`
  fragment initialCommentFragment on InitialComment ${initialCommentCommon}
`
export const commentFragment = gql`
  fragment commentFragment on Comment ${commentCommon}
`
export const companyResponseFragment = gql`
  fragment companyResponseFragment on CompanyResponse ${commentCommon}
`
export const allComments = `
  ${commentFragment}
  ${companyResponseFragment}
  allComments {
    ... on Comment {
      ...commentFragment
    }
    ... on CompanyResponse {
      ...companyResponseFragment
    }
  }
`
export const createComment = gql`
  ${initialCommentFragment}
  mutation createComment(
    $threadId: ID!
    $documentVersionId: ID!
    $text: String!
    $image: Upload
  ) {
    createComment(data: {
      threadId: $threadId
      documentVersionId: $documentVersionId
      text: $text
      image: $image
    }) {
      ...initialCommentFragment
    }
  }
`
export const updateInitialComment = gql`
  ${initialCommentFragment}
  mutation updateInitialComment(
    $commentId: ID!
    $text: String!
    $imageChanged: Boolean!
    $image: Upload
  ) {
    updateInitialComment(
      pk: $commentId
      data: {
        text: $text
        imageChanged: $imageChanged
        image: $image
      }
    ) {
      ...initialCommentFragment
    }
  }
`
export const updatePmComment = gql`
  ${commentFragment}
  mutation updatePmComment(
    $commentId: ID!
    $text: String!
    $imageChanged: Boolean!
    $image: Upload
  ) {
    updatePmComment(
      pk: $commentId
      data: {
        text: $text
        imageChanged: $imageChanged
        image: $image
      }
    ) {
      ...commentFragment
    }
  }
`
export const deleteInitialComment = gql`
  mutation deleteInitialComment($commentId: ID!) {
    deleteInitialComment(pk: $commentId) {
      id
      threadDeleted
    }
  }
`
