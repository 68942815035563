import gql from 'graphql-tag'

export const currentProject = gql`
  query currentProject($id: ID!) {
    project(pk: $id) {
      id
      name
      docControllers {
        id
      }
      permissions {
        doIHaveAccess
        canIConfigure
      }
      contracts {
        id
        name
        code
        companyContracts {
          id
          company {
            id
            name
          }
        }
      }
      trades {
        id
        name
        acronym
        experts {
          id
        }
      }
      metadata {
        id
        name
        isShown
        isChoices
        sequence
        stringFormat
        choices
      }
    }
  }
`
export const allDocumentVersions = `
  query allDocumentVersions {
    allDocumentVersions##REPLACE## {
      id
      title
      isInLastStep
      isFirstVersion
      url
      directoryUrl
      originGedUrl
      workflowStartDatetime
      stepDeadline
      workflowDeadline
      remainingStepWorkDays
      remainingStepCalendarDays
      remainingWorkflowWorkDays
      remainingWorkflowCalendarDays
      endOfWorkflowTransitionDatetime
      tradeContributions {
        trade {
          acronym
        }
        status
      }
      currentStepTemplate {
        type
      }
      metadataValues {
        metadata {name}
        value
      }
      document {
        contract {
          id
          code
          name
        }
        versions {
          id
          index
        }
      }
    }
    countDocumentVersions##REPLACE_COUNT## {
      totalCount
    }
  }
`
export const createDocumentVersion = gql`
  mutation createDocumentVersion(
    $contractId: ID!
    $metadataValues: [MetadataNameValueInput!]!
    $file: Upload!
    $workflowStartDatetime : DateTime!
    $originGedUrl : String!
  ) {
    createDocumentVersion(data: {
      contractId: $contractId
      metadataValues: $metadataValues
      file: $file
      workflowStartDatetime: $workflowStartDatetime
      originGedUrl: $originGedUrl
    }) {
      document {
        id
      }
    }
  }
`
