<script>
import { backUrl } from '@src/config'
import CommentImagePopup from '@comp/document/comments/CommentImagePopup.vue'
import CommentReadOnly from '@comp/document/comments/CommentReadOnly.vue'
import { THREAD_IMPORTANCE } from '@src/utils/consts'
const _imageUrl = `${backUrl}media/`

const IMPORTANCE_ORDER = [
  'MAJOR',
  'MINOR',
  'INTERNAL',
]
export default {
  name: 'CommentsPopup',
  components: {
    CommentImagePopup,
    CommentReadOnly,
  },
  props: {
    popupShown: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    documentVersion: {
      type: Object,
      required: true,
    },
    threads: {
      type: Array,
      required: true,
    },
    showImportance: {
      type: Boolean,
      default: () => true,
    },
    isOmanType: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'update:popupShown',
    'close',
  ],
  data () {
    return {
      popupShownState: false,
      showImagePopup: false,
      currentComment: null,
    }
  },
  computed: {
    headers () {
      const headers = [
        { title: this.$gettext('Discipline acronym'), key: 'tradeAcronym', sortable: false, width: '10%' },
        { title: this.$gettext('Importance'), key: 'importance', sortable: false, width: '10%' },
        { title: this.$gettext('Comment'), key: 'text', sortable: false, width: '80%' },
      ]
      if (!this.showImportance) {
        headers.splice(1, 1)
      }
      return headers
    },
    MAX_CHARS () {
      return 30
    },
    sortedThreads () {
      const threads = this.threads.slice().sort(this.sortThreads)
      return this.generateThreadComments(threads)
    },
  },
  watch: {
    popupShown: {
      async handler () {
        this.popupShownState = this.popupShown
      },
      immediate: true,
    },
    popupShownState () {
      this.$emit('update:popupShown', this.popupShownState)
      if (!this.popupShownState) {
        this.$emit('close')
      }
    },
  },
  methods: {
    sortThreadsByTradeAcronym (th1, th2) {
      if (th1.trade.acronym > th2.trade.acronym) {
        return 1
      } else if (th1.trade.acronym < th2.trade.acronym) {
        return -1
      } else {
        return 0
      }
    },
    sortThreadsByImportance (th1, th2) {
      if (this.isOmanType) {
        const versionBigNumber = 1000000
        const versionRegex = RegExp('(\\d+)', 'g')
        const versionFilter = s => s.replace(versionRegex, n => '' + (1 * n + versionBigNumber))
        const versionList = [th1.importance, th2.importance].map(versionFilter)
        if (versionList[0] === versionList[1]) {
          return 0
        } else {
          return versionList[0] < versionList[1] ? -1 : 1
        }
      } else {
        const th1ImportanceOrder = IMPORTANCE_ORDER.indexOf(th1.importance)
        const th2ImportanceOrder = IMPORTANCE_ORDER.indexOf(th2.importance)
        if (th1ImportanceOrder === th2ImportanceOrder) {
          return 0
        } else {
          return th1ImportanceOrder - th2ImportanceOrder
        }
      }
    },
    sortThreads (th1, th2) {
      const tradeAcronymComparison = this.sortThreadsByTradeAcronym(th1, th2)
      if (tradeAcronymComparison !== 0) {
        return tradeAcronymComparison
      }
      const importanceComparison = this.sortThreadsByImportance(th1, th2)
      if (importanceComparison !== 0) {
        return importanceComparison
      }
      return th1.sequence - th2.sequence
    },
    sortCommentsDescendingOrder (c1, c2) {
      if (c1.documentVersion.sequence > c2.documentVersion.sequence) {
        return -1
      } else if (c1.documentVersion.sequence < c2.documentVersion.sequence) {
        return 1
      } else {
        return 0
      }
    },
    generateThreadComments (threads) {
      return threads.map(thread => {
        const allKindComments = [...thread.initialComments, ...thread.allComments]
        allKindComments.sort(this.sortCommentsDescendingOrder)
        return {
          id: thread.id,
          trade: thread.trade,
          importance: thread.importance,
          allKindComments,
        }
      })
    },
    displayTradeName (thread) {
      return thread.trade.name
    },
    displayTradeAcronym (thread) {
      return thread.trade.acronym
    },
    displayImportance (thread) {
      return this.isOmanType ? thread.importance : this.$gettext(THREAD_IMPORTANCE[thread.importance])
    },
    getTextLines (comment) {
      const sep = '</p>'
      const lines = comment.text.split(sep).filter(line => line).map(line => line + sep)
      // guaranteed to be a non-empty array
      return lines.length ? lines : ['']
    },
    displayImageLink (comment) {
      return comment.imageUrl
    },
    showImage (comment) {
      this.currentComment = comment
      this.showImagePopup = true
    },
    closeImage () {
      this.showImagePopup = false
      this.currentComment = null
    },
    getBackgroundImage (imgUrl) {
      return `url("${_imageUrl}${imgUrl}")`
    },
    hideComments () {
      this.popupShownState = false
    },
  },
}
</script>
<template>
  <v-dialog
    v-model="popupShownState"
    scrollable
    width="80%"
  >
    <v-card class="card-without-padding">
      <v-card-title class="dialog-header">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-data-table-virtual
          :headers="headers"
          :items="sortedThreads"
          class="comments-table"
          fixed-header
        >
          <template #item.tradeAcronym="{ item: thread }">
            <div :title="displayTradeName(thread)">
              {{ displayTradeAcronym(thread) }}
            </div>
          </template>
          <template
            v-if="showImportance"
            #item.importance="{ item: thread }"
          >
            {{ displayImportance(thread) }}
          </template>
          <template #item.text="{ item: thread }">
            <CommentReadOnly
              v-for="comment in thread.allKindComments"
              :key="comment.id"
              :comment="comment"
              :is-oman-type="isOmanType"
              @show-image="showImage"
            />
          </template>
        </v-data-table-virtual>
        <CommentImagePopup
          v-if="showImagePopup && currentComment"
          :comment="currentComment"
          @close="closeImage"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-spacer />
        <v-btn
          variant="elevated"
          prepend-icon="fas fa-times"
          @click="hideComments"
        >
          {{ $gettext('Close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
.v-overlay__content { // stylelint-disable-line selector-class-pattern
    top: 100px;
    left: 10px;
}
.card-without-padding {
  padding: 0 !important;
}
.v-card-text {
  padding: 0 !important;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.comments-table {
  overflow-y:auto;
}
</style>
