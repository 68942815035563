<script>
import { validateSharepointFilename, maxStringLengthRule } from '@src/utils/userInputValidation'

export default {
  name: 'ApprovalSheetName',
  props: {
    approvalSheetSuffix: {
      type: String,
      required: true,
    },
    approvalSheetPrefix: {
      type: String,
      required: true,
    },
  },
  emits: [
    'changeApprovalSheetSuffix',
    'changeApprovalSheetPrefix',
  ],
  data () {
    return {
      prefix: this.approvalSheetPrefix,
      suffix: this.approvalSheetSuffix,
    }
  },
  methods: {
    validate (input) {
      if (!maxStringLengthRule(input, 20)) {
        return 'This field cannot contain more than 20 characters'
      } else if (!validateSharepointFilename(input)) {
        return 'This field cannot contain any of the following characters: " * : < > ? / \\ |'
      }
      return true
    },
    onChangeApprovalSheetSuffix () {
      this.$emit('changeApprovalSheetSuffix', this.suffix)
    },
    onChangeApprovalSheetPrefix () {
      this.$emit('changeApprovalSheetPrefix', this.prefix)
    },
  },
}
</script>
<template>
  <v-card
    :title="$gettext('Approval sheet name')"
  >
    <v-card-text>
      <v-text-field
        v-model="prefix"
        class="name"
        color="secondary"
        :label="$gettext('Prefix')"
        :rules="[validate(prefix)]"
        :hint="$gettext('Note: add a space after the prefix')"
        @change="onChangeApprovalSheetPrefix"
      >
        <template #message="{ message }">
          {{ $gettext(message) }}
        </template>
      </v-text-field>
      <v-text-field
        v-model="suffix"
        color="secondary"
        :label="$gettext('Suffix')"
        :rules="[validate(suffix)]"
        :hint="$gettext('Note: add a space before the suffix')"
        @change="onChangeApprovalSheetSuffix"
      >
        <template #message="{ message }">
          {{ $gettext(message) }}
        </template>
      </v-text-field>
    </v-card-text>
  </v-card>
</template>
<style lang="scss" scoped>
.label {
  width: 100%;
}
</style>
