<script>
export default {
  name: 'PmEvaluationButtons',
  components: {},
  props: {
    documentVersion: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  emits: [
    'nextStep',
  ],
  data () {
    return {}
  },
  computed: {
    canIAdvanceToNextStep () {
      return this.documentVersion.permissions.canIAdvanceToNextStep
    },
  },
  methods: {
    nextStep () {
      this.$emit('nextStep')
    },
  },
}
</script>
<template>
  <div class="step-btn-container pm-evaluation-buttons">
    <v-btn
      :color="!canIAdvanceToNextStep ? 'lightgray' : 'primary'"
      :disabled="!canIAdvanceToNextStep"
      prepend-icon="fas fa-arrow-right"
      @click="nextStep"
    >
      {{ $gettext("Go to next step") }}
    </v-btn>
  </div>
</template>
<style lang="scss" scoped>
.pm-evaluation-buttons {
  margin-top: 0;
}
</style>
