<script>
import { useIndexStore } from '@src/store'

export default {
  name: 'Notification',
  data () {
    return {
      snackbar: false,
    }
  },
  computed: {
    notification () {
      return useIndexStore().notification
    },
  },
  watch: {
    notification () {
      this.snackbar = true
    },
  },
  methods: {
    closeSnackbar () {
      this.snackbar = false
    },
  },
}
</script>
<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="notification.autoClose ? 3000 : -1"
    transition="slide-y-reverse-transition"
    color="secondary"
    :class="`snackbar-${notification.type}`"
  >
    <v-icon
      v-if="notification.icon"
      :icon="notification.icon"
    />
    {{ notification.text }}
    <template v-slot:actions>
      <v-btn
        size="small"
        color="primary"
        variant="text"
        @click="closeSnackbar"
      >
        {{ $gettext("Close") }}
      </v-btn>
    </template>
  </v-snackbar>
</template>
