<script>
export default {
  name: 'ProjectAddDialog',
  emits: [
    'addNewProject',
    'closeAddDialog',
  ],
  data () {
    return {
      newProjectName: '',
    }
  },
  computed: {
    open () {
      return true
    },
    isNewProjectNameEmpty () {
      return this.newProjectName === ''
    },
  },
  methods: {
    addProject () {
      this.$emit('addNewProject', this.newProjectName)
    },
    closeAddDialog () {
      this.newProjectName = ''
      this.$emit('closeAddDialog')
    },
  },
}
</script>
<template>
  <v-dialog
    v-model="open"
    persistent
    width="30%"
  >
    <v-card>
      <v-card-title>
        {{ $gettext('New project creation') }}
      </v-card-title>
      <v-card-text>
        <div>
          {{ $gettext('What name do you want to give to your new project?') }}
        </div>
        <v-text-field
          v-model="newProjectName"
          color="secondary"
          :label="$gettext('Project name')"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="lightgrey"
          variant="elevated"
          prepend-icon="fas fa-times"
          @click="closeAddDialog"
        >
          {{ $gettext('Cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          variant="elevated"
          :disabled="isNewProjectNameEmpty"
          prepend-icon="fas fa-save"
          @click="addProject"
        >
          {{ $gettext('Save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
