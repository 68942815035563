<script>
export default {
  name: 'CodificationSeparatorSelect',
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    currentSeparator: {
      type: String,
      default: null,
    },
  },
  emits: [
    'updateCurrentSeparator',
  ],
  data () {
    return {
      separator: '',
      oldSeparator: '',
    }
  },
  computed: {
    separatorDisplay () {
      return [
        { value: ' ', text: this.$gettext('space') },
        { value: '-', text: this.$gettext('dash') },
        { value: '_', text: this.$gettext('underscore') },
      ]
    },
    separatorLabel () {
      return this.$gettext('Separator')
    },
  },
  watch: {
    currentSeparator: {
      handler () {
        this.setData()
      },
      immediate: true,
    },
  },
  methods: {
    setData () {
      this.separator = this.currentSeparator
      if (this.oldSeparator === '') {
        this.oldSeparator = this.separator
      }
    },
    itemText (item) {
      if (item.value && item.text) {
        return `${item.value} : ${this.$gettext(item.text)}`
      }
      return ''
    },
    onChanged () {
      if (this.oldSeparator !== this.separator) {
        this.oldSeparator = this.separator
        this.$emit('updateCurrentSeparator', this.separator)
      }
    },
  },
}
</script>
<template>
  <v-select
    v-model="separator"
    :items="separatorDisplay"
    :item-title="itemText"
    :label="separatorLabel"
    :disabled="disabled"
    item-value="value"
    color="secondary"
    @update:model-value="onChanged"
  />
</template>
