<script>
import Comments from '@comp/document/comments/Comments.vue'
import {
  STEP_EXTERNAL_CONTRIBUTIONS,
  STEP_TRADE_CONTRIBUTIONS,
} from '@src/utils/consts'

export default {
  name: 'Thread',
  components: {
    Comments,
  },
  props: {
    currentThread: {
      type: Object,
      required: true,
    },
    currentStep: {
      type: String,
      required: true,
    },
    documentVersionId: {
      type: String,
      required: true,
    },
    isOmanType: {
      type: Boolean,
      required: true,
    },
    pdfPreview: {
      type: Boolean,
      required: true,
    },
    commentedPage: {
      type: Number,
      required: true,
    },
    isCommentedPageClicked: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'closeThreadDetails',
    'newThread',
    'update:currentThread',
    'threadDeleted',
    'activateLink',
  ],
  data () {
    return {
      thread: {},
      displayBackButton: true,
    }
  },
  computed: {
    allComments: {
      get () {
        const lst = []
        if ([STEP_TRADE_CONTRIBUTIONS, STEP_EXTERNAL_CONTRIBUTIONS].includes(this.currentStep)) {
          lst.push(...this.thread.initialComments)
        }
        lst.push(...this.thread.allComments)
        return lst
      },
      set (comments) {
        if (this.thread.id) {
          this.thread.allComments = comments.filter(
            comment => comment.__typename !== 'InitialComment',
          )
          if ([STEP_TRADE_CONTRIBUTIONS, STEP_EXTERNAL_CONTRIBUTIONS].includes(this.currentStep)) {
            this.thread.initialComments = comments.filter(
              comment => comment.__typename === 'InitialComment',
            )
          }
        }
      },
    },
  },
  watch: {
    currentThread: {
      handler () {
        this.thread = { ...this.currentThread }
      },
      immediate: true,
    },
    'thread.initialComments' (value, oldValue) { this.onThreadContentChange(value, oldValue) },
    'thread.allComments'  (value, oldValue) { this.onThreadContentChange(value, oldValue) },
  },
  methods: {
    onCancelCommentEdition () {
      this.displayBackButton = true
    },
    onSwitchToCommentEdition () {
      this.displayBackButton = false
    },
    closeThreadDetails () {
      this.$emit('closeThreadDetails')
    },
    onNewThread (...args) {
      this.displayBackButton = false
      this.$emit('newThread', ...args)
    },
    onThreadContentChange (value, oldValue) {
      if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
        this.$emit('update:currentThread', this.thread)
      }
    },
    onThreadDeleted (...args) {
      this.$emit('threadDeleted', ...args)
    },
    onChangeComments (comments) {
      this.allComments = comments
    },
    onActivateLink (isLinkActivated) {
      this.$emit('activateLink', isLinkActivated)
    },
  },
}
</script>
<template>
  <Comments
    v-model:all-comments="allComments"
    :thread-id="thread.id"
    :document-version-id="documentVersionId"
    :can-add-comment="thread.permissions.canAddComment"
    :current-step="currentStep"
    :thread-importance="thread.importance"
    :is-oman-type="isOmanType"
    :pdf-preview="pdfPreview"
    :commented-page="commentedPage"
    :is-commented-page-clicked="isCommentedPageClicked"
    @new-thread="onNewThread"
    @close-dialog="closeThreadDetails"
    @switch-to-comment-edition="onSwitchToCommentEdition"
    @cancel-comment-edition="onCancelCommentEdition"
    @thread-deleted="onThreadDeleted"
    @change="onChangeComments"
    @activate-link="onActivateLink"
  />
  <v-btn
    v-if="displayBackButton"
    class="back-button"
    color="lightgrey"
    prepend-icon="fas fa-arrow-left"
    @click="closeThreadDetails"
  >
    {{ $gettext('Go back to threads listing') }}
  </v-btn>
</template>
<style lang="scss" scoped>
.back-button {
  width: fit-content;
}
</style>
