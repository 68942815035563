<script>
export default {
  name: 'MetadatumItem',
  props: {
    hasDocuments: {
      type: Boolean,
      default: false,
    },
    metadatum: {
      /* { id, sequence, name, isChoices, choices, stringFormat, isShown, _created } */
      type: Object,
      required: true,
    },
    metadata: {
      type: Array,
      required: true,
    },
    maxSequence: {
      type: Number, /* 0-based */
      required: true,
    },
  },
  emits: [
    'update:metadatum',
    'remove:metadatum',
    'order:metadatum',
  ],
  data () {
    return {
      /* { id, sequence, name, isChoices, choices, stringFormat, isShown, _created } */
      datum: {}, // comes from metadatum prop
      rulePattern: /^((\[[A0*]+\])?[A0*]?)*$/,
    }
  },
  computed: {
    canBeModified () {
      return !!(!this.hasDocuments || this.datum._created)
    },
  },
  watch: {
    metadatum: {
      handler() {
        this.datum = this.metadatum
      },
      immediate: true,
    },
  },
  methods: {
    updateMetadatum () {
      this.$emit('update:metadatum', this.datum)
    },
    removeMetadatum () {
      this.$emit('remove:metadatum', this.datum)
    },
    orderMetadatum (offset) { // offset could be +1 or -1
      this.$emit('order:metadatum', this.datum, offset)
    },
    isNameValid (name, id) {
      if (name.toLowerCase() === this.$gettext('Marché').toLowerCase()) {
        return this.$gettext('The name \"Marché\" is reserved')
      } else {
        const metadataValues = this.metadata.filter(o => o.id !== id).map(o => o.name)
        return !metadataValues.includes(name) || this.$gettext('A metadatum already has the same name')
      }
    },
    isFormatValid (value) {
      return this.rulePattern.test(value) || this.$gettext('Incorrect value')
    },
  },
}
</script>
<template>
  <v-card>
    <v-card-title>
      <div class="test">
        <v-text-field
          v-model="datum.name"
          hide-details
          :label="$gettext('Field name')"
          :rules="[isNameValid(metadatum.name, metadatum.id)]"
          :disabled="!canBeModified"
          @change="updateMetadatum"
        />
      </div>
    </v-card-title>
    <v-card-text>
      <div class="row">
        <v-text-field
          v-if="!datum.isChoices"
          v-model="datum.stringFormat"
          hide-details
          color="secondary"
          :label="$gettext('Text format')"
          :rules="[isFormatValid]"
          :disabled="!canBeModified"
          @change="updateMetadatum"
        />
        <ChipsCombobox
          v-else
          v-model="datum.choices"
          :label="$gettext('Possible choices')"
          hide-details
          :item-display="item => item.title"
        />
        <div class="options-checkboxes">
          <v-checkbox
            v-model="datum.isShown"
            :label="$gettext('Show in the table')"
            color="primary"
            hide-details
            @update:model-value="updateMetadatum"
          />
          <v-checkbox
            v-model="datum.isChoices"
            :label="$gettext('List of choices')"
            color="primary"
            density="compact"
            class="my-switch"
            hide-details
            :disabled="!canBeModified"
            @update:model-value="updateMetadatum"
          />
        </div>
        <div class="control-buttons">
          <v-btn
            size="small"
            variant="text"
            icon="fas fa-arrow-up"
            :disabled="datum.sequence === 0"
            @click="orderMetadatum(-1)"
          />
          <v-btn
            size="small"
            variant="text"
            icon="fas fa-arrow-down"
            :disabled="datum.sequence === maxSequence"
            @click="orderMetadatum(+1)"
          />
          <v-btn
            size="small"
            variant="text"
            icon="fas fa-trash"
            :disabled="!canBeModified"
            @click="removeMetadatum"
          />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<style lang="scss" scoped>
.v-card {
  display: flex;
  flex-direction: row !important;
}
.v-card-title {
  background-color: $grey-light !important;
  min-width: 200px;
  align-content: center;
}
.v-card-text {
  flex-grow: 1;
  gap: 1ch;
  padding-top: 1rem !important;
}
.control-buttons {
  min-width: 120px;
}
.options-checkboxes {
  display: flex;
  flex-direction: row;
  gap: 1ch;
  align-items: center;
  min-width: 300px;
}
</style>
