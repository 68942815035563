<script>
import MetadatumItem from '@comp/projectSettings/step3Documents/MetadatumItem.vue'

export default {
  name: 'MetadataSection',
  components: {
    MetadatumItem,
  },
  props: {
    hasDocuments: {
      type: Boolean,
      default: false,
    },
    metadata: {
      /* Array of { id, sequence, name, isChoices, choices, stringFormat, isShown, _created } */
      type: Array,
      required: true,
    },
  },
  emits: [
    'update:metadata',
  ],
  methods: {
    getMetadataExcludeNames (metadatum) {
      return this.metadata.filter(o => o.id !== metadatum.id).map(o => o.name)
    },
    onUpdateMetadatum (metadatum, index) {
      const metadata = [
        ...this.metadata.slice(0, index),
        metadatum,
        ...this.metadata.slice(index + 1),
      ]
      this.$emit('update:metadata', metadata)
    },
    onRemoveMetadatum (metadatum) {
      const index = this.metadata.findIndex(o => o.id === metadatum.id)
      const metadata = [...this.metadata.slice(0, index), ...this.metadata.slice(index + 1)]
      metadata.forEach((metadatum, index) => {
        metadatum.sequence = index
      })
      this.$emit('update:metadata', metadata)
    },
    onReorderMetadatum (metadatum, offset) { // offset could be +1 or -1
      const metadata = [...this.metadata]
      const toMoveMetadatum = metadata.find(x => x.id === metadatum.id)
      const swapMetadatum = metadata.find(x => x.sequence === metadatum.sequence + offset)
      toMoveMetadatum.sequence += offset
      swapMetadatum.sequence += -offset
      metadata.sort((a, b) => a.sequence - b.sequence)
      this.$emit('update:metadata', metadata)
    },
    getRandomId () {
      return Math.random().toString(36).substr(2, 9)
    },
    onAddMetadatum () {
      const metadata = [
        ...this.metadata,
        {
          id: this.getRandomId(),
          name: '',
          isShown: true,
          isChoices: false,
          stringFormat: '',
          choices: [],
          sequence: this.metadata.length,
          _created: true,
        },
      ]
      this.$emit('update:metadata', metadata)
    },
  },
}
</script>
<template>
  <div class="form-section">
    <h1>{{ $gettext('Metadata') }}</h1>
    <div>
      <!-- eslint-disable-next-line max-len -->
      {{ $gettext('To specify the expected metadata text format, use \"*\" to allow any character, \"A\" to allow only letters, and \"0\" to allow only numbers. Leave the field blank to allow any format. Enclose optional items in \"[]\"') }}
    </div>
    <div class="metadata-container">
      <MetadatumItem
        v-for="(metadatum, index) in metadata"
        :key="metadatum.id"
        :metadatum="metadatum"
        :metadata="metadata"
        :has-documents="hasDocuments"
        :metadata-names="getMetadataExcludeNames(metadatum)"
        :max-sequence="metadata.length - 1"
        @update:metadatum="m => onUpdateMetadatum(m, index)"
        @remove:metadatum="onRemoveMetadatum"
        @order:metadatum="onReorderMetadatum"
      />
    </div>
    <div>
      <v-btn
        color="secondary"
        prepend-icon="fas fa-plus"
        rounded
        @click="onAddMetadatum"
      >
        {{ $gettext('Add a metadatum') }}
      </v-btn>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.metadata-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
