import {
  threadHistoryVDocIdSeqFragment,
  threadVDocIdFragment,
} from '@comp/document/threads/queries'
import gql from 'graphql-tag'

export const tradeContributionPermissionsFragment = gql`
  fragment tradeContributionPermissionsFragment on TradeContributionStatus {
    permissions {
      canAssignUser
      canUnassignUser
      canSetNotApplicable
      canSetApplicable
      canCreateThread
      canSubmit
      canReopen
      canSee
    }
  }
`
export const tradeContributionVDocIdFragment = gql`
  ${tradeContributionPermissionsFragment}
  ${threadVDocIdFragment}
  fragment tradeContributionVDocIdFragment on TradeContributionStatus {
    ...tradeContributionPermissionsFragment
    id
    status
    trade {
      id
      acronym
      name
    }
    assignedTo {
      id
      fullName
    }
    lastSubmittedDatetime
    threads(filters: {notPreviouslyClosed: true}) {
      ...threadVDocIdFragment
    }
  }
`
export const allTradeContributions = gql`
  ${tradeContributionVDocIdFragment}
  query allTradeContributions(
    $documentVersionId: ID! # for query and fragment
    $projectId: ID!
  ) {
    documentVersion(pk: $documentVersionId) {
      id
      sequence
      document {
        id
      }
      permissions {
        canIAdvanceToNextStep
      }
    }
    allTrades(filters: {project: {id: {exact: $projectId}}}) {
      id
      acronym
      name
    }
    allTradeContributions(filters: {documentVersion: {id: {exact: $documentVersionId}}}) {
      ...tradeContributionVDocIdFragment
    }
  }
`

export const allTradeContributionCommentsForDocumentVersion = gql`
  ${threadHistoryVDocIdSeqFragment}
  query allTradeContributionCommentsForDocumentVersion(
    $documentId: ID!
    $documentVersionId: ID! # for fragment
    $documentVersionSequence: Int! # for fragment
  ) {
    document(pk: $documentId) {
      threads(filters: {
        NOT: {importance: {exact: "INTERNAL"}}
        notClosedBeforeVersionSequence: $documentVersionSequence
      }) {
        ...threadHistoryVDocIdSeqFragment
      }
    }
  }
`

export const allInternalNotesForDocumentVersion = gql`
  ${threadHistoryVDocIdSeqFragment}
  query allInternalNotes(
    $documentId: ID!
    $documentVersionId: ID! # for fragment
    $documentVersionSequence: Int! # for fragment
  ) {
    document(pk: $documentId) {
      threads(filters: {
        importance: {exact: "INTERNAL"}
        notClosedBeforeVersionSequence: $documentVersionSequence
      }) {
        ...threadHistoryVDocIdSeqFragment
      }
    }
  }
`

export const createTradeContribution = gql`
  ${tradeContributionVDocIdFragment}
  mutation createTradeContribution(
    $documentVersionId: ID! # for fragment
    $tradeId: ID!
  ) {
    createTradeContribution(data: {
      documentVersionId: $documentVersionId,
      tradeId: $tradeId
    }) {
      ...tradeContributionVDocIdFragment
    }
  }
`
export const updateTradeContributionStatus = gql`
  ${tradeContributionVDocIdFragment}
  mutation updateTradeContributionStatus(
    $id: ID!
    $documentVersionId: ID! # for fragment
    $status: StatusChoices!
  ) {
    updateTradeContributionStatus(
      pk: $id
      data: {
        status: $status
      }
    ) {
      ...tradeContributionVDocIdFragment
    }
  }
`
export const finishTradeContributions = gql`
  mutation finishTradeContributions(
    $documentVersionId: ID!
  ) {
    finishTradeContributions(pk: $documentVersionId) {
      currentStepTemplate {
        type
      }
    }
  }
`
export const finishPmEvaluation = gql`
  mutation finishPmEvaluation(
    $documentVersionId: ID!
    $pmEvaluationLabel: String!
  ) {
    finishPmEvaluation(
      pk: $documentVersionId
      data: {
        pmEvaluationLabel: $pmEvaluationLabel
      }
    ) {
      currentStepTemplate {
        type
      }
    }
  }
`
export const getTradeContributionPermissions = gql`
  ${tradeContributionPermissionsFragment}
  query getTradeContributionPermissions(
    $id: ID!
  ) {
    tradeContribution(pk: $id) {
      ...tradeContributionPermissionsFragment
    }
  }
`
