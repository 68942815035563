<script>
export default {
  name: 'PmEvaluationDialog',
  props: {
    pmEvaluationLabel: {
      type: String,
      default: () => '',
    },
    pmEvaluationList: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'update:pmEvaluationLabel',
    'close',
    'save',
  ],
  data () {
    return {
      evaluationLabel: this.pmEvaluationLabel || null,
    }
  },
  computed: {
    open () {
      return true
    },
    evaluationFilteredList () {
      return (
        this.pmEvaluationList
          .filter(label => label.isActive)
          .map(label => label.name)
      )
    },
    canSave () {
      return !!this.evaluationLabel
    },
  },
  methods: {
    closeDialog () {
      this.$emit('close')
    },
    saveDialog () {
      this.$emit('save', this.evaluationLabel)
    },
  },
}
</script>
<template>
  <v-dialog
    v-model="open"
    persistent
    width="30%"
  >
    <v-card>
      <v-card-title class="dialog-header">
        {{ $gettext("Submission of Project Manager evaluation") }}
      </v-card-title>
      <v-card-text>
        <div>{{ $gettext('What is your evaluation of this document?') }}</div>
        <v-select
          v-model="evaluationLabel"
          :items="evaluationFilteredList"
          :label="$gettext('Project Manager Evaluation')"
          color="secondary"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="lightgrey"
          variant="elevated"
          prepend-icon="fas fa-times"
          @click="closeDialog"
        >
          {{ $gettext('Cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          variant="elevated"
          :disabled="!canSave"
          prepend-icon="fas fa-save"
          @click="saveDialog"
        >
          {{ $gettext('Save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
