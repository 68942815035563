<script>
import FormStep1 from '@comp/projectSettings/step1Project/FormStep1.vue'
import FormStep2 from '@comp/projectSettings/step2ProjectTeam/FormStep2.vue'
import FormStep3 from '@comp/projectSettings/step3Documents/FormStep3.vue'
import FormStep4 from '@comp/projectSettings/step4Contributions/FormStep4.vue'
import FormStep5 from '@comp/projectSettings/step5ApprovalSheetTemplate/FormStep5.vue'

export default {
  name: 'ProjectSettingsStepper',
  components: {
    FormStep1,
    FormStep2,
    FormStep3,
    FormStep4,
    FormStep5,
  },
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
  },
  emits: [
    'changeStep',
  ],
  data () {
    return {
      selectedStep: 1,
      saveData: null,
    }
  },
  watch: {
    currentStep: {
      async handler() {
        this.selectedStep = this.currentStep
      },
    },
  },
  mounted () {
    this.setSaveDataFunction()
  },
  updated () {
    this.setSaveDataFunction()
  },
  methods: {
    setSaveDataFunction () {
      this.saveData = this.$refs[`step_${this.selectedStep}`].saveData
    },
    onUpdateModelValue (event) {
      this.$emit('changeStep', event)
    },
  },
}
</script>
<template>
  <v-stepper
    v-model="selectedStep"
    hide-actions
    non-linear
    editable
    flat
    bg-color="ultralightgrey"
    :items="[
      $gettext('Project'),
      $gettext('Project team'),
      $gettext('Documents'),
      $gettext('Contributions'),
      $gettext('Approval sheet template'),
    ]"
    @update:model-value="onUpdateModelValue"
  >
    <template v-slot:item.1>
      <div class="step-scrolling">
        <FormStep1
          ref="step_1"
          :step="currentStep"
        />
      </div>
    </template>
    <template v-slot:item.2>
      <div class="step-scrolling">
        <FormStep2
          ref="step_2"
          :step="currentStep"
        />
      </div>
    </template>
    <template v-slot:item.3>
      <div class="step-scrolling">
        <FormStep3
          ref="step_3"
          :step="currentStep"
        />
      </div>
    </template>
    <template v-slot:item.4>
      <FormStep4
        ref="step_4"
        :step="currentStep"
      />
    </template>
    <template v-slot:item.5>
      <div class="step-scrolling">
        <FormStep5
          ref="step_5"
          :step="currentStep"
        />
      </div>
    </template>
  </v-stepper>
</template>
<style lang="scss">
.v-stepper {
  flex-grow: 1;
}
.v-stepper-header {
  background-color: $white !important;
  padding: 0 5%;
}
.step-scrolling {
  max-height: calc(100vh - 270px);
  overflow-y: auto;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 5% 10px;
}
.form-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.row {
  gap: 1ch;
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
