export const STEP_ADMISSIBILITY = 'Admissibility'
export const STEP_TRADE_CONTRIBUTIONS = 'TradeContributions'
export const STEP_PM_EVALUATION = 'PMEvaluation'
export const STEP_PM_EVALUATION_SUBMISSION = 'PMEvaluationSubmission'
export const STEP_EXTERNAL_CONTRIBUTIONS = 'ExternalContributions'
export const STEP_EVALUATION_SUMMARY = 'EvaluationSummary'
export const STEP_EVALUATION_SUMMARY_SUBMISSION = 'EvaluationSummarySubmission'
export const STEP_END_OF_WORKFLOW = 'EndOfWorkflow'
const $gettext = s => s // to force vue-i18n-extract to see translations
export const WORKFLOW_STEPS = {
  [STEP_ADMISSIBILITY]: {
    id: 1,
    label: $gettext('Admissibility'),
    icon: 'fas fa-check',
    component: 'admissibility',
  },
  [STEP_TRADE_CONTRIBUTIONS]: {
    id: 2,
    label: $gettext('Discipline Contributions'),
    icon: 'fas fa-pen',
    component: 'trade-contributions',
  },
  [STEP_PM_EVALUATION]: {
    id: 3,
    label: $gettext('Project Manager Evaluation'),
    icon: 'fas fa-signature',
    component: 'pm-evaluation',
  },
  [STEP_PM_EVALUATION_SUBMISSION]: {
    id: 4,
    label: $gettext('Project Manager Evaluation Submission'),
    icon: 'fas fa-mail-bulk',
    component: 'p-m-evaluation-submission',
  },
  [STEP_EXTERNAL_CONTRIBUTIONS]: {
    id: 5,
    label: $gettext('External Contributions'),
    icon: 'fas fa-pen',
    component: 'trade-contributions',
  },
  [STEP_EVALUATION_SUMMARY]: {
    id: 6,
    label: $gettext('Evaluation Summary'),
    icon: 'fas fa-signature',
    component: 'evaluation-summary',
  },
  [STEP_EVALUATION_SUMMARY_SUBMISSION]: {
    id: 7,
    label: $gettext('Evaluation Summary Submission'),
    icon: 'fas fa-mail-bulk',
    component: 'evaluation-summary-submission',
  },
  [STEP_END_OF_WORKFLOW]: {
    id: 8,
    label: $gettext('End of workflow'),
    icon: 'fas fa-flag-checkered',
    component: 'end-of-workflow',
  },
}

export const WORKFLOWS = {
  COMPLETE: {
    name: $gettext('Complete'),
    label: $gettext('Admissibility / PM Evaluation / Summary'),
  },
  COMPLETE_SGP: {
    name: $gettext('SGP Complete'),
    label: $gettext('SGP - Admissibility / PM Evaluation / External Contrib. / Summary'),
  },
  SIMPLIFIED_SGP: {
    name: $gettext('SGP Simplified'),
    label: $gettext('SGP - PM Evaluation / External Contrib. / Summary'),
  },
  SIMPLE: {
    name: $gettext('Simple'),
    label: $gettext('Admissibility / PM Evaluation'),
  },
}

export const FROM_HISTORY = 'history'
export const FROM_PROJECT = 'project'

export const WIDTH_BP = 1400
export const HEIGHT_BP = 850
export const NUM_QUERIES_PER_DOCUMENT_DOWNLOAD_REQUEST = 4
export const FOOTER_HEIGHT = 59

export const CONTRIBUTION_STATUS = {
  INITIAL: 'INITIAL',
  ASSIGNED: 'ASSIGNED',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
  SUBMITTED: 'SUBMITTED',
}
export const CONTRIBUTION_STATUS_NAME = {
  INITIAL: $gettext('Initial'),
  ASSIGNED: $gettext('Assigned'),
  NOT_APPLICABLE: $gettext('Not applicable'),
  SUBMITTED: $gettext('Submitted'),
}
export const THREAD_IMPORTANCE = {
  MAJOR: $gettext('Major'),
  MINOR: $gettext('Minor'),
  INTERNAL: $gettext('Internal'),
}
