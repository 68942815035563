<script>
import InformationPopup from '@comp/utils/InformationPopup.vue'
import { currentDocumentVersionESS, finishEvaluationSummarySubmission } from './queries'
import { useIndexStore } from '@src/store/index.js'

export default {
  name: 'EvaluationSummarySubmission',
  components: {
    InformationPopup,
  },
  props: {
    lockedMode: {
      type: Boolean,
      default: () => false,
    },
    documentVersionId: {
      type: String,
      default: () => '',
    },
  },
  emits: [
    'nextStep',
  ],
  data () {
    return {
      documentVersion: null,
      evaluationSummaryStateLabel: null,
      evaluationSummaryComment: null,
      informationPopup: false,
      informationPopupTitle: '',
      informationPopupContent: '',
      informationPopupValidate: '',
      informationPopupValidateParams: [],
    }
  },
  computed: {
    store () {
      return useIndexStore()
    },
    canIAdvanceToNextStep () {
      return this.documentVersion ? this.documentVersion.permissions.canIAdvanceToNextStep : false
    },
  },
  watch: {
    async lockedMode () {
      await this.setData()
    },
  },
  async mounted () {
    await this.setData()
  },
  methods: {
    async setData () {
      return this.$graphqlQuery(currentDocumentVersionESS, {
        id: this.documentVersionId,
      }).then(response => {
        this.documentVersion = response.documentVersion
        this.evaluationSummaryStateLabel = this.documentVersion.evaluationSummaryStateLabel
        this.evaluationSummaryComment = this.documentVersion.evaluationSummaryComment
      })
    },
    async nextStep () {
      return this.$graphqlMutate(finishEvaluationSummarySubmission, {
        documentVersionId: this.documentVersionId,
      }).then(response => {
        const type = response.finishEvaluationSummarySubmission.currentStepTemplate.type
        this.$emit('nextStep', type)
      }).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
      })
    },
    openInformationPopupNextStep () {
      this.informationPopupTitle = this.$gettext('Transition to next step')
      this.informationPopupContent = this.$gettext('Are you sure you want to go to the next step?')
      this.informationPopupValidate = this.nextStep()
      this.informationPopupValidateParams = []
      this.informationPopup = true
    },
  },
}
</script>
<template>
  <section class="card">
    <div>
      {{ $gettext('Summary evaluation submitted') }} : {{ evaluationSummaryStateLabel }}
    </div>
    <div class="evaluation-summary-comment">
      {{ evaluationSummaryComment }}
    </div>
    <div
      v-if="!lockedMode"
      class="step-btn-container"
    >
      <v-btn
        color="primary"
        :disabled="!canIAdvanceToNextStep"
        prepend-icon="fas fa-arrow-right"
        @click="openInformationPopupNextStep"
      >
        {{ $gettext("Go to next step") }}
      </v-btn>
    </div>
    <InformationPopup
      :model="informationPopup"
      :title="informationPopupTitle"
      :content="informationPopupContent"
      :params="informationPopupValidateParams"
      @cancel="informationPopup = false"
      @validate="informationPopupValidate"
    />
  </section>
</template>
<style lang="scss" scoped>
.evaluation-summary-comment {
  margin-top: 20px;
  border-top: 1px solid $grey-light;
  padding-top: 20px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
}
</style>
