<script>
export default {
  name: 'KeywordTagsFilter',
  props: {
    filterTags: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  emits: [
    'removeTags',
  ],
  data () {
    return {
      filterTagsStored: [],
    }
  },
  methods: {
    removeTagFromList (tag) {
      const tagIndex = this.filterTags.indexOf(tag)
      if (tagIndex > -1) {
        this.filterTags.splice(tagIndex, 1)
        this.$emit('removeTags')
      }
    },
  },
}
</script>
<template>
  <ul class="list-tags-btn">
    <li
      v-for="tag in filterTags"
      :key="tag"
    >
      <v-btn
        rounded
        color="primary"
        size="x-small"
        prepend-icon="fas fa-times"
        @click="removeTagFromList(tag)"
      >
        {{ tag }}
      </v-btn>
    </li>
  </ul>
</template>
<style lang="scss" scoped>
.list-tags-btn {
  padding: inherit;
  max-height: 4vh;
  overflow-y: auto;
}
.list-tags-btn > li {
  display: inline-block;
  margin-right: 10px;
}
.list-tags-btn > li > button:hover {
  text-decoration: line-through;
  color: $secondary;
}
</style>
