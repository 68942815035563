<script>
import moment from 'moment'
import {
  CONTRIBUTION_STATUS,
  CONTRIBUTION_STATUS_NAME,
} from '@src/utils/consts'
import { useIndexStore } from '@src/store/index.js'

export default {
  name: 'TradeContributionsTable',
  props: {
    currentStep: {
      type: String,
      required: true,
    },
    lockedMode: {
      type: Boolean,
      required: true,
    },
    tradeContributions: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'showTradeContribution',
    'assignTradeContribution',
    'unassignedTradeContribution',
    'setTradeContributionAsNotConcerned',
    'setTradeContributionAsApplicable',
  ],
  data () {
    return {
      tradeContributionsSorted: [],
    }
  },
  computed: {
    store () {
      return useIndexStore()
    },
    headers () {
      return [
        { title: this.$gettext('Progress'), key: 'status', sortable: false },
        { title: this.$gettext('Discipline'), key: 'trade', sortable: false },
        { title: this.$gettext('Assigned to'), key: 'assignation', sortable: false },
        { title: this.$gettext('Contribution date'), key: 'lastSubmittedDatetime', sortable: true },
        { title: this.$gettext('Actions'), key: 'actions', sortable: false, width: '160' },
      ]
    },
    editableTradeContrib () {
      return !this.lockedMode
    },
  },
  watch: {
    tradeContributions: {
      handler () {
        this.tradeContributionsSorted = this.getSortedTradeContributions()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getSortedTradeContributions () {
      return this.tradeContributions.slice().sort(this.tradeContributionComparator)
    },
    tradeContributionComparator (tc1, tc2) {
      return tc1.trade.acronym > tc2.trade.acronym ? 1 : -1
    },
    getStatusCssClass (tradeContribution) {
      return `table-progress progress-${tradeContribution.status.toLowerCase()}`
    },
    displayStatus (tradeContribution) {
      const statusName = CONTRIBUTION_STATUS_NAME[tradeContribution.status]
      return `${this.$gettext('Progress')}${this.$gettext(':')} ${this.$gettext(statusName)}`
    },
    displayTradeName (tradeContribution) {
      return tradeContribution.trade.name
    },
    displayTradeAcronym (tradeContribution) {
      return tradeContribution.trade.acronym
    },
    displayAssignation (tradeContribution) {
      const userAssigned = tradeContribution.assignedTo || {}
      return userAssigned.fullName
    },
    displayDate (tradeContribution) {
      const date = tradeContribution.lastSubmittedDatetime
      return date ? moment(date).format('DD/MM/YYYY') : null
    },
    amIAssigned (tradeContribution) {
      return !!tradeContribution.assignedTo && tradeContribution.assignedTo.id === this.store.user.id
    },
    isAssignToggable (tradeContribution) {
      const perms = tradeContribution.permissions
      return this.amIAssigned(tradeContribution) ? perms.canUnassignUser : perms.canAssignUser
    },
    displayAssignTitle (tradeContribution) {
      if (this.amIAssigned(tradeContribution)) {
        return this.$gettext('Self unassigned')
      } else {
        if (tradeContribution.status === CONTRIBUTION_STATUS.SUBMITTED) {
          return this.$gettext('Cancel the contribution submission and assigned self')
        } else {
          return this.$gettext('Self assign')
        }
      }
    },
    canShow (tradeContribution) {
      return tradeContribution.permissions.canSee
    },
    hasOpenThreads (tradeContribution) {
      return tradeContribution.threads.length > 0
    },
    canMakeNotApplicable (tradeContribution) {
      return tradeContribution.permissions.canSetNotApplicable
    },
    canMakeApplicable (tradeContribution) {
      return tradeContribution.permissions.canSetApplicable
    },
    show (tradeContribution) {
      this.$emit('showTradeContribution', tradeContribution)
    },
    assignClick (tradeContribution) {
      if (this.amIAssigned(tradeContribution)) {
        this.$emit('unassignedTradeContribution', tradeContribution)
      } else {
        const reopen = tradeContribution.status === CONTRIBUTION_STATUS.SUBMITTED
        this.$emit('assignTradeContribution', tradeContribution, reopen)
      }
    },
    makeNotApplicable (tradeContribution) {
      this.$emit('setTradeContributionAsNotConcerned', tradeContribution)
    },
    makeApplicable (tradeContribution) {
      this.$emit('setTradeContributionAsApplicable', tradeContribution)
    },
    getAssignButtonIcon (contrib) {
      return this.amIAssigned(contrib) ? 'fas fa-times' : 'fas fa-hand-point-up'
    },
  },
}
</script>
<template>
  <v-data-table-virtual
    :headers="headers"
    :items="tradeContributionsSorted"
    class="trade-contributions-table"
    fixed-header
  >
    <template #item.status="{ item: contrib }">
      <div
        :class="getStatusCssClass(contrib)"
        :title="displayStatus(contrib)"
      />
    </template>
    <template #item.trade="{ item: contrib }">
      <div :title="displayTradeName(contrib)">
        {{ displayTradeAcronym(contrib) }}
      </div>
    </template>
    <template #item.assignation="{ item: contrib }">
      <div>
        {{ displayAssignation(contrib) }}
      </div>
    </template>
    <template #item.lastSubmittedDatetime="{ item: contrib }">
      <div>
        {{ displayDate(contrib) }}
      </div>
    </template>
    <template #item.actions="{ item: contrib }">
      <v-btn
        v-if="editableTradeContrib"
        size="small"
        color="secondary"
        class="action-btn"
        variant="text"
        :disabled="!isAssignToggable(contrib)"
        :title="displayAssignTitle(contrib)"
        :icon="getAssignButtonIcon(contrib)"
        @click="assignClick(contrib)"
      />
      <v-btn
        size="small"
        color="secondary"
        variant="text"
        :disabled="!canShow(contrib)"
        :title="$gettext('Comment threads')"
        icon="fas fa-list"
        @click="show(contrib)"
      />
      <v-btn
        v-if="editableTradeContrib && contrib.status === 'NOT_APPLICABLE'"
        size="small"
        color="secondary"
        variant="text"
        :disabled="!canMakeApplicable(contrib)"
        :title="$gettext('Make discipline applicable')"
        icon="fas fa-folder-open"
        @click="makeApplicable(contrib)"
      />
      <v-btn
        v-else-if="editableTradeContrib"
        size="small"
        color="secondary"
        variant="text"
        icon="fas fa-ban"
        :disabled="!canMakeNotApplicable(contrib)"
        :title="$gettext('Discipline not applicable')"
        @click="makeNotApplicable(contrib)"
      />
    </template>
  </v-data-table-virtual>
</template>
<style lang="scss" scoped>
.trade-contributions-table {
  overflow-y: auto;
  flex-grow: 1;
}
</style>
