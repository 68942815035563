<script>
import { finishPmEvaluation } from '@comp/document/tradeContributions/queries'
import { getDocumentVersion, getDocumentThreads } from './queries'
import PmEvaluationButtons from '@comp/document/pmEvaluation/PmEvaluationButtons.vue'
import PmEvaluationDialog from '@comp/document/pmEvaluation/PmEvaluationDialog.vue'
import AllThreads from '@comp/document/pmEvaluation/AllThreads.vue'
import { useIndexStore } from '@src/store/index.js'

export default {
  name: 'PmEvaluation',
  components: {
    PmEvaluationButtons,
    PmEvaluationDialog,
    AllThreads,
  },
  props: {
    documentVersionId: {
      type: String,
      required: true,
    },
    lockedMode: {
      type: Boolean,
      default: () => false,
    },
    isOmanType: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'nextStep',
    'previewUpdateRequired',
    'expandDocumentInformationRequest',
    'reduceDocumentInformationRequest',
  ],
  data () {
    return {
      documentVersion: null,
      dialogShown: false,
      pmEvaluationLabel: null,
      oldThreadsAsString: null,
      hideThread: true,
    }
  },
  computed: {
    store () {
      return useIndexStore()
    },
    threads: {
      get () {
        if (this.documentVersion && this.documentVersion.document) {
          return this.documentVersion.document.threads.filter(thread => {
            return thread.allComments.length
          })
        } else {
          return []
        }
      },
      set (value) {
        const svalue = JSON.stringify(value)
        if (svalue !== this.oldThreadsAsString) {
          this.oldThreadsAsString = svalue
          this.documentVersion.document.threads = value
          this.$emit('previewUpdateRequired')
        }
      },
    },
    showPMEvaluationButton () {
      return !this.lockedMode && this.documentVersion && this.hideThread
    },
  },
  watch: {
    documentVersionId: {
      handler () {
        return this.setData()
      },
      immediate: true,
    },
  },
  methods: {
    async setData () {
      let documentVersion = null
      const payloadDocVersion = {
        documentVersionId: this.documentVersionId,
      }
      await this.$graphqlMutate(
        getDocumentVersion, payloadDocVersion,
      ).then(response => {
        documentVersion = response.documentVersion
      }).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
      })
      const payloadDoc = {
        documentId: documentVersion.document.id,
        documentVersionId: documentVersion.id,
        documentVersionSequence: documentVersion.sequence,
      }
      await this.$graphqlMutate(getDocumentThreads, payloadDoc,
      ).then(response => {
        documentVersion.document = response.document
        this.documentVersion = documentVersion
        this.oldThreadsAsString = JSON.stringify(this.threads)
      }).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
      })
    },
    onNextStepButtonClicked () {
      this.openDialog()
    },
    openDialog () {
      this.pmEvaluationLabel = null
      this.dialogShown = true
    },
    closeDialog () {
      this.dialogShown = false
      this.pmEvaluationLabel = null
    },
    saveDialog (evaluationLabel) {
      this.dialogShown = false
      this.pmEvaluationLabel = evaluationLabel
      this.nextStep()
    },
    onShowThreadDetails () {
      this.hideThread = false
      this.$emit('reduceDocumentInformationRequest')
    },
    onCloseThreadDetails () {
      this.hideThread = true
      this.$emit('expandDocumentInformationRequest')
    },
    async nextStep () {
      const query = finishPmEvaluation
      const payload = {
        documentVersionId: this.documentVersionId,
        pmEvaluationLabel: this.pmEvaluationLabel,
      }
      const resultKey = 'finishPmEvaluation'
      return this.$graphqlMutate(query, payload).then(response => {
        this.$emit('nextStep', response[resultKey].currentStepTemplate.type)
      }).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
      })
    },
  },
}
</script>
<template>
  <section class="pm-evaluation-section">
    <div class="card">
      <AllThreads
        v-model:threads="threads"
        :locked-mode="lockedMode"
        :document-version-id="documentVersionId"
        :is-oman-type="isOmanType"
        @show-thread-details="onShowThreadDetails"
        @close-thread-details="onCloseThreadDetails"
      />
      <PmEvaluationButtons
        v-if="showPMEvaluationButton"
        :document-version="documentVersion"
        @next-step="onNextStepButtonClicked"
      />
      <PmEvaluationDialog
        v-if="dialogShown"
        v-model:pm-evaluation-label="pmEvaluationLabel"
        :pm-evaluation-list="documentVersion.labels.pmEvaluationList"
        @close="closeDialog"
        @save="saveDialog"
      />
    </div>
  </section>
</template>
<style lang="scss" scoped>
.pm-evaluation-section {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.card {
  gap: 1rem;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
}
</style>
