import { defineStore } from 'pinia'

export const useIndexStore = defineStore('store', () => {
  const route = null
  const notification = {}
  const user = null
  const project = null
  const createdProject = null
  const filters = {}
  const sort = {}
  const filtersPanel = true
  const docPreview = true

  function changeRoute (newRoute) {
    this.route = newRoute
  }
  function changeNotification (payload) {
    this.notification = payload
  }
  function changeUser (newUser) {
    this.user = newUser
  }
  function changeProject (newProject) {
    this.project = newProject
  }
  function createProject (newProject) {
    this.createdProject = newProject
  }
  function resetProject () {
    this.project = null
  }
  function changeTableParam ({ key, newValue }) {
    if (this.project) {
      if (!this[key][this.project.id]) {
        this[key][this.project.id] = {}
      }
      this[key][this.project.id][this.route] = newValue
    }
  }
  function changeVisibility (key) {
    this[key] = !this[key]
  }

  return {
    route,
    notification,
    user,
    project,
    createdProject,
    filters,
    sort,
    filtersPanel,
    docPreview,

    changeRoute,
    changeNotification,
    changeUser,
    changeProject,
    createProject,
    resetProject,
    changeTableParam,
    changeVisibility,
  }
})
