<script>
export default {
  name: 'UpdateFiltersButton',
  props: {
    filterUpdateDataError: {
      type: Boolean,
      required: true,
    },
    filterIsDirty: {
      type: Boolean,
      required: true,
    },
    filterKeywordsIsDirty: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'filtersUpdated',
  ],
  computed: {
    size () {
      const size = { xs: 'x-small', sm: 'small', lg: 'large', xl: 'x-large' }[this.$vuetify.display.name]
      return size ? { [size]: true } : {}
    },
    color () {
      if (this.filterUpdateDataError) {
        return 'error'
      } else if (this.filterIsDirty) {
        return 'primary'
      } else {
        return 'mediumgrey'
      }
    },
    btnTxt () {
      if (this.filterUpdateDataError) {
        return this.$gettext('Error')
      } else if (this.filterIsDirty) {
        return this.$gettext('Update filter')
      } else {
        return this.$gettext('Filter up to date')
      }
    },
    btnIcon () {
      if (this.filterUpdateDataError) {
        return 'fas fa-circle-exclamation'
      } else if (this.filterIsDirty) {
        return 'fas fa-arrows-rotate'
      } else {
        return 'far fa-circle-check'
      }
    },
    updateIsDiabled () {
      return this.loading || this.filterKeywordsIsDirty || !this.filterIsDirty
    },
  },
  methods: {
    sendFilter () {
      this.$emit('filtersUpdated')
    },
  },
}
</script>
<template>
  <transition>
    <v-btn
      class="update-filter-button"
      :loading="loading"
      :color="color"
      :disabled="updateIsDiabled"
      v-bind="size"
      :prepend-icon="btnIcon"
      @click="sendFilter"
    >
      {{ btnTxt }}
    </v-btn>
  </transition>
</template>
<style lang="scss" scoped>
.update-filter-button {
  color: $black !important;
}

@media (width <= 1050px), (height <= 550px) {
  .v-btn {
    font-size: .7rem;
  }
}
</style>
