<script>
export default {
  name: 'InformationPopup',
  props: {
    model: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => '',
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    showExtraButtons: {
      type: Boolean,
      default: () => false,
    },
    content: {
      type: String,
      default: () => '',
    },
    params: {
      type: Array,
      default: () => [],
    },
    extraButtons: {
      type: Array,
      default: () => [],
    },
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: [
    'cancel',
    'validate',
  ],
  computed: {
    dialogProps () {
      return {
        contentClass: undefined,
        fullscreen: false,
        maxWidth: undefined,
        persistent: true,
        scrollable: false,
        transition: 'dialog-transition',
        width: '30%',
        ...this.props,
      }
    },
    scopedModel () {
      return this.model
    },
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    validate () {
      this.$emit('validate', ...this.params)
    },
    emitExtraButton (emit) {
      this.$emit(emit, ...this.params)
    },
  },
}
</script>
<template>
  <v-dialog
    v-model="scopedModel"
    :content-class="dialogProps.contentClass"
    :fullscreen="dialogProps.fullscreen"
    :max-width="dialogProps.maxWidth"
    :persistent="dialogProps.persistent"
    :scrollable="dialogProps.scrollable"
    :transition="dialogProps.transition"
    :width="dialogProps.width"
  >
    <v-card class="card-without-padding">
      <v-card-title class="dialog-header">
        <slot
          name="title"
          :title="title"
        >
          {{ title }}
        </slot>
      </v-card-title>
      <v-card-text
        v-show="!loading"
        class="dialog-content-pre"
      >
        <slot :content="content">
          {{ content }}
        </slot>
      </v-card-text>
      <v-card-text
        v-show="loading"
        class="loading-state"
      >
        <v-progress-circular
          :width="10"
          :size="100"
          color="primary"
          indeterminate
        />
      </v-card-text>
      <slot
        v-if="!loading"
        name="actions"
        :extra-buttons="extraButtons"
      >
        <v-card-actions
          v-if="showExtraButtons"
          class="justify-end"
        >
          <v-btn
            v-for="extraButton in extraButtons"
            :key="extraButton.text"
            color="primary"
            variant="elevated"
            :prepend-icon="extraButton.icon"
            @click="emitExtraButton(extraButton.emit)"
          >
            {{ extraButton.text }}
          </v-btn>
        </v-card-actions>
        <v-card-actions
          v-else
          class="justify-end"
        >
          <v-btn
            variant="elevated"
            prepend-icon="fas fa-times"
            @click="cancel"
          >
            {{ $gettext('Cancel') }}
          </v-btn>
          <v-btn
            prepend-icon="fas fa-save"
            variant="elevated"
            color="primary"
            @click="validate"
          >
            {{ $gettext('Validate') }}
          </v-btn>
        </v-card-actions>
      </slot>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
.dialog-content-pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.card-without-padding {
  padding: 0 !important;
}
:deep(.v-overlay__content) { // stylelint-disable-line selector-class-pattern
  position: relative;
}
.v-card-text {
  overflow: auto;
}
</style>
