<script>
import { useIndexStore } from '@src/store'
import { loginRoute } from '@src/router'

export default {
  name: 'Fail',
  async mounted () {
    const store = useIndexStore()
    store.changeRoute(this.$options.name)
    const errorMessage = this.getURLParams('error')
    if (errorMessage && errorMessage.includes('cannot logout')) {
      console.error(errorMessage)
      this.$router.push({ name: loginRoute.name })
    }
  },
  methods: {
    getURLParams (param, Window) {
      const url = new URL((Window || window).location.href)
      return url.searchParams.get(param)
    },
  },
}
</script>
<template>
  <div class="layout">
    <div class="layout-overlay" />
    <div class="card">
      <div class="fail-title">
        <img
          src="/static/logo_vizeo.png"
          alt="Vizeo"
        >
      </div>
      <div>
        <div class="fail-subtitle">
          {{ $gettext("Error") }}
        </div>
        <div class="error-msg">
          {{ getURLParams('error') }}
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.card {
  z-index: 1;
  gap: 1rem;
  width: 500px;
  max-height: calc(100% - 2em);
  overflow-y: auto;
  padding: 40px 20px;
}
.fail-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fail-title > img {
  max-width: 20rem;
  max-height: 15rem;
}
.fail-subtitle {
  font-size: 2em;
  color: $warning-orange !important;
  font-weight: bold;
  text-align: center;
}
.error-msg {
  font-size: 1em;
  color: $error-red !important;
}
</style>
